import React, { useState } from 'react'

import ReactCardFlip from 'react-card-flip';
import SavedAnswers from './SavedAnswer';
import QuestionSetDescription from './QuestionSetDescription';
import WrapperCard from '../../components/WrapperCard';

export default function FullSavedAnswer(props: any) {
  const [flip, setFlip] = useState(false)

  return (
    <ReactCardFlip isFlipped={flip} flipDirection="horizontal"
      cardStyles={{
        back: {
          width: "100%",
        }
      }}
    >
      {/* Saved answers - Flip == false*/}
      <WrapperCard>
        <SavedAnswers
          answers={props.answer}
          flip={() => { setFlip(!flip) }}
        />
      </WrapperCard>
      
      {/* Description - Flip == true */}
      <WrapperCard>
        <QuestionSetDescription
          isAnswering={false}
          questionSet={props.answer}
          flip={() => { setFlip(!flip) }}
        />
      </WrapperCard>
    </ReactCardFlip>
  )
}
