import { SEA, hashValue } from "../services/gun";
import { Pair } from "../services/types";


export function generateKey() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result: string = '';
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result
}

export async function encryptKeys(pair: Pair, length = 3) {
  // Generate codes
  const codes = Array.from({ length }, generateKey);
  const encryptedKeys = await Promise.all(codes.map(async (code: string) => {
    const codeHash = await hashValue(code)
    const encrypted = await SEA.encrypt({...pair, codes}, code)
    return {
      codeHash,
      encrypted,
    }
  }))

  return { encryptedKeys, codes }
}
