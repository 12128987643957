import React, { useState, useEffect} from "react"

import {
  Box, CardContent, Typography, Fab, IconButton
} from "@mui/material"
import { collection, doc, fs, getDoc, logOut, setDoc } from "../../services/firebase";
import { query, where, getDocs } from "firebase/firestore";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

import FlipIcon from "@mui/icons-material/Flip";

import ReactMarkdown from 'react-markdown'
import makeStyles from "@mui/styles/makeStyles";
import GDPRDialog from "../../components/GDPRDialog";
import AboutDialog from "../../components/AboutDialog";
import { QuestionSet } from "../../services/types";
import { QuestionSetShare } from "../Ask/SavedQuestionSet"

const useStyles = makeStyles(_ => ({
  cardContent: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    height: "100%",
  },
  topRightIconWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  fabWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  shareWrapper: {
    marginTop: 1,
    marginBottom: 0,
    display: "flex",
    justifyContent: "right",
    alignItems: "flex-end",
    overlow: "hidden"
  },
  GDPRLink: {
    marginBottom: 15,
    flexGrow: 1,
    textDecoration: "none",
    color: "green",
    cursor: "pointer",
  }
}))
export default function QuestionSetDescription(props: any) {
  console.log("QuestionSetDescription.tsx", props)
  const classes = useStyles();
  const [shareDialogOpen, setShareDialogOpen] = useState(false)

  const [GDPROpen, setGDPROpen] = useState(false)
  const [aboutOpen, setAboutOpen] = useState(false)
  const [terms, setTerms] = useState("")
  function getTerms(id) {
    console.log("getTerms", id)
    const q = query(collection(fs, "setters"), where("pubKey", "==", id))
    return getDocs(q).then((data) => {
      console.log("getTerms", data)
      if (data.empty) return ""
      return data.docs[0].data().terms
    })
  }
  
  useEffect(() => {
    if (props.questionSet?.setterPub && !terms) {
      getTerms(props.questionSet.setterPub).then((data) => {
        console.log("getTerms", data)
	setTerms(data)
      })
    }
  }, [props.questionSet?.setterPub, terms])
   
  return (
    <CardContent className={classes.cardContent}>
      <Box sx={{ mb: 2, overflowY: "auto", "-webkit-overflow-scrolling": "touch", height: "50%", whiteSpaceCollapse: "break-spaces" }}>
        <ReactMarkdown>{props.questionSet?.reason || props.questionSet?.description}</ReactMarkdown>
      </Box>
      <Typography variant="body1" className={classes.GDPRLink} onClick={() => { setGDPROpen(true) }}>GDPR and Privacy Statement</Typography>
      <Typography variant="body1" className={classes.GDPRLink} onClick={() => { setAboutOpen(true) }}>{props.questionSet?.setterName}</Typography>

      <GDPRDialog  open={GDPROpen} terms={terms} key={terms} questionSet={props.questionSet as QuestionSet} onClose={() => { setGDPROpen(false) }} />
      <AboutDialog open={aboutOpen} questionSet={props.questionSet as QuestionSet} onClose={() => { setAboutOpen(false) }} />
      <QuestionSetShare
        open={shareDialogOpen}
        id={props.questionSet?.id}
        name={props.questionSet?.title}
        handleClose={() => { setShareDialogOpen(false) }}
      />

      {!props.noflip && (<Box className={classes.fabWrapper}>
        <Fab color="secondary" aria-label="flip card" title="Flip Card"
          onClick={() => { props.flip() }}
        >
          <FlipIcon />
        </Fab>
      </Box>)}
    </CardContent>
  )
}
