import React from 'react'
import { useNavigate } from "react-router-dom"

import {
  Box, Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import makeStyles from '@mui/styles/makeStyles';
import CookiePolicy from '../../components/CookiePolicy';
const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "left",
    paddingBottom: 20,
  },
}))


export default function ResponderFAQ() {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <Box className={classes.wrapper}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2, width: "100%", }}>
        <ArrowBackIcon sx={{ fontSize: 20, mr: 0.5, ml: 1.5, cursor: "pointer" }} onClick={() => { navigate("/") }} />
        <Typography variant="h2">About Us</Typography>
        <Box sx={{ minWidth: 10 }}></Box>
      </Box>
     
      <h3> PARROT: The Web App That Ends Repetitive Forms with AI🦜🔒 </h3>
      <p>Introducing PARROT, the game-changing web app that's transforming the way we share data! 🎉 Say goodbye to the endless cycle of filling out repetitive forms. With PARROT's cutting-edge AI technology, you can create and share forms securely, just once. 🔒💪 </p>
      <h3> 🌟 Designed with Inclusivity in Mind  </h3>
      <p> PARROT's journey began in low-income communities, where the team was committed to creating an app that was accessible and intuitive for all users, regardless of their technological or cognitive challenges. This foundation in empathy and understanding ensures that PARROT serves a wide audience and addresses the unique needs of those who have been historically underserved by digital advancements. 🙌❤️ </p>
      <h3> 📱 Seamless Access, Anytime, Anywhere  </h3>
      <p> PARROT is built on the principle of universal access, making data sharing and collection a breeze without the need for repetitive forms. As a mobile-optimized web app, PARROT delivers unparalleled convenience, allowing you to access and share your data from any device, in any setting. 🌍📱 </p>
      <h3> ⚡ AI-Powered Forms in a Flash  </h3>
      <p> Leveraging PARROT's sophisticated AI, you can transform your ideas into comprehensive, ready-to-use forms in no time. The app streamlines data collection with targeted questions, optimizing both your time and that of your respondents. ⏰💡 </p>
      <h3> 🔐 Share Once, Secure Forever  </h3>
      <p> With PARROT, you only need to share your information once, and it's securely stored for future use under end-to-end encryption. This secure environment protects your data, eliminating the need for repeated submissions. 🔒👍 </p>
      <h3> ✅ Transparency and Verification at Your Fingertips  </h3>
      <p> PARROT provides a clear audit trail for every share, detailing views, purposes, and interactions. The addition of digital signatures verifies actions and confirmations, enhancing the trustworthiness of every exchange. 📝✅ </p>
      <h3> 🚀 Embrace the Future of Data Sharing  </h3>
      <p> PARROT is redefining the way we interact with forms and information. As a mobile-optimized web app, it offers unmatched convenience and security, marking the end of repetitive data entry. Join the smarter, more efficient future with PARROT, where data is shared securely and succinctly on your terms. 🔒🦜 </p>
    </Box>
  )
}
