import React, { useState } from 'react'
import QrReader from 'react-qr-scanner'
import { QRCode } from 'react-qrcode-logo'
import { QrSettings } from '../../Helpers/qrcodeSettings'

export default function AddDevice() {
    const [data, setData] = useState({delay: 300, result: 'scan your code here'});
    const previewStyle = {
      height: 240,
      width: 320,
    }
    function handleScan(scan:string){
        if (!scan) return;
        try {
            const data = JSON.parse(scan)
            console.log(data)
        // validate data
            if (data && data.pub && data.epub && data.priv && data.epriv) {
                localStorage.setItem('keys', data)
                window.location.href = '/'
	    } else {
	        setData({delay: 300, result: 'Invalid key Code'})
	    }
        } catch (error) {
            setData({delay: 300, result: 'Invalid QR Code'})
        }
    }
    
    return(
      <div>
        <QrReader
          delay={data.delay}
          style={previewStyle}
          onError={console.error}
          onScan={handleScan}
        />
        <p>{data.result}</p>
        <QRCode {...QrSettings} value={localStorage.keys} size={320} />

      </div>
    )

}
