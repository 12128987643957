import React from 'react'
import { useNavigate } from "react-router-dom"

import {
  Box, Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import makeStyles from '@mui/styles/makeStyles';
import CookiePolicy from '../../components/CookiePolicy';
const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    textAlign: "left",
  },
}))

const faq = [
  {
    question: "How do I create an account?",
    answer: "You can create an account either by linking an existing Google Account or by signing up with an email and password. To do this, click ’Sign Up’ on the first screen, and follow the instructions from there. You can include information about your team, such as a name, email, and website, if you have one."
  },
  {
    question: "What is a plan?",
    answer: "A plan is which tier of Parrot you are subscribed to. The free plan has the most limited features, with the paid plans allowing you to create more question sets and gather more responses. When you create an account, you will be on the free plan as default."
  },
  {
    question: "How do I change my plan?",
    answer: "To change your plan, select plan from the main menu on the home screen. That will take you to your current plan and usage information. From there you’ll find a link to change plans."
  },
  {
    question: "What is a team?",
    answer: "A team is essentially an organisation registered with Parrot. Members of that team can sign up with their own account and link it to the organisation. In future versions of Parrot, users will be able to leave and join multiple teams."
  },
  {
    question: "How do I create a question set?",
    answer: "Once you’re logged in, your homepage will show a list of your previously created question sets, if any. At the bottom of the homepage is a ‘Create a New Question Set’ button. Press that and follow the instructions from there!"
  },
  {
    question: "How do I change or delete a question set?",
    answer: "To change or delete a question set, open that question set. If there are no responses to it so far, you will be able to press the change and delete buttons at the top of the screen. If there are responses, then these will be unavailable. Changing a question set will generate a new code, and the old one will become inactive."
  },
  {
    question: "What is the difference between deleting and archiving a question set?",
    answer: "Once you delete a question set, you cannot recover it, or the responses received. Archiving a question set will move it to your archive, which is accessible through the menu in the top right of the screen. You can unarchive a question set."
  },
  {
    question: "How do I see the responses to my question set(s)?",
    answer: "On your homepage, you’ll see your questions sets. If you click on the question set, it will take you to a question set details card, with a flip button at the bottom of it. If you click that button, the card will flip and show you the responses."
  },
  {
    question: "What’s the difference between seeing and saving responses?",
    answer: "When you see a response, it’s only available to you for a set amount of time, as determined by the responder. This timer begins when you open the card. When you save a response, you will have access to it permanently."
  },
  {
    question: "How do I save a response?",
    answer: "When you create a question set, you’ll be able to set whether you will see or save each response, on a question by question basis. To see a response, select the eye symbol, to save, select the floppy disc. The responder will know whether each answer is being seen or saved."
  },
  {
    question: "How do I ask for new questions?",
    answer: "For now, you’re limited to the questions inbuilt in Parrot. We’re working on a feature to request new questions soon!"
  },
  {
    question: "Why are the questions limited?",
    answer: "The questions you can ask are limited so that the system can recognise when the user has answered a question before! There are hundreds of ways to ask for someone’s name in English, but for most people there’s only one answer! We limit the ways you can ask the question, so they don’t have to keep answering it."
  },
  {
    question: "How do I sign a card",
    answer: "To sign a card – these contain someone’s responses – click to open it and then slide up from the orange button."
  },
  {
    question: "What does signing a card mean?",
    answer: "Signing a card means that your organisation has seen and approved it. This could be a way of validating that vouchers you’ve given"
  },
  {
    question: "How do I share my question set for people to fill in?",
    answer: "Once you have created your final question set, you can press the green share symbol in the top-right corner of the page. This will show a link, an alphanumeric code, and a QR code. All of these will bring users to your question set."
  },
  {
    question: "Do we need our own privacy policy?",
    answer: "No, you don't need to create your own privacy policy from scratch. We automatically generate a GDPR (General Data Protection Regulation) compliant privacy policy based on the information you provide in your profile."
  },
  {
    question: "How can we contact you?",
    answer: "If you press the three dots in the top-right corner of the screen, and then select ‘Help’, it will open an email to one of the members of the Hi9 team."
  },
  // {
  //   question: "test",
  //   answer:"test"
  // },
]


export default function AskFAQ() {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <Box className={classes.wrapper}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2, width: "100%", }}>
        <ArrowBackIcon sx={{ fontSize: 20, mr: 0.5, ml: 1.5, cursor: "pointer" }} onClick={() => { navigate("/ask") }} />
        <Typography variant="h5">FAQ</Typography>
        <Box sx={{ minWidth: 10 }}></Box>
      </Box>

      {
        faq.map((qa, index) => {
          return (
            <Accordion key={index} sx={{ width: "100%" }} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel-content-" + index}
                id={"panel-content-" + index}
                sx={{ backgroundColor: "#efebebcc" }}
              >
                <Typography>{qa.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {qa.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })
      }

      <Accordion key="privacy-policy" id="privacy-policy">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content-pp"
          id="panel-content-pp"
          sx={{ backgroundColor: "#efebebcc" }}
        >
          <Typography>What’s your privacy policy?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <strong>Hi9 Ltd Privacy Policy</strong>
          <br /><br />
          This Privacy Policy informs you of how Hi9 Ltd collects, uses, and protects your personal data when you use our services.
          <br /><br />

          <strong>Who we are:</strong> Hi9 Ltd is the data controller for the purposes of data protection laws. Our Data Protection Officer, Wo King, can be reached at <a href="mailto:wo@hi9.io">wo@hi9.io</a> for any queries or concerns.
          <br /><br />

          <strong>Information we collect:</strong> We collect data necessary to deliver our services. This may include account information needed for the creation and operation of your account. Form responses and form creation data are not typically stored on our servers, but on the users' devices.
          <br /><br />

          <strong>Purpose of Processing:</strong> The purpose of processing this data is to provide, improve, understand, customize, support, and market our services. We are committed to ensuring that our data collection and processing is always lawful, fair, and transparent.
          <br /><br />

          <strong>Data Security:</strong> We prioritize the security of your data. All data we process is fully end-to-end encrypted and the encryption keys are saved on the users' devices. This means that your data cannot be decrypted by us, ensuring that your information remains private and secure. However, if someone were to access Parrot on your device, they would be able to see the data in the browser page.
          <br /><br />

          <strong>Data Retention:</strong> We retain your data for as long as necessary to provide our services. Form responses and form creation data are stored on users' devices and not typically on our servers.
          <br /><br />

          <strong>Data Sharing:</strong> We do not share your data with third parties unless it is necessary for the delivery of our services or if we are required to do so by law. Even when required by law, we are unable to provide unencrypted data as we do not have the keys to decrypt the data. Only the users setting the forms can see and share the data.
          <br /><br />

          <strong>Your Rights:</strong> You have several rights under data protection law, including the right to access, update, or delete your data, and the right to object to certain types of processing. If you wish to exercise any of these rights, please contact our Data Protection Officer at wo@hi9.io.
          <br /><br />

          <strong>Children's Privacy:</strong> If our services are used by children under the age of 16, parental consent will be sought in accordance with data protection laws.
          <br /><br />

          <strong>Contact us:</strong> If you have any questions about this Privacy Policy or our data practices, please contact our Data Protection Officer, Wo King, at wo@hi9.io.
          <br /><br />

          Please note that this Privacy Policy may be updated from time to time to reflect changes in our data practices or applicable laws.
        </AccordionDetails>
      </Accordion>

      <Accordion key="cookie-policy" id="cookie-policy">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="cookie-ploicy-content"
          id="cookie-ploicy-content"
          sx={{ backgroundColor: "#efebebcc" }}
        >
          <Typography>What’s your cookie policy?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CookiePolicy />
        </AccordionDetails>
      </Accordion>


      {/* <Accordion key="terms-of-service" id="terms-of-service">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content-tos"
          id="panel-content-tos"
          sx={{ backgroundColor: "#efebebcc" }}
        >
          <Typography>What are your terms of service?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <strong>Terms of Service Agreement</strong>
          <br />
          These Terms of Service (the "Terms") govern your use of the Parrot app (the "App"), a product of Hi9 ("Hi9" or "we"), and all related products, services, software, tools, and documentation provided by Hi9 (collectively, the "Services"). By accessing or using the Services, you agree to be bound by these Terms and our Privacy Policy, which is incorporated by reference. If you do not agree to these Terms, you may not access or use the Services.

          <br /><br />
          <strong>Use of the App</strong>
          <br />
          You may use the App only in accordance with these Terms. You may not use the App for any illegal purpose or in any way that violates these Terms. You must not: (a) reverse engineer, decompile, disassemble, or attempt to discover the source code of the App; (b) modify, translate, or create derivative works based on the App; (c) rent, lease, loan, resell for profit, or distribute the App; (d) remove any proprietary notices or labels on the App; or (e) use the App in a manner that could damage, disable, or impair Hi9's servers or connected networks.

          <br /><br />
          <strong>Ownership</strong>
          <br />
          The App is protected by copyright, trade secret, and other intellectual property laws. You acknowledge that Hi9 owns all legal rights, titles, and interest in the App, including any intellectual property rights. You also acknowledge that the App may contain confidential information proprietary to Hi9

          <br /><br />
          <strong>Warranty Disclaimer</strong>
          <br />
          You understand and agree that:
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>your use of the App and services is at your own risk. The App and services are provided "as is" and "as available." Hi9 disclaims all warranties, express or implied, including merchantability, fitness for a particular purpose, and non-infringement;</li>
            <li>Hi9 makes no warranty that:
              <ol style={{ listStyleType: "lower-roman" }}>
                <li>the App and services will meet your requirements;</li>
                <li>the App and services will be uninterrupted, timely, secure, or error-free;</li>
                <li>the results from using the App and services will be accurate or reliable;</li>
                <li>any errors in the App and services will be corrected;</li>
              </ol>
            </li>
            <li>any material downloaded or obtained through the use of the App and services is done at your own risk and you will be solely responsible for any damage to your computer system or loss of data; and</li>
            <li>no advice or information, oral or written, obtained from Hi9 or through the App and services creates any warranty not stated in these Terms.</li>
          </ol>

          <br /><br />
          <strong>Limitation of Liability</strong>
          <br />
          You understand and agree that Hi9 will not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if Hi9 has been advised of the possibility of such damages), arising from:
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>using or inability to use the App and services;</li>
            <li>the cost of obtaining substitute goods and services resulting from any goods, data, information, or services purchased or obtained or messages received or transactions entered into through the App and services;</li>
            <li>unauthorized access to or alteration of your transmissions or data;</li>
            <li>statements or conduct of any third party on the App and services;</li>
            <li>any other matter relating to the App and services.</li>
          </ol>

          <strong>Changes to the Terms</strong>
          <br />
          Hi9 reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting of the revised Terms. Your continued use of the App and services after any changes means you accept the revised Terms.

          <br /><br />
          <strong>Termination</strong>
          <br />
          Hi9 may terminate these Terms or your use of the App and services at any time, with or without cause or notice. Upon termination, you must cease all use of the App and services.

          <br /><br />
          <strong>Governing Law</strong>
          <br />
          These Terms and your use of the App and services will be governed by and construed in accordance with the laws of the legal entity in which you are located, without giving effect to any principles of conflicts of law.

          <br /><br />
          <strong>Miscellaneous</strong>
          <br />
          These Terms constitute the entire agreement between you and Hi9 regarding the use of the App and services. If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced. The failure of Hi9 to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing.

          <br /><br />
          <strong>Contact Information</strong>
          <br />
          If you have any questions about these Terms, please contact Hi9 at wo@hi9.io
        </AccordionDetails>
      </Accordion> */}
    </Box >
  )
}
