import React, { useState, useEffect } from "react"

import { Pair, QuestionSet, ExtendedAnswer } from "../../services/types"

import {
  Box, CardContent, Button, Typography, Divider, Fab,
} from "@mui/material"
// import LoadingButton from "@mui/lab/LoadingButton";

import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

import { hashValue, SEA } from "../../services/gun"
import { getSetterPair, getUser, setUser } from "../../Helpers/localStorage"

import "../../styles/slides.css"
import { VERSION } from "../../serviceWorkerRegistration";
import { CarouselItem } from "../../services/carousel";
import { collection, doc, fs, getDoc, logOut, setDoc } from "../../services/firebase";

import WrapperCard from "../../components/WrapperCard";
import NewQuestionSet from "./NewQuestionSet";

import SavedQuestionSet from "./SavedQuestionSet";

import { parseDate } from "../../Helpers/dates";
import { checkLimitsReached, PLAN_LIMIT_QS, PLAN_LIMIT_RESPONSES_SAVED } from "./pricingTable";
import { useNavigate } from "react-router";
import { deleteDoc, getDocs, addDoc, where, onSnapshot, query } from "@firebase/firestore";

import { isIOS, isMobile} from 'react-device-detect'
import makeStyles from "@mui/styles/makeStyles";
import { checkPermission, PermissionsEnum } from "../../Helpers/permissions";
import { maxHeight } from "../../Helpers/height";
import CustomBreadcrumbs from "../../components/Breadcrumbs";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Invite } from "./Team";
import {replace} from "cypress/types/lodash";

const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  cardContent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "space-between",
    width: "100%"
  },
}))

const homeItem: CarouselItem = {
  key: "setter_home",
  type: "setter_home"
}

const newQuestionSetItem: CarouselItem = {
  key: "setter_new_question_set",
  type: "setter_new_question_set"
}

type Keys = {
  [key: string]: Date
}

export default function SetterHome() {
  const classes = useStyles();

  const navigate = useNavigate()

  //#region state
  const [gunPair, setGunPair] = useState<Pair | null>(null)
  const [user] = useState(getUser())
  const [setter, setSetter] = useState<any>(null)

  const [questionSetsIds, setQuestionSetsIds] = useState<string[]>([])
  const [questionSets, setQuestionSets] = useState<QuestionSet[]>([])
  const [loading, setLoading] = useState(false)

  const [carouselItems, setCarouselItem] = useState<CarouselItem[]>([homeItem])
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [carouselDisabled, setCarouselDisabled] = useState(false)

  const [questionSetsLimit, setQuestionSetsLimit] = useState(false)

  const [answers, setAnswers] = useState<any[]>([])
  const [hiddenAnswers, setHiddenAnswers] = useState<string[]>([])
  const [teams, setTeams] = useState<Invite[]>([])
  const urlParams = new URL(document.location.toString()).searchParams
  const team = urlParams.get("team") || ""
  console.log("team", team)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  //#endregion

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    if (Math.abs(distance) < minSwipeDistance) return
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isRightSwipe && carouselIndex !== 0) {
      setCarouselIndex(carouselIndex - 1)
    }
    if (isLeftSwipe && carouselIndex < carouselItems.length - 1) {
      setCarouselIndex(carouselIndex + 1)
    }
  }

  //#region effects
  useEffect(() => {

    if (user?.email && user?.email !== "" && !questionSets.length) {
      const teams = Object.keys(JSON.parse(localStorage.getItem("setterKeys") || "{}") || {})
      if (team !== "" && teams.includes(team)) {
	loadData(team)
      } else {
        loadData(user?.email)
      }
    } else if (!user) {
      logOut()
      navigate("/ask/login")
    }

    return () => {
    }
  }, [user])
  // get pubKeys from localStorage or url.hash
  // save pubKeys to firestore
  useEffect(() => {
    const urlHash = (window.location.hash || localStorage.getItem("hash") || "#,,,,").slice(1).split(",")
    console.log("urlHash", urlHash)
    const [id, pub, epub, email] = urlHash || ["","","",""]

    if (id && pub && epub && email) {
      const docRef = doc(fs, "questionSets", id)
      const data = { pub, epub, email }
      const toSave = {}
      toSave[email] = data
      setDoc(docRef, {pubKeys: toSave}, { merge: true }) // merge: true to not overwrite existing data
      localStorage.setItem("hash", ``) // clear hash
      window.location.hash = "" // remove hash from url
    }
  }, [])


  useEffect(() => {
    // console.log(gunPair, user, user?.plan)
    if (gunPair && user?.plan) {
      // Handle responses
      // Testing: once
      const q = collection(fs, "answers", gunPair.pub, "hash")
      const unsub = onSnapshot(q, (querySnapshot) => {
        let answers: any[] = [];
        querySnapshot.forEach((doc) => {
          // answers.push({ ...doc.data(), id: doc.id })
          answers.push({
            answerId: doc.id,
            created: doc.data().created
          })
        }, (error: any) => {
          console.error("Error fetching documents: ", error);
        });

        // console.log("answers", answers.length)

        const currMonth = (new Date()).getMonth()
        const currYear = (new Date()).getFullYear()

        answers.sort(function (a: any, b: any) {
          return new Date(a.created!) < new Date(b.created!) ? -1 : 1
        })

        setAnswers(answers.map((resp: any) => resp.answerId) || [])

        // Check if limit reached for the month 
        const monthAnswers = answers.filter((ans: any) => {
          return parseDate(ans.created).month() === currMonth
            && parseDate(ans.created).year() === currYear
        }) || []

        const limitResponses = checkLimitsReached(PLAN_LIMIT_RESPONSES_SAVED, user?.plan, monthAnswers?.length)
        // console.log("limitResponses", user?.plan, monthAnswers?.length, limitResponses)

        if (!limitResponses) {
          // Logout
          logOut()
          setUser(null)
          navigate("/")
          return
        }

        let hiddenResponses: any[] = []
        if (limitResponses < 0) {
          hiddenResponses = monthAnswers.slice(monthAnswers.length + limitResponses)
          hiddenResponses = hiddenResponses.map((resp: any) => resp.answerId)
          setHiddenAnswers(hiddenResponses || [])
        } else {
          setHiddenAnswers([])
        }
      });

      return unsub
    }

    return () => {
    }
  }, [gunPair])

  useEffect(() => {
    // console.log("user", user.plan)
    // Current month & year
    /* const currYear = (new Date()).getFullYear()
    const currMonth = (new Date()).getMonth()
    const monthQS = questionSets.filter((qs: QuestionSet) => {
      return parseDate(qs.created).month() === currMonth
        && parseDate(qs.created).year() === currYear
    }) || [] */

    // console.log("this month's qs", monthQS?.length)
    const limit = checkLimitsReached(PLAN_LIMIT_QS, user?.plan, questionSets?.length)
    console.log("limit", limit)
    if (!limit) return
    
    setQuestionSetsLimit(limit <= 0)

    return () => {
    }
  }, [questionSets])
  //#endregion

  //#region handlers
  const goToSlide = (id: string) => {
    // console.log("goToSlide", id)
    // console.log("window.location", window.location)
    const { pathname, search } = window.location
    // const newHref = pathname + search + "#" + id
    // console.log("newHref", newHref)
    // window.location.href = newHref
    setTimeout( () => {
      let el = document.getElementById(id)
      console.log("el", el)
      if (!el || !el.parentElement ) return
      el.parentElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" })
    }, 40)
  }

  async function loadSetterProfile(setterId: string) {
    const docRef = doc(fs, "setters", setterId)
    try {
      const setter = (await getDoc(docRef))?.data()
      return setter
    } catch (error: any) {
      console.error("error", error.message)

      if (error.message === "Missing or insufficient permissions.") {
        logOut()
        setUser(null)
        navigate("/ask/login")
      }
    }
  }
    const handleNewQuestionSet = (questionSet: QuestionSet | null = null) => {
    // Check if profile is completed 
    if (!setter) return alert("error")
    if (setter?.setterContactEmail.trim() === "" || setter?.setterContactFullName.trim() === "") {
      alert("Please complete your profile to make a question set!")
      return navigate("/ask/profile?destination=/ask#new-question-set")
    }

    // Check if a new question set is already in the carousel
    const newQuestionSetIndex = carouselItems.findIndex((item: CarouselItem) => item.type == newQuestionSetItem.key)
    if (newQuestionSetIndex !== -1) {
      goToSlide(newQuestionSetItem.key)
      setCarouselIndex(newQuestionSetIndex)
      return
    }

    setCarouselItem([
      ...carouselItems.slice(0, 1),
      {
        ...newQuestionSetItem,
        questionSet,
      },
      ...carouselItems.slice(1),
    ],)
    setCarouselIndex(1)

    // TODO: fix this hack
    // Wait for the carousel to update
    setTimeout(
      () => { goToSlide(newQuestionSetItem.key) },
      10
    );
  }

  async function loadData(theTeam?: string) {
    setLoading(true)
    setQuestionSets([])
    setCarouselItem([homeItem])
    // get teams
    const teamsRef = collection(fs, "invites")
    const teamsRefQuery = query(teamsRef, where('email', '==', user.email), where("setter", "!=", user.email));
    const teamsSnapshot = await getDocs(teamsRefQuery)

    let pair: Pair = await getSetterPair(user?.email)
    let lKeys = {[user.email]: pair}

    const teams = await Promise.all(teamsSnapshot.docs.map(async doc => {
      const teamData = doc.data()
      console.log("teamData", teamData)

      // decrypt the keys
      const aes = await SEA.secret(teamData.epubKey, pair)
      const data = await SEA.decrypt(teamData.encrypted, aes)
      lKeys[teamData.setter] = data as Pair
      return teamData as Invite
    }))
    localStorage.setItem("setterKeys", JSON.stringify(lKeys))

    let setter = await loadSetterProfile((teams || []).reduce((a,b) => {
      return b.setter === theTeam ? b.setterId : a
    }, user?.setterId))

    if (!setter) {
      alert("error")
      return
    }

    console.log("setter", setter)
    pair = await getSetterPair(theTeam || user?.email)

    // Get question sets from firestore
    // with the same pub key
    const questionSetsRef = collection(fs, "questionSets")
    const qsQuery = query(questionSetsRef, where("setterPub", "==", pair.pub))

    const questionSetsByPubKey = await getDocs(qsQuery)
    const QSetsIds = [ ...questionSetsByPubKey.docs.map(doc => doc.id) ]
    setter.questionSets = QSetsIds

    setSetter({
      setterName: setter?.organisationName || "",
      setterContactEmail: setter?.contactEmail || "",
      setterContactFullName: `${setter?.contactName || ""} ${setter?.contactSurname || ""}`,
      setterLogo: setter?.logo || "",
      setterAbout: setter?.about || "",
      ...setter,
    })



    // Get setter keys
    setGunPair(pair)

    // Save/update pub key of setter
    const docRef = doc(fs, "setters", user?.setterId!)
    const setterData = (await getDoc(docRef)).data()
    let keys: Keys = {}
    if ((setterData?.keys || [])[pair.pub] === undefined) {
      keys[pair.pub] = new Date()
      try {
	await setDoc(docRef, { keys }, { merge: true })
      } catch (error) {
        console.error("Error writing document: ", error)
      }
    }
    setQuestionSetsIds( questionSetsIds || [])
    if (!questionSetsByPubKey.empty) {
      // Get question sets from firestore
      let questionSets = await Promise.all(setter.questionSets.map(async (id: string) => {
        try {
          const qs = await getDoc(doc(fs, "questionSets", id))
          if (!qs.exists()) {
            return null
          }

          let questionSet = qs.data() as QuestionSet

          if (questionSet.status === "archived") return null

          questionSet.fields = Object.values(questionSet.fields)
          // console.log(questionSet.id, questionSet.setterPub === pair.pub)
          return questionSet as QuestionSet

        } catch (error) {
          console.error(error)
          return null
        }
      }))

      // Filter the nulls
      questionSets = questionSets.filter((data: QuestionSet) => data != null)

      // Order by most recent
      questionSets.sort(function (a: QuestionSet, b: QuestionSet) {
        return new Date(a.created!) < new Date(b.created!) ? 1 : -1
      })

      setQuestionSets(questionSets as QuestionSet[])

      setCarouselItem([...carouselItems.filter(item => item.type !== "setter_saved_question_set"), ...(questionSets.map((questionSet: QuestionSet) => {
        return {
          key: questionSet?.id,
          type: questionSet ? "setter_saved_question_set" : "error",
          questionSet,
        } as CarouselItem
      }))])
      console.log("questionSets", questionSets)
    }
    setLoading(false)
  }


  const handleCancelNewQuestionSet = (id: string | null = null) => {
    setCarouselItem([
      ...carouselItems.slice(0, 1),
      ...carouselItems.slice(2),
    ])
    if (id) handleViewQuestionSet(id)
    else {
      setCarouselIndex(0)
      goToSlide(homeItem.key)
    }
  }

  const createQuestionSet = async (questionSet: any, oldId: string) => {
    // Get the current questions from firestore""
    const questionsRef = collection(fs, "questions")
    const questionsSnapshot = await getDocs(questionsRef)
    const questions = questionsSnapshot.docs.map(doc => doc.data().label.toLowerCase())

    // Look for new fields and save them to firestore under the questions
    const newFields = questionSet.fields.filter((field: any) => {
      return !questions.includes(field.label.toLowerCase())
    })
    console.log("newFields", newFields)
    newFields.forEach(async (field: any) => {
      if (field.type !== "select") {
        delete field.options
      }
      field.name = field.label.toLowerCase().replace(/ /g, "_").replaceAll("/", "\\")
      await addDoc(questionsRef, field)
    })

    if (!gunPair) {
      alert("error")
      return
    }

    // Generate question set id
    let newQuestionSet: QuestionSet = {
      ...questionSet,
      ...setter,
      setterPub: gunPair.pub,
      setterEpub: gunPair.epub,
      setterId: user?.email,
      id: "",
      madeWith: `Parrot ${VERSION}`,
    }
    const hash = await hashValue(JSON.stringify(newQuestionSet))
    const id = hash.slice(-6).toUpperCase()

    newQuestionSet = { ...newQuestionSet, id }
    // console.log("saving newQuestionSet", newQuestionSet)

    // Save the form to firestore
    const saveTo = { ...newQuestionSet, fields: { ...newQuestionSet.fields }, id }
    console.log("saveTo", saveTo)
    const docRef = doc(fs, "questionSets", id)
    await setDoc(docRef, saveTo).catch((error) => {
      console.error("Error writing document: ", error)
    })

    if (!oldId) {
      // Save to Firebase
      const updated = {
        questionSets: [...questionSetsIds, id]
      }
      setDoc(doc(fs, "setters", user?.setterId), updated, { merge: true })

      // Update carousel
      setCarouselItem([
        ...carouselItems.slice(0, 1),
        {
          key: id,
          type: "setter_saved_question_set",
          questionSet: newQuestionSet,
        },
        ...carouselItems.slice(2),
      ])
      setCarouselIndex(1)
      goToSlide(id)

      // Update question sets list
      setQuestionSets([
        newQuestionSet,
        ...questionSets,
      ])

    } else {
      // Delete old question set if exists
      console.log("delete old", oldId)

      // Delete from firebase
      await deleteDoc(doc(fs, "questionSets", oldId));

      const updated = {
        questionSets: [...questionSetsIds.filter((i: string) => i !== oldId), id]
      }
      setDoc(doc(fs, "setters", user?.setterId), updated, { merge: true })

      // Update list of question sets
      const tmpQuestionSets = questionSets.filter((qs: QuestionSet) => qs.id !== oldId)
      setQuestionSets([
        newQuestionSet,
        ...tmpQuestionSets,
      ])

      // Update carousel
      const tmpCarouselItems = carouselItems.filter((item: CarouselItem) => item.key !== oldId)
      setCarouselItem([
        ...tmpCarouselItems.slice(0, 1),
        {
          key: id,
          type: "setter_saved_question_set",
          questionSet: newQuestionSet,
        },
        ...tmpCarouselItems.slice(2),
      ])
      setCarouselIndex(1)
      goToSlide(id)

    }
  }

  const handleRequestNewQuestion = async (request: any) => {
    console.log(request)
    console.log("user 123", user)

    if (!gunPair) {
      alert("error")
      return
    }

    const hash = await hashValue(JSON.stringify(request))

    request = {
      requesterContactEmail: setter.setterContactEmail,
      requesterName: setter.setterName,
      requesterPub: gunPair.pub,
      requesterEpub: gunPair.epub,
      requesterId: user?.id,
      doNotSave: false,
      name: hash.slice(-5).toUpperCase(),
      multiline: request.rows > 1,
      ...request,
    }

    const id = hash.slice(-10).toUpperCase()

    request = { ...request, id }

    // Save the form to firestore
    const docRef = doc(fs, "questions", id)
    await setDoc(docRef, request).catch((error) => {
      console.error("Error writing document: ", error)
    })

  }

  async function deleteQuestionSet(id: string) {
    // console.log("id", id)
    // Delete from firebase
    await deleteDoc(doc(fs, "questionSets", id));

    const updated = {
      questionSets: questionSetsIds.filter((i: string) => i !== id)
    }
    setDoc(doc(fs, "setters", user?.setterId), updated, { merge: true })

    // Update list of question sets
    setQuestionSets(questionSets.filter((qs: QuestionSet) => qs.id !== id))

    // Update carousel
    setCarouselItem(carouselItems.filter((item: CarouselItem) => item.key !== id))
    setCarouselIndex(0)
    goToSlide(homeItem.key)
  }

  function copyQuestionSet(questionSet: QuestionSet) {
    
    const newQuestionSet = { ...questionSet, id: "" }
    console.log("copyQuestionSet", newQuestionSet)
    handleNewQuestionSet(newQuestionSet)
  }
  function updateQuestionSet(questionSet: QuestionSet) {
    console.log("updateQuestionSet", questionSet)
    handleNewQuestionSet(questionSet)
  }

  async function archiveQuestionSet(id: string) {
    console.log("archiveQuestionSet", id)

    // TODO: show confirmation message

    // Update in firestore
    await setDoc(doc(fs, "questionSets", id), { status: "archived" }, { merge: true });

    // Update list of question sets
    setQuestionSets(questionSets.filter((qs: QuestionSet) => qs.id !== id))

    // Update carousel
    setCarouselItem(carouselItems.filter((item: CarouselItem) => item.key !== id))
    setCarouselIndex(0)
    goToSlide(homeItem.key)
  }

  const handleViewQuestionSet = (id: string) => {
    const carouselIndex = carouselItems.findIndex((item: CarouselItem) => item.key === id)
    setCarouselIndex(carouselIndex)
    goToSlide(id)
  }
  //#endregion handlers

  return (
    <Box
      className={classes.wrapper+" normal" + (isMobile ? " mobile" : "")} 
    >
      <Box className="slide-container">
        {
          carouselItems.map((item: CarouselItem) => {
            switch (item.type) {
              case "setter_home":
                return (
                  <Box className="slide" key={item.key}>
		    <a className="anchor" id={item.key}></a>
                    <WrapperCard key={item.key}
                    // onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
                    >
                      <CardContent sx={{ width: "100%", display: "flex", flexDirection: "column", overflowY: "auto", "-webkit-overflow-scrolling": "touch" }}>
                        <Box sx={{ flexGrow: 1, textAlign: "left", height: maxHeight("100px") }}>
                          <Box>
                            {setter?.setterLogo && (<img src={setter?.setterLogo} width="100" />)}
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>

                              <Typography variant="h5" sx={{ mt: 0, mb: 2 }}>Question sets</Typography>
                              {!questionSetsLimit && questionSets.length > 5 && checkPermission(PermissionsEnum.CUD_QS, user?.permissions) ? <Box>
                                <Button variant="contained" color="secondary"
                                  startIcon={<AddIcon />}
                                  onClick={() => { handleNewQuestionSet(null) }}
                                >
                                  New
                                </Button>
                              </Box> : questionSetsLimit ? <Box>
                                <Button variant="contained" color="secondary"
                                  onClick={(e) => {
                                    navigate("/ask/plans")
                                  }}
                                >
                                  Upgrade
                                </Button>
                              </Box> :
                              <Button variant="contained" color="secondary"
                                onClick={() => { handleNewQuestionSet(null) }}
                                id="make-new-qs"
                              >
                                +
                              </Button>}
                            </Box>
                          </Box>

                          {loading ? "Loading ..." : questionSets.length ? <Box>
                            {questionSets.map((questionSet: QuestionSet) => {
                              return (
                                <Box key={questionSet.id} sx={{ my: 1 }}
                                  onClick={() => { handleViewQuestionSet(questionSet.id) }}
                                >
                                  <Box sx={{ display: "flex", py: 1 }}>
                                    <Box sx={{ flexGrow: 1.5 }}>
                                      <Typography variant="body1" sx={{ fontWeight: 300 }}>{questionSet.id} - {questionSet.name}</Typography>
                                      {/* <Typography variant="body1">Created: {questionSet.created}</Typography> */}
                                      <Typography variant="body1" sx={{ fontWeight: 300 }}>Expires: {questionSet.expiryDate?.slice(0, 10) || "N/A"}</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0.5, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                      <KeyboardArrowRightRoundedIcon />
                                    </Box>
                                  </Box>
                                  <Divider />
                                </Box>
                              )
                            })}
                          </Box> : <Typography variant="body1">No question sets yet!</Typography>
                          }
                        </Box>

                        {!questionSetsLimit && checkPermission(PermissionsEnum.CUD_QS, user?.permissions) ? <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Button variant="contained" color="secondary"
                            onClick={() => { handleNewQuestionSet(null) }}
                            id="make-new-qs"
                          >
                            Make a new question set
                          </Button>
                        </Box> : (questionSetsLimit ? <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography sx={{ mb: 1 }}>Question sets limit reached!</Typography>
                          <Button variant="contained" color="secondary"
                            onClick={(e) => {
                              navigate("/ask/plans")
                            }}
                          >
                            Upgrade plan
                          </Button>
                        </Box> : "")}
                      </CardContent>
                    </WrapperCard>
                  </Box>
                )
              case "setter_new_question_set":
                return (
                  <Box className="slide" key={item.key}>
		    <a className="anchor" id={item.key}></a>
		    <a className="anchor" id="new"></a>
                    <NewQuestionSet
                      key={item.key}
                      questionSet={item.questionSet}
                      cancelNewQuestionSet={handleCancelNewQuestionSet}
                      createQuestionSet={createQuestionSet}
                      handleRequestNewQuestion={handleRequestNewQuestion}
                    />
                  </Box>
                )
              case "setter_saved_question_set":
                return (
                  <div className="slide">
		    <a className="anchor" id={item.key}></a>
                    <SavedQuestionSet
                      key={item.key}
                      questionSet={item.questionSet}
                      plan={user?.plan}
                      pair={gunPair}
                      setCarouselDisabled={(disabled: boolean) => { setCarouselDisabled(disabled) }}
		      setterId={item.questionSet.setterId}

                      answers={answers?.filter((id: string) => id.slice(0, 6) === item.questionSet.id)}
                      hiddenAnswers={hiddenAnswers?.filter((id: string) => id.slice(0, 6) === item.questionSet.id)}

                      deleteQuestionSet={deleteQuestionSet}
                      updateQuestionSet={updateQuestionSet}
                      copyQuestionSet={copyQuestionSet}
                      archiveQuestionSet={archiveQuestionSet}
                    />
                  </div>
                )
              case "error":
                return (
                  <Box className="slide" key={item.key}>
		    <a className="anchor" id={item.key}></a>
                    <WrapperCard key={"error-" + item.key}>
                      Question set with ID {item.key} not found
                    </WrapperCard>
                  </Box>
                )
            }
          })
        }
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
      </Box>

      {/* <Breadcrumbs style={{ position: "absolute", bottom: 10, height: 35 }} color="primary" separator="-" aria-label="breadcrumb"
        sx={{
          "& li.MuiBreadcrumbs-separator": { ml: 0.5, mr: 0.5 }
        }}
      >
        <Box className={classes.breadcrumb} onClick={() => {
          setCarouselIndex(0)
          goToSlide(homeItem.key)
        }}>
          <HomeRoundedIcon fontSize="medium" />
        </Box>
        {carouselItems.length <= 4 && (
          carouselItems.map((item: CarouselItem, index: number) => {
            if (index === 0) return ""

            return (
              <Box key={item.key} className={classes.breadcrumb}>
                <CropPortraitRoundedIcon fontSize="medium" onClick={() => {
                  setCarouselIndex(index)
                  goToSlide(item.key)
                }} />
              </Box>
            )
          })
        )}
        {carouselItems.length > 4 && (<Box className={classes.breadcrumb}>
          <CropPortraitRoundedIcon fontSize="medium" onClick={() => {
            const newIndex = 1 // carouselIndex ? carouselIndex - 1 : 0
            setCarouselIndex(newIndex)
            goToSlide(carouselItems[newIndex].key)
          }} />
        </Box>)}
        {carouselItems.length > 4 && (<Box className={classes.breadcrumb}>
          <MoreHorizOutlinedIcon sx={{ fontSize: 15 }} />
        </Box>)}
        {carouselItems.length > 4 && (<Box className={classes.breadcrumb}>
          <CropPortraitRoundedIcon fontSize="medium" onClick={() => {
            const newIndex = carouselItems.length - 1 // carouselIndex !== carouselItems.length - 1 ? carouselIndex + 1 : carouselIndex
            setCarouselIndex(newIndex)
            goToSlide(carouselItems[newIndex].key)
          }} />
        </Box>)}
      </Breadcrumbs>
      <CustomBreadcrumbs
        slides={carouselItems}
        activeIndex={carouselIndex}
        onClickHome={() => {
          setCarouselIndex(0)
          goToSlide(homeItem.key)
        }}
      /> */}
    </Box >
  )
}
