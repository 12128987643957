import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import {
  Box, Typography, CardContent, Divider,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, TextField, Button,
  FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, FormHelperText, Accordion, AccordionSummary, AccordionDetails, IconButton
} from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AddIcon from '@mui/icons-material/Add';

import makeStyles from '@mui/styles/makeStyles';
import { collection, doc, fs, getDoc, getDocs, logOut, query } from '../../services/firebase';
import { addDoc, deleteDoc, setDoc, where } from 'firebase/firestore';
import { PermissionsEnum } from '../../Helpers/permissions';
import { getSetterPair, getUser, setUser } from '../../Helpers/localStorage';
import WrapperCard from '../../components/WrapperCard';
import { Pair } from '../../services/types';
import { getPubKey } from '../../services/api';
import { encryptKeys } from '../../Helpers/encryptionKeys';
import { formatDate } from '../../Helpers/dates';
import { PLAN_LIMIT_users, checkLimitsReached } from './pricingTable';
import { SEA } from '../../services/gun';
const useStyles = makeStyles(_ => ({
  wrapper: {
    width: "100%",
    padding: "0 20px",
  },
  accordionRoot: {
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    '&:before': {
      display: 'none',
    },
  },
  summaryRoot: {
    "& .MuiTypography-root": {
      fontSize: "0.95rem",
    },
    "& .MuiAccordionSummary-content": {
      marginTop: 5,
      marginBottom: 5,
      flexGrow: 0,
    },
    '&$summaryExpanded': {
      minHeight: 30, // Adjust the expanded height as needed
    },
    padding: 0,
    justifyContent: "flex-start",
  },
  accordionDetails: {
    textAlign: "left",
    padding: "5px 0",
    "& .MuiTypography-root": {
      fontSize: "0.8rem",
    },
  },
  summaryExpanded: {},
}))

type MemberDialogProps = {
  open: boolean;
  action: string;
  email?: string;
  permissions?: Record<string, boolean>;
  uid?: string;
}
const MemberDialog = (props: any) => {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState<boolean | string>(false)

  const [permissions, setPermissions] = useState(initPermissions())
  const [noPermissions, setNoPermissions] = useState(0)
  const [permError, setPermError] = useState<boolean | string>(false)

  useEffect(() => {
    if (props.email && props.permissions) {
      setEmail(props.email || "")
      setPermissions(props.permissions || initPermissions())
      setNoPermissions(Object.keys(PermissionsEnum).filter((perm: string) => props.permissions[perm]).length)
    }
  }, [props])

  function initPermissions() {
    const perms = Object.keys(PermissionsEnum).reduce((prev: any, curr: string) => {
      prev[curr] = false
      return prev
    }, {})

    // console.log(perms)
    return perms
  }

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleChangePermissions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target

    if (PermissionsEnum[name] === PermissionsEnum.ADMIN) {
      if (checked) {
        setPermissions(Object.keys(PermissionsEnum).reduce((prev: any, curr: string) => {
          prev[curr] = true
          return prev
        }, {}))
        setNoPermissions(Object.keys(PermissionsEnum).length)
      } else {
        setPermissions(initPermissions())
        setNoPermissions(0)
      }
    } else {
      const updated = {
        ...permissions,
        ADMIN: checked ? permissions["ADMIN"] : false,
        [name]: checked,
      }
      setPermissions(updated);
      setNoPermissions(Object.keys(PermissionsEnum).filter((perm: string) => updated[perm]).length)
    }
  };

  const onContinue = () => {
    setEmailError(false)
    setPermError(false)

    if (email.trim() === "") {
      return setEmailError("The email is required")
    } else if (!emailPattern.test(email)) {
      return setEmailError("The email is not valid")
    }

    if (!noPermissions) {
      return setPermError("Please assign at least one permission")
    }

    // console.log("noPermissions", noPermissions)

    props.onContinue({
      email: email.trim(),
      permissions: Object.keys(permissions).filter((perm: string) => permissions[perm]).map((perm: string) => PermissionsEnum[perm])
    })
    resetForm()
  }

  const onClose = () => {
    resetForm()
    props.onClose()
  }

  function resetForm() {
    // console.log("resetForm")
    setEmail("")
    setPermissions(initPermissions)
    setNoPermissions(0)
  }

  return (
    <Dialog open={props.open !== false} onClose={onClose}>
      <DialogTitle>{props.action === "add" ? "New" : "Update"} member</DialogTitle>
      <DialogContent sx={{ mx: 2, mb: 2 }}>
        <TextField
          autoFocus margin="dense" id="email" label="email"
          type="text" fullWidth variant="outlined"
          disabled={props.email !== undefined}

          value={email}
          onChange={(e) => {
            setEmailError(false)
            setEmail(e.target.value)
          }}

          sx={{ mb: 2 }}
          error={emailError !== false}
          helperText={emailError || ""}

          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onContinue()
            }
          }}
        />

        <FormControl component="fieldset" variant="standard" error={permError !== false}>
          <FormLabel component="legend">{props.action === "add" ? "Assign" : "Update"} permissions</FormLabel>
          <FormGroup>
            {Object.keys(permissions).map((perm: string) => {
              return (
                <FormControlLabel
                  key={perm}
                  control={
                    <Checkbox checked={permissions[perm]} onChange={handleChangePermissions} name={perm} />
                  }
                  label={PermissionsEnum[perm].replaceAll("_", " ")}
                />
              )
            })}
          </FormGroup>
          {permError && <FormHelperText>{permError}</FormHelperText>}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onContinue}>{props.action === "Add Member" ? "New Member" : "Update"}</Button>
      </DialogActions>
    </Dialog>
  )
}

const InviteCodeDialog = (props: any) => {

  return (
    <Dialog open={props.open} onClose={props.onContinue}>
      <DialogTitle>!IMPORTANT - Invite code</DialogTitle>
      <DialogContent sx={{ mx: 2, mb: 2, textAlign: "center" }}>
        An invitation email has been sent to your new team member!
        <br /><br />
        Please save the following code and share it with your new team member separately. They will need it to sign up and join the team.
        <br />This code won't be shown again.
        <br /><br />
        <Typography variant="h6">
          {props.code}
        </Typography>

      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={props.onContinue}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

type ConfirmationDialogProps = {
  open: boolean;
  action: string;
  message: string;
  resource: string;
  id: null | string;
}
const ConfirmationDialog = (props: any) => {

  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Typography variant="h6">{props.message}</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 3, mt: 3 }}>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={props.handleConfirm}>Confirm</Button>
        </Box>
      </Box>
    </Dialog>
  )
}

const SnackAlert = React.forwardRef<HTMLDivElement, AlertProps>(function alert(
  props,
  ref,
) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const moment = require('moment');

type Member = {
  email: string;
  uid: string;
  permissions: string[];
  setterId: string;
}

export type Invite = {
  id?: string;
  email: string;
  permissions: string[];
  encrypted: string;
  codeHash: string;
  created: any;
  expires: any;
  setterId: string;
  setter: string;
  status: "active" | "accepted";
  epubKey?: string;
  keys?: any;
}

export default function TeamMembers() {
  const classes = useStyles();

  const navigate = useNavigate();

  //#region staten
  const [user] = useState(getUser())
  const [pair, setPair] = useState<Pair | null>(null)
  const [loading, setLoading] = useState(false)
  const [invites, setInvites] = useState<Invite[]>([])
  const [yourInvites, setYourInvites] = useState<Invite[]>([])
  const [error, setError] = useState<string | null>(null)

  const [memberDialogData, setMemberDialogData] = useState<MemberDialogProps>({
    open: false,
    action: "",
  })
  const [inviteCodeDialog, setInviteCodeDialog] = useState({
    open: false,
    code: "",
  })
  const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationDialogProps>({
    open: false,
    message: "",
    resource: "",
    id: null,
    action: "",
  })
  //#endregion

  useEffect(() => {
    if (user && user.plan) {
      setLoading(true)
      fetchInvites()
      getSetterPair(user?.email, false).then((pair: Pair) => {
        setPair(pair)
        setLoading(false)
      })
    } else {
      logOut()
      setUser(null)
      navigate("/ask")
    }
  }, [])

  async function fetchInvites() {
    const invitesRef = collection(fs, "invites")
    const invitesQuery = query(invitesRef, where('setterId', '==', user.setterId), where('status', '==', 'active'));
    const invites = await getDocs(invitesQuery);
    if (invites.empty) {
      // console.log("empty")
      setInvites([])
    } else {
      const invitesData: Invite[] = [];
      invites.forEach((doc) => {
        const invite = doc.data();
        invitesData.push({ ...invite, id: doc.id, created: invite.created?.toDate(), expires: invite.expires?.toDate() } as Invite);
      });

      setInvites(invitesData)
    }
    const yourInvitesQuery = query(invitesRef, where('email', '==', user.email), where('status', '==', 'active'));
    const yourInvites = await getDocs(yourInvitesQuery);
    if (yourInvites.empty) {
      // console.log("empty")
      setYourInvites([])
    } else {
      // console.log("not empty")
      const yourInvitesData: Invite[] = [];
      yourInvites.forEach((doc) => {
        const invite = doc.data();
        yourInvitesData.push({ ...invite, id: doc.id, created: invite.created?.toDate(), expires: invite.expires?.toDate() } as Invite);
      });

      setYourInvites(yourInvitesData)
    }
  }

  const clickAddMember = () => {
    // console.log("add")
    setError(null)
    setMemberDialogData({
      open: true,
      action: "add"
    })
  }

  const clickUpdateMember = (email: string, permissions: string[], uid: string) => {
    // console.log("update", permissions)
    setError(null)
    setMemberDialogData({
      open: true,
      action: "update",
      uid,
      email,
      permissions: Object.keys(PermissionsEnum).reduce((prev: any, curr: string) => {
        prev[curr] = permissions.includes(PermissionsEnum[curr])
        return prev
      }, {})
    })
  }

  const handleCloseMemberDialog = () => {
    setMemberDialogData({
      open: false,
      action: ""
    })
  }

  const handleAddNewMember = async (form: any) => {
    console.log("form", form)
    const { email, permissions } = form

    setMemberDialogData({
      open: false,
      action: "",
    })
    console.log("email", email)

    // console.log("noPermissions", noPermissions)

    if (!pair || !user) return alert("Error")

    try {
      setLoading(true)
      const epubKey = await getPubKey(email)
      console.log("epubKey", epubKey)

      if (memberDialogData.action !== "update") {
        const { encryptedKeys, codes } = await encryptKeys(pair, 1)

        const code = codes[0]
        let encrypted = encryptedKeys[0]
	console.log(encrypted)

        // Gun encrypt Keys pair
	if (epubKey) {
	  const aes = await SEA.secret(epubKey, pair)
          const encryptedKey = await SEA.encrypt(pair, aes)
	  encrypted = {codeHash:"", encrypted: encryptedKey}
	}

        // Generate invite code
        const invite: Invite = {
          email, permissions,
          setterId: user.setterId,
          setter: user.email,
          ...encrypted,
          created: new Date(),
          expires: new Date(moment().add(3, 'days')),
          status: "active",
	  epubKey: epubKey ? pair.epub : "",
	  keys: null,
	}

        console.log("invite", invite)

        // Check if was invited before
        const invitesRef = collection(fs, "invites")
        const invitesQuery = query(invitesRef, where('email', '==', email));
        const invitesSnapshot = await getDocs(invitesQuery);
        if (!invitesSnapshot.empty) {
	  setLoading(false)
          return setError("This email address was invited before")
        } else {
          // Create invite and add to list
          const newInvite = await addDoc(invitesRef, invite)
          setInvites([...invites, { ...invite, id: newInvite.id }])
          setLoading(false)
          // Send email through cloud function trigger
        }
        if (!epubKey) {
          setInviteCodeDialog({
            open: true,
            code,
          })
	}
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      setError(error.message)
    }
  }

  const handleConfirm = async () => {
    const { resource, id, action } = confirmationDialog
    if (!id) return setError("ID not found")
    console.log("confirmationDialog", confirmationDialog)
    try {
      const docRef = doc(fs, resource, id!)
      if (action === "accept") {
	const invite = await getDoc(docRef)
	if (invite.exists()) {
	  const inviteData = invite.data()
	  const memberRef = collection(fs, "members")
	  await addDoc(memberRef, inviteData)
	  fetchInvites()
	}
      } else if (action === "delete") {
	await deleteDoc(docRef)
	if (resource === "invites") setInvites(invites.filter((invite: Invite) => invite.id !== id))
      }

    } catch (error: any) {
      console.error("error", error)
      setError(`Error deleting ${resource} - ${id}`)
    } finally {
      setConfirmationDialog({
        open: false,
        message: "",
        resource: "",
	action: "",
        id: "",
      })
    }

  }

  // TODO: firestore rules read either by admin or same user (on snapshot for permissions)

  return (
    <Box className={classes.wrapper}
      sx={{ display: { sm: "flex" }, flexDirection: { sm: "column" }, alignItems: { sm: "center" }, }}
    >
      <WrapperCard>
        <CardContent sx={{ width: "100%", display: "flex", flexDirection: "column", overflowY: "auto", "-webkit-overflow-scrolling": "touch" }}>
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", my: 2 }}>
              <ArrowBackIcon sx={{ fontSize: 20, mr: 1, cursor: "pointer" }} onClick={() => { navigate("/ask") }} />
              <Typography variant='h5' sx={{ fontWeight: "bold" }}> Team</Typography>
            </Box>

            {error && <Alert severity="error" sx={{ textAlign: "left" }}>{error}</Alert>}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {
                !checkLimitsReached(PLAN_LIMIT_users, user?.plan, invites?.length) || checkLimitsReached(PLAN_LIMIT_users, user?.plan, invites?.length)! <= 0 ? (
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button variant="contained" color="secondary"
                      onClick={(e) => {
                        navigate("/ask/plans")
                      }}
                    >
                      Upgrade plan
                    </Button>
                    <Typography sx={{ mt: 1 }} variant="caption">Users limit reached!</Typography>
                  </Box>
                ) :
                  <LoadingButton variant="contained" size="medium" color="secondary" sx={{ my: 1 }}
                    onClick={clickAddMember}
                    startIcon={<AddIcon />}
                    disabled={checkLimitsReached(PLAN_LIMIT_users, user?.plan, invites?.length)! <= 0}
                  >
                    Add Member
                  </LoadingButton>
              }
            </Box>

            <Box sx={{ flexGrow: 1, minHeight: 100, mt: 2 }}>
              {loading ? "loading ..." : !yourInvites?.length ? "Your are not a member of a Team" : yourInvites?.length !== 0 && (
                <Box sx={{ textAlign: "left", mt: 2 }}>
                  <Typography variant="h6" >Your Teams</Typography>
                  {yourInvites.map((invite: Invite) => {
                    return (
                      <Box key={invite.setter}>
                        <Box sx={{ mt: 1, textAlign: "left", display: "flex", justifyContent: "space-between" }}>
                          <Typography sx={{ fontWeight: "bold" }}>{invite.setter}</Typography>
                          <Box>
                            {/* <EditIcon fontSize="small" sx={{ mr: 1, cursor: "pointer" }} /> */}
                            <DeleteIcon
			    fontSize="small" sx={{ mr: 1, cursor: "pointer" }} color="primary"

			    onClick={() => { setConfirmationDialog({ open: true, action: "delete", message: `Are you sure you want to remove my self from the team ${invite.setter}?`, resource: "invites", id: invite.id! }) }}
                            />
                          </Box>
                        </Box>
                        <Accordion classes={{ root: classes.accordionRoot }} sx={{ mb: 3 }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.summaryRoot, expanded: classes.summaryExpanded, }}>
                            <Typography variant="body2">Permissions</Typography>
                          </AccordionSummary>
                          <AccordionDetails classes={{ root: classes.accordionDetails }}>
                            <ul style={{ margin: 0 }}>
                              {invite.permissions.map((perm: string) => {
                                return (
                                  <li key={perm}>{perm.replaceAll("_", " ")}</li>
                                )
                              })}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                        <Divider />
                      </Box>
                    )
                  })}
                </Box>
              )}
              {invites?.length ? "No Team Members" : (
                <Box sx={{ textAlign: "left", mt: 2 }}>
                  {invites.map((invite: Invite) => {
                    return (
                      <Box key={invite.email}>
                        <Box sx={{ mt: 1, textAlign: "left", display: "flex", justifyContent: "space-between" }}>
                          <Typography sx={{ fontWeight: "bold" }}>{invite.email}</Typography>
                          <Box>
                            {/* <EditIcon fontSize="small" sx={{ mr: 1, cursor: "pointer" }} /> */}
                            <DeleteIcon
			    fontSize="small" sx={{ mr: 1, cursor: "pointer" }} color="primary"

			    onClick={() => { setConfirmationDialog({ open: true, action: "delete", message: `Are you sure you want to remove team member ${invite.email}?`, resource: "invites", id: invite.id! }) }}
                            />
                          </Box>
                        </Box>
                        <Accordion classes={{ root: classes.accordionRoot }} sx={{ mb: 3 }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.summaryRoot, expanded: classes.summaryExpanded, }}>
                            <Typography variant="body2">Permissions</Typography>
                          </AccordionSummary>
                          <AccordionDetails classes={{ root: classes.accordionDetails }}>
                            <ul style={{ margin: 0 }}>
                              {invite.permissions.map((perm: string) => {
                                return (
                                  <li key={perm}>{perm.replaceAll("_", " ")}</li>
                                )
                              })}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                        <Divider />
                      </Box>
                    )
                  })}
                </Box>
              )}
            </Box>

          </Box>
        </CardContent>
      </WrapperCard>

      <MemberDialog
        {...memberDialogData}
        onClose={handleCloseMemberDialog}
        onContinue={handleAddNewMember}
      />

      <InviteCodeDialog
        {...inviteCodeDialog}
        onContinue={() => { setInviteCodeDialog({ open: false, code: "" }) }}
      />

      <ConfirmationDialog
        {...confirmationDialog}
        handleClose={() => { setConfirmationDialog({ open: false, action:"", message: "", resource: "", id: "" }) }}
        handleConfirm={handleConfirm}
      />
    </Box >
  )
}
