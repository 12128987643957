import React, { useState, useEffect } from "react"

import {
  Box, CardContent, Typography, Fab, Dialog, Link, Divider, IconButton,
  Button, Alert, DialogTitle, DialogContent, TextField, Avatar,
  MenuItem, ListItemIcon, Menu, Badge
} from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CopyOutlinedIcon from '@mui/icons-material/FileCopy';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import SaveIcon from '@mui/icons-material/Save';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import ReplySharpIcon from '@mui/icons-material/ReplySharp';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import SignatureIcon from "../../images/sig purple.png"

import { ExtendedAnswer, Field } from "../../services/types";

import { SEA } from "../../services/gun"

import { doc, fs, setDoc, collection, getDocs, orderBy, query, getDoc } from "../../services/firebase";

import SeeAnswer from "./SeeAnswer";

import { DATE_FORMAT, DATE_FORMAT_NO_SECONDS } from "../../Helpers/dates";
import { WriteNFC } from "../../Helpers/NFC";
import { useNavigate } from "react-router";
import { arrayUnion } from "firebase/firestore";

import { PermissionsEnum, checkPermission } from "../../Helpers/permissions";
import { getUser } from "../../Helpers/localStorage";
import theme from "../../theme";
import GDPRDialog from "../../components/GDPRDialog";

import { QRCode } from 'react-qrcode-logo'
import { QrSettings } from '../../Helpers/qrcodeSettings';
import { maxHeight } from '../../Helpers/height';

import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import WrapperCard from "../../components/WrapperCard";
import { getQuestions, Question } from "../../services/questions";
import themeConfig from "../../theme";
import { formatDate, parseDate } from "../../Helpers/dates";

import makeStyles from "@mui/styles/makeStyles";
import {isMobile} from "react-device-detect";
import {async} from "@firebase/util";
import Papa from "papaparse";
import FlipCard from '../../components/FlipCard';
import { isIOS } from 'react-device-detect'

function fbtimeStampToDate(theDate: any) {
  //date from firebase is represented as an object with seconds and nanoseconds
  if (theDate.seconds) {
    const fireBaseTime = new Date(
      theDate.seconds * 1000 + theDate.nanoseconds / 1000000,
    )
    return fireBaseTime.toDateString()
  }
  if (theDate.toDate) {
    return theDate.toDate().toDateString()
  }
  if (theDate.toDateString) {
    return theDate.toDateString()
  }
  if (typeof theDate === "string") {
    return theDate
  }
  return "unknown"
}  

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function DescriptionAccordions(props) {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  
  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{props.questionSet.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography> {props.questionSet.description}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Questions</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {props.questionSet.fields.map((question, index) => {
          if (question.type === "text") {
            return (
              <Typography key={index}>
                {question.label}
              </Typography>
            )
          }
          else if (question.type === "select") {
            return (
              <Typography key={index}>
                {question.label} : <small> {Object.values(question.options).join(", ")} </small>
              </Typography>
            )
          }
          return (<Typography> {question.label} </Typography>)
        })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

// import { addDoc, collection } from "firebase/firestore";
// import { fs } from "../../services/firebase";

const useStyles = makeStyles(_ => ({
  cardContent: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  questionButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    borderRadius: 2,
  }
}))

//types
// Type definitions for Web NFC
// Project: https://github.com/w3c/web-nfc
// Definitions by: Takefumi Yoshii <https://github.com/takefumi-yoshii>
// TypeScript Version: 3.9

// This type definitions referenced to WebIDL.
// https://w3c.github.io/web-nfc/#actual-idl-index

declare global {
  interface Window {
    NDEFReader: any;
  }
}

const PARROT_LINK = "https://parrot.cards"
export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mimeType = arr[0].match(/:(.*?);/)[1],
    decodedData = atob(arr[1]),
    lengthOfDecodedData = decodedData.length,
    u8array = new Uint8Array(lengthOfDecodedData);
  while (lengthOfDecodedData--) {
    u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData);
  }
  return new File([u8array], filename, { type: mimeType });
}
export function QuestionSetShare(props: any) {

  const [NFCSteps, setNFCSteps] = useState<any>(null)

  const onClickNFC = () => {
    if (window.NDEFReader) {
      setNFCSteps("No")
      WriteNFC(PARROT_LINK + "/" + props.id, () => {
        alert("All good")
        setNFCSteps(null)
      }, (err) => alert(err))
    } else {
      setNFCSteps("Yes")
    }
  }
  const downloadQr = () => {
    const canvas = document.getElementById("react-qrcode-logo") as HTMLCanvasElement
    const link = document.createElement('a');
    link.download = `${props.name}-${props.id}.png`;
    link.href = canvas.toDataURL()
    link.click();
  }
  const webShare = () => {
    if (navigator.share) {
      const canvas = document.getElementById("react-qrcode-logo") as HTMLCanvasElement
      let files: any[] = []
      files.push(dataURLtoFile(canvas.toDataURL("image/jpeg"), props.name + "-" + props.id + ".jpg"))
      navigator.share({
        title: props.name,
        text: props.name,
        url: PARROT_LINK + "/" + props.id,
        files,
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    } else {
      alert("Web share not supported")
    }
   }
  const navShare = !!navigator.share
  const id = props.id.split("-")[0]
  console.log("id", id)
  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <Box sx={{ px: 5, py: 7, textAlign: "center" }}>
        {!NFCSteps ? <Box>
          <Typography variant="h5">{id}</Typography>

          <Box sx={{ mt: 3, mb: 2 }}>
            <Button href={"/" + id} className="qrlink">
              <QRCode {...QrSettings} value={PARROT_LINK + "/" + id} size={400} />
            </Button>
          </Box>
          <Button type="button" value="Download" onClick={downloadQr}>Download</Button>
          {navShare && <Button type="button" value="Download" onClick={webShare}>share</Button>}
          <br />

          <Link variant="h6" color="primary" href={PARROT_LINK + "/" + id}>parrot.cards/{id}</Link>
          <br />
          <Button color="primary" onClick={onClickNFC} size="large" variant="contained" sx={{ mt: 2 }}> NFC </Button>

        </Box> : <Box sx={{ textAlign: "left" }}>
          {NFCSteps == "Yes" ? <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant="h6" sx={{ textAlign: "center" }}>NFC</Typography>

            <br />
            To make a Parrot NFC card with your iPhone, follow these simple steps:
            <br />
            <ul>
              <li>
                Download the free "NFC & RFID for iPhone" app from the App Store.
              </li>
              <li>
                Open the app, and choose "Write NFC Tag".
              </li>
              <li>
                Tap "Create NFC Message" and then "Web Link".
              </li>
              <li>
                Paste your Parrot link in the space provided.
                <br />
                Link: {PARROT_LINK + "/" + props.id}
              </li>
              <li>
                Save it and return to the "Write NFC Tag" screen.
              </li>
              <li>
                Pick your recent web link, and hit "Continue".
              </li>
              <li>
                Press the big blue "Write NFC Tag" button.
              </li>
              <li>
                Finally, hold a blank NFC card to the top back of your phone.
              </li>
            </ul>
            Now, your Parrot link is saved on the card and anyone can access it by tapping their phone to the card.
            <br />
            <br />
          </Box> : <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            Please tap your card!
            <br />
            <TapAndPlayIcon sx={{ fontSize: 50, mt: 1 }} />
          </Box>}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button color="primary" onClick={() => { setNFCSteps(false) }} sx={{ mt: 2 }}> Close </Button>
          </Box>
        </Box>}
      </Box>
    </Dialog>
  )
}

function ConfirmationDialog(props: any) {
  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <Box sx={{ px: 5, py: 7, textAlign: "center" }}>
        <Typography variant="h6">Are you sure you want to {props.action} the question set "{props.name}"?</Typography>
        {/* <Typography variant="h5" sx={{ my: 2 }}>code</Typography> */}
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 3, mt: 1.5 }}>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={props.handleConfirm} sx={{ color: "gray" }}>Confirm</Button>
        </Box>
      </Box>
    </Dialog>
  )
}

function DataTableDialog(props: any) {
  return (
    <Dialog onClose={props.handleClose} open={props.open} full-screen>
      <Box sx={{ px: 5, py: 7, textAlign: "center" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
	        {(props.fields || []).map((field) => (
                  <TableCell>{field.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(props.answers || []).map((answer) => (
                <TableRow
                  key={answer.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
		  {answer.fields.map((field) => (
                    <TableCell component="th" scope="row">
                      {field.decrypted}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 3, mt: 1.5 }}>
          <Button onClick={props.handleClose}>Done</Button>
        </Box>
      </Box>
    </Dialog>
  )
}
function ConfirmUpdateDialog(props: any) {
  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <Box sx={{ px: 5, py: 7, textAlign: "center" }}>
        <Typography variant="h6">Changing the question set will generate a new code</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 3, mt: 1.5 }}>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={props.handleConfirm} sx={{ color: "gray" }}>Change</Button>
        </Box>
      </Box>
    </Dialog>
  )
}

type ThirdParty = {
  name: string;
  date: Dayjs | null;
}
function ThirdPartyShareDialog(props: any) {
  const [newTP, setNewTP] = useState(false)

  const [form, setForm] = useState<ThirdParty>({
    name: "",
    date: dayjs(new Date())
  })

  const addNewTP = () => {
    if (form.name?.trim() === "") return

    // console.log(form)
    props.addNew({
      ...form,
      date: form.date?.toDate() || new Date(),
    })

    handleReset()
  }

  const handleReset = () => {
    // Clear form
    setForm({
      name: "",
      date: dayjs(new Date())
    })

    // Back to list
    setNewTP(false)
  }

  return (
    <Dialog
      onClose={props.handleClose} open={props.open}
      aria-labelledby="third-party-dialog-title"
      aria-describedby="third-party-dialog-description"

      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle id="third-party-dialog-title">
        {newTP ? "New Third Party" : "Third Parties"}
      </DialogTitle>
      <DialogContent>
        {newTP ? (<Box>
          <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
            <TextField
              required
              id="third-party"
              label="Third Party"

              value={form.name}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value })
              }}

              sx={{ mb: 1.5 }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="DatePicker"
                label="Date"
                inputFormat="DD/MM/YYYY"

                value={form.date}
                onChange={(newValue) => {
                  setForm({ ...form, date: newValue })
                }}
                renderInput={(params) => <TextField className="Date" {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              variant="contained"
              onClick={addNewTP}
            >
              Add
            </Button>
            <Button
              variant="text"
              sx={{ ml: 1 }}
              onClick={handleReset}
            >
              Cancel
            </Button>
          </Box>
        </Box>) : !props.thirdParties || props.thirdParties?.length === 0 ? (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box>
              <Typography>
                The answers are not shared with any Third Parties.
              </Typography>
            </Box>
          </Box>) : (<Box>
            <Typography sx={{ mb: 1 }}>
              The answers were shared with the following Third Parties
            </Typography>
            {props.thirdParties?.map((tp: ThirdParty) => {
              return <Box key={tp.name} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <BusinessIcon sx={{ mr: 1 }} /> {tp.date} - {tp.name}
              </Box>
            })}
          </Box>)}
        {!newTP && <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button variant="contained" onClick={() => { setNewTP(true) }}>
            <AddIcon fontSize="small" sx={{ mr: .5 }} />
            Add
          </Button>
        </Box>}
      </DialogContent>
    </Dialog>
  )
}

const moment = require('moment');
moment().format();

const SHOW_TIMES = {
  "1m": { label: "1 minute", minutes: 1 },
  "10m": { label: "10 minutes", minutes: 10 },
  "1h": { label: "1 hour", minutes: 60 },
  "1d": { label: "1 day", minutes: 1440 },
}

export default function SavedQuestionSet(props: any) {
  const classes = useStyles();

  const navigate = useNavigate()

  //#region State
  const [user] = useState(getUser())

  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [dataProps, setDataProps] = useState({
    open: false,
    action: "",
  })
  const [confirmationProps, setConfirmationProps] = useState({
    open: false,
    action: "",
  })
  const [confirmUpdateOpen, setConfirmUpdateOpen] = useState(false)

  const [thirdPartyOpen, setThirdPartyOpen] = useState(false)
  const [thirdParties, setThirdParties] = useState<ThirdParty[]>([])

  const [flip, setFlip] = useState(false)

  const [answers, setAnswers] = useState<ExtendedAnswer[] | null>(null)
  const [answersId, setAnswersId] = useState<ExtendedAnswer[]>([])
  const [answerShowing, setAnswerShowing] = useState<ExtendedAnswer | null>(null)

  const [hiddenAnswers, setHiddenAnswers] = useState([])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [GDPROpen, setGDPROpen] = useState(false)
  const [questionsList, setQuestionsList] = useState<Question[]>([])
  //#endregion

  //#region Effects
  useEffect(() => {
    getQuestions().then((questions) => {
      // console.log("questions", questions.length)
      setQuestionsList(questions)
    })
    return () => {
    }
  }, [])

  useEffect(() => {
    if (JSON.stringify(props.answers) !== JSON.stringify(answersId) ||
      JSON.stringify(props.hiddenAnswers) !== JSON.stringify(hiddenAnswers)) {
      console.log("loadAnswers", props.questionSet.name, props.answers?.length, props.hiddenAnswers?.length)
      // console.log("props.answers", props.answers)
      // console.log("props.hiddenAnswers", props.hiddenAnswers)
      setAnswersId(props.answers)
      setHiddenAnswers(props.hiddenAnswers)
      loadAnswers()
    } else if (!props.answers?.length) {
      setAnswers(answers || [])
    }
  }, [props.answers, props.hiddenAnswers])

  useEffect(() => {
    // Start timeout with minutes left
    if (answerShowing && answerShowing.available) {
      // console.log("before timeout")
      let minutesAvailable = SHOW_TIMES[answerShowing.time].minutes

      const timeoutId = setTimeout(async () => {

        const savedFields = answerShowing.fields.filter((field: Field) => field.action === "save") || []
        // console.log("timeout", savedFields)

        const updatedHistory = await getAnswerHistory(answerShowing.id)
        const answer = {
          ...answerShowing,
          available: false,
          saved: savedFields?.length !== 0,
          fields: savedFields,// replace fields by only the saved one
          signatures: updatedHistory,
          signed: updatedHistory.length > 1 ? updatedHistory[updatedHistory.length - 1]?.action === "sign" : false,
        }

        console.log("new answer", answer)

        // Delete see only fields
        deleteSeeOnlyFields(answer.id)

        setAnswers(answers?.map((ans: ExtendedAnswer) => {
          if (ans.id !== answer.id) return ans

          return answer
        }) as ExtendedAnswer[] | null)

        console.log("updated respponse", answer)
        // How to check that the current answer showing is this one!
        if (answerShowing?.id === answer.id) {
          setAnswerShowing(answer)
        }
      }, minutesAvailable * 60 * 1000)

      return () => clearTimeout(timeoutId)
    }
  }, [answerShowing])

  useEffect(() => {
    if (props.questionSet) {
      // console.log(props.questionSet.sharedDataWith)
      let sharedDataWith = props.questionSet.sharedDataWith || []
      sharedDataWith = sharedDataWith.map((tp) => {
        return {
          ...tp,
          date: formatDate(tp.date.toDate())
        }
      })
      setThirdParties(sharedDataWith)
    }
  }, [props.questionSet])
  //#endregion

  //#region Handlers
  async function loadAnswers() {
    // console.log("loadAnswers")
    let answers: ExtendedAnswer[] = await Promise.all(props.answers?.map(async (ansHash: string) => {
      // Get data from answer hash
      return await new Promise(async (resolve) => {
        try {
          const docRef = doc(fs, "answers", props.pair.pub, "hash", ansHash)
          const data = await getDoc(docRef)
          if (!data.exists) return resolve(null)

          const answer = data.data()
          if (!answer) return resolve(null)

          const responderEpub = answer.responderEpub

          // Get when answer was made
          const created = answer.created || formatDate(new Date())

          // Check extended first. if seen then don't get the data
          const time = answer.time || "1m" // Default 1m

          const history = await getAnswerHistory(ansHash)
          // console.log(ansHash, history)

          let extended: any = {}
          if (!history.length) {
            extended = {
              seenAt: null,
              signed: false,
              available: true,
              signatures: [],
            }
          } else if (history.length === 1 && history[0].action === "third-party") {
            extended = {
              seenAt: null,
              signed: false,
              available: true,
              signatures: history,
            }
          } else {
            const seeSignature = history.find((h) => h.action === "see")
            const seenAt = new Date(seeSignature.date)

            const { available, minutesLeft } = checkIsAvailable(seenAt, time as string)

            let minutesLeftString = ""
            // if (minutesLeft < 60) {
            minutesLeftString = minutesLeft + " minutes"
            // } else {
            // TODO hours and minutes
            // }

            extended = {
              seenAt: formatDate(seenAt),
              available,
              minutesLeft: minutesLeftString,
              signed: false,
              signatures: history,
            }

            // Check if last action was sign and revoke
            const onlySignRevoke = history.filter((h) => h.action === "sign" || h.action === "revoke")
            if (onlySignRevoke.length) {
              const lastSignature = onlySignRevoke[onlySignRevoke.length - 1]
              extended = {
                ...extended,
                signed: lastSignature.action === "sign",
              }
            }
          }

          // console.log("extended", ansHash, extended)

          let decrypted = {
            id: ansHash,
            fields: [] as Field[],
            timestamp: parseDate(created as string),
            saved: false,
            userEpub: responderEpub,
          }

          if (extended.available) {
            console.log("available", ansHash/* , answer */)
            const secret: string = await SEA.secret(responderEpub, props.pair)
            try {
              decrypted = (await SEA.decrypt(answer.encrypted, secret))
            } catch (error) {
              console.error("Error decrypting", ansHash)
            }
            // console.log("decrypted response", ansHash, decrypted, answer.encrypted, secret)
          } else {
            // console.log("not available", answer.saved, answer.encrypted)
            let fields: Field[] = []
            const secret: string = await SEA.secret(responderEpub, props.pair)
            try {
              fields = (await SEA.decrypt(answer.saved || answer.encrypted, secret))
              // console.log("all fields", ansHash, fields)
              fields = fields?.filter((field: Field) => field.action === "save") || []

            } catch (error) {
              console.error("Error decrypting")
            }

            // console.log("decrypted", ansHash, fields)
            decrypted = {
              ...decrypted,
              saved: fields?.length !== 0,
              fields,
            }

            // const fields = await getSavedFields(ansHash, clientEpub) || []
            // Delete the rest of the response
            // deleteSeeOnlyFields(answer.id)
          }

          const shortId = decrypted.id?.slice(-8).toUpperCase()

          const hidden = props.hiddenAnswers?.includes(decrypted?.id)
          if (decrypted !== undefined) {
            resolve({
              ...decrypted,
              ...extended,
              time,
              shortId,
              fields: !hidden ? decrypted.fields : [],
              hidden,
            })
          } else {
            resolve(null)
          }
        } catch (err) {
          console.error(ansHash, err)
          resolve(null)
        }
      })
      // })
    }))

    answers = answers.filter((answer: ExtendedAnswer) => answer !== null)

    // Order by most recent
    answers.sort(function (a: ExtendedAnswer, b: ExtendedAnswer) {
      return new Date(a.timestamp!) < new Date(b.timestamp!) ? 1 : -1
    })

    // Filter hidden answers
    answers = answers.filter((answer: ExtendedAnswer) => !answer.hidden)

    // console.log("answers", props.questionSet.id, answers.length)
    setAnswers(answers || [])
    // TODO encrypt answers for the drydashboard if key is available
    // get pubKeys
    getPubKeys(props.questionSet.id).then((pubKeys: any) => {
      console.log("pubKeys", pubKeys)
      pubKeys.forEach(async (pubKey = {pub:"", epub:""}) => {
        if (pubKey.pub && pubKey.epub) {
          const secret = await SEA.secret(pubKey.epub, props.pair)
          console.log("secret", secret)
          const JustFields = answers.map( async (answer: ExtendedAnswer) => {
            const fields = await Promise.all(answer.fields?.map( async (field: Field) => {
              console.log("field", field)
              return await SEA.encrypt(field, secret)
            })
            )
            return {
              ...answer, fields
            }
          })
          console.log("JustFields", await Promise.all(JustFields))
          const data = await Promise.all(JustFields)
          window.fetch("https://us-central1-dry-board.cloudfunctions.net/addData", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: "marcus@hi9.io", id: props.questionSet.id, data
            })
          })
        }
        // post to drydashboard
      })
    })
  }

  function getPubKeys(id) {
    return new Promise(async (resolve) => {
      try {
        const docRef = doc(fs, "questionSets", id)
        const data = await getDoc(docRef)
        if (!data.exists) return resolve([])
        return resolve(Object.values(data.data()?.pubKeys || {}))
      } catch (err) {
        console.error(err)
        return resolve([])
      }
    })
  }

  function checkIsAvailable(seenAt: Date, time: string) {
    const minutesSinceSeen = moment.duration(moment().diff(moment(seenAt, DATE_FORMAT))).minutes()
    const totalMinutes = SHOW_TIMES[time].minutes

    // console.log("minutesSinceSeen", minutesSinceSeen, seenAt, moment().format(DATE_FORMAT) )
    // console.log("minutesSinceSeen <= totalMinutes", minutesSinceSeen <= totalMinutes, minutesSinceSeen, totalMinutes, totalMinutes - minutesSinceSeen)
    return {
      available: minutesSinceSeen < totalMinutes,
      minutesLeft: totalMinutes - minutesSinceSeen,
    }
  }

  async function saveFields(ansHash: string, fields: Field[], responderEpub: string) {
    const toSave = fields.filter((field: Field) => field.action === "save")
    const secret: string = await SEA.secret(responderEpub, props.pair)
    const enc = await SEA.encrypt(toSave, secret)
    const data = await SEA.sign(enc, props.pair)
    const msg = await SEA.verify(data, props.pair?.pub)
    // console.log("saveFields msg", toSave, msg)

    // Firestore save to-save fields encrypted with setter pub
    const docRef = doc(fs, "answers", props.pair.pub, "hash", ansHash)
    await setDoc(docRef, {
      saved: msg
    }, { merge: true })
  }

  async function deleteSeeOnlyFields(ansHash: string) {
    const docRef = doc(fs, "answers", props.pair.pub, "hash", ansHash)
    await setDoc(docRef, {
      encrypted: null
    }, { merge: true })
  }

  const handleViewAnswer = async (answer: ExtendedAnswer) => {
    console.log("handleViewAnswer", answer)

    if (!props.pair) return

    let minutesAvailable = SHOW_TIMES[answer.time].minutes

    if (!answer.seenAt) {
      console.log("first time")
      answer = await handleAnswerActions(answer, "see")

      // Save fields to save
      await saveFields(answer.id, answer.fields, answer.userEpub)
    } else {
      const { available, minutesLeft } = checkIsAvailable(answer.seenAt || new Date(), answer.time)

      if (!available) {
        const savedFields = answer.fields.filter((field: Field) => field.action === "save") || []
        answer = {
          ...answer,
          available: false,
          saved: savedFields?.length !== 0,
          fields: savedFields, // replace fields by only the saved one
        }

        // Delete the rest of the response from firestore
        deleteSeeOnlyFields(answer.id)

        setAnswers(answers?.map((ans: ExtendedAnswer) => {
          if (ans.id !== answer.id) return ans

          return answer
        }) as ExtendedAnswer[] | null)
      } else {
        minutesAvailable = minutesLeft
      }
    }

    props.setCarouselDisabled(true)

    // Set answer showing
    setAnswerShowing(answer)

  }

  const handleStopViewAnswer = () => {
    setAnswerShowing(null)
    props.setCarouselDisabled(false)
  }

  // See answer card, sign it, or revoke it
  async function handleAnswerActions(answer: ExtendedAnswer, action: "see" | "sign" | "revoke" | "accept" | "reject" | "third-party" | "download", additional: any = {}) {
    const data = { id: answer.id, date: Date.now(), action, user: user?.email, ...additional }
    // console.log("signing", data)

    const signature = await SEA.sign(data, props.pair)
    // console.log("signing ...", signature)

    setDoc(doc(fs, `cardAuditions/${data.id}/signatures/${data.date}`), { signature, ...data }).then(() => {
      console.log(action, answer.id)
    }).catch((error) => {
      console.error(error)
    })

    switch (action) {
      case "see":
        answer = { ...answer, seenAt: formatDate(new Date()) }
        break
      case "sign":
        answer = { ...answer, signed: true }
        break
      case "revoke":
        answer = { ...answer, signed: false }
        break
    }

    answer = {
      ...answer,
      signatures: [...answer.signatures, { signature, ...data }]
    }

    setAnswers(answers?.map((ans: ExtendedAnswer) => {
      if (ans.id !== answer.id) return ans

      return answer
    }) as ExtendedAnswer[] | null)

    if (["see", "sign", "revoke"].includes(action)) setAnswerShowing(answer)

    return answer
  }

  async function getAnswerHistory(id: string) {
    const querySnapshot = await getDocs(query(collection(fs, "cardAuditions", id, "signatures"), orderBy('date')));
    const history: any[] = []
    querySnapshot.forEach((doc) => {
      history.push(doc.data())
    });

    return history
  }
  function getTerms(id) {
    const docRef = doc(fs, "setters", id)
    console.log("docRef", docRef)
    return getDoc(docRef).then((data) => {
      if (!data.exists) return ""
      if (!data.data()?.terms) return "" 
      return data.data()?.terms
    })
  }

  const handleConfirmDialog = () => {
    setConfirmationProps({ open: false, action: "" })
    if (confirmationProps.action === "delete" && !answersId?.length) props.deleteQuestionSet(props.questionSet.id)
    else if (confirmationProps.action === "archive") props.archiveQuestionSet(props.questionSet.id)
  }

  const handleCopyQuestionSet = () => {
    if (answersId?.length) return
    props.copyQuestionSet(props.questionSet)
  }

  const handleUpdateQuestionSet = () => {
    setConfirmUpdateOpen(false)
    if (answersId?.length) return
    props.updateQuestionSet(props.questionSet)
  }

  const handleArchiveQuestionSet = () => {
    if (!checkPermission(PermissionsEnum.CUD_QS, user?.permissions)) return
    // TODO: show confirmation message
    props.archiveQuestionSet(props.questionSet.id)
  }

  const handleNewThirdParty = async (thirdParty: ThirdParty) => {
    // console.log("handleNewThirdParty", thirdParties.length, thirdParty)
    try {
      const docRef = doc(fs, "questionSets", props.questionSet.id)
      await setDoc(docRef, {
        sharedDataWith: thirdParties.length ? arrayUnion(thirdParty) : [thirdParty]
      }, { merge: true })

      // Update third parties
      setThirdParties([...thirdParties, {
        ...thirdParty,
        date: formatDate((thirdParty.date || new Date()) as Date)
      }])

      console.log("answers", answers)
      // Add card update for each answer
      answers?.forEach(async (answer: ExtendedAnswer) => {
        console.log("answer", answer.id)
        await handleAnswerActions(answer, "third-party", { thirdPartyName: thirdParty.name })
      })

    } catch (error) {
      console.error(error)
    }
  }
  function downloadCSV() {
    console.log(answers)
    const csv = Papa.unparse(answers?.map((answer: ExtendedAnswer) => {
      return {
        id: answer.id,
        ...answer.fields.reduce((acc: any, field: Field) => {
          acc[field.label] = field.decrypted
          return acc
        }, {timestamp: answer.timestamp})
      }
    }))
    const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    const csvURL = window.URL.createObjectURL(csvData);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'parrot.csv');
    tempLink.click();
  }

  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };
  //#endregion
  return (
    <FlipCard flip={flip} item={{key: props.questionSet.id}} isIOS={isIOS}>
      <CardContent className={classes.cardContent}>
        {
          props.pair.pub !== props.questionSet.setterPub && (
            <Alert severity="info">This question set was made on a different device.</Alert>
          )
        }
 
        <Box className={classes.title} sx={{ mt: 1 }}>
          <Typography variant="h6" color="primary" >
            Question Set 
          </Typography>
          <Typography variant="h6" color="primary">{props.questionSet.id} {props.answers.length > 0 && <Badge badgeContent={props.answers.length} color="primary" sx={{ ml: 1 }} />}</Typography>
          {/* <ShareRoundedIcon fontSize="large" sx={{ color: themeConfig.palette.tertiary.main }} onClick={() => { setDialogOpen(true) }} /> */}
        </Box>
 
        {props.questionSet.expiryDate ? <Typography sx={{ my: .5, textAlign: "right" }} variant="body1">Expires: {fbtimeStampToDate(props.questionSet.expiryDate)?.substring(0, 10) || "N/A"}</Typography>
          : <Typography sx={{ my: .5, textAlign: "right" }} variant="body1">Created: {fbtimeStampToDate(props.questionSet.created)?.substring(0, 10) || ""}</Typography>
        }
        <Box sx={{ height: maxHeight("300px"), overflowY: "auto", "-webkit-overflow-scrolling": "touch", my: 1 }}>
          <DescriptionAccordions questionSet={props.questionSet} />
        </Box>
 
        <Box sx={{
          mt: 1, mb: 0,
          display: "flex", justifyContent: "space-between", alignItems: "flex-end", overlow: "hidden"
        }}>
          <IconButton aria-label="start question set" size="large"
            onClick={(event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget) }}
          >
            <MoreVertSharpIcon
              sx={{ color: theme.palette.tertiary.main, fontSize: 30 }}
            />
          </IconButton>
 
          <Fab color="secondary" aria-label="details" href="#"
            onClick={() => { setFlip(!flip) }}
          >
            <ChecklistRtlOutlinedIcon />
          </Fab>
 
          <IconButton aria-label="start question set" size="large"
            onClick={() => { setShareDialogOpen(true) }}
          >
            <ShareOutlinedIcon sx={{ color: theme.palette.tertiary.main, fontSize: 25 }} />
          </IconButton>
        </Box>
 
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMoreMenu}
          onClick={handleCloseMoreMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={() => {
              if (!checkPermission(PermissionsEnum.SHARE_ANSWERS, user?.permissions)) return
              setThirdPartyOpen(true)
            }}
            disabled={!checkPermission(PermissionsEnum.SHARE_ANSWERS, user?.permissions)}>
            <ListItemIcon>
              <BusinessIcon fontSize="small" />
            </ListItemIcon>
            Share
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (!checkPermission(PermissionsEnum.CUD_QS, user?.permissions)) return alert("no permissions")
              if (answersId?.length !== 0 || props.pair.pub !== props.questionSet.setterPub) return
              setConfirmUpdateOpen(true)
            }}
            disabled={answersId?.length !== 0 || props.pair.pub !== props.questionSet.setterPub || !checkPermission(PermissionsEnum.CUD_QS, user?.permissions)}
          >
            <ListItemIcon>
              <EditOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (answersId?.length !== 0 || props.pair.pub !== props.questionSet.setterPub) return
              props.copyQuestionSet(props.questionSet)
            }}
            disabled={answersId?.length !== 0 || props.pair.pub !== props.questionSet.setterPub || !checkPermission(PermissionsEnum.CUD_QS, user?.permissions)}
          >
            <ListItemIcon>
              <CopyOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Copy
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (answersId?.length || props.pair.pub !== props.questionSet.setterPub || !checkPermission(PermissionsEnum.CUD_QS, user?.permissions)) return
              setConfirmationProps({ open: true, action: "delete" })
            }}
            disabled={answersId?.length !== 0 || props.pair.pub !== props.questionSet.setterPub || !checkPermission(PermissionsEnum.CUD_QS, user?.permissions)}
          >
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (!checkPermission(PermissionsEnum.CUD_QS, user?.permissions)) return
              setConfirmationProps({ open: true, action: "archive" })
            }}
            disabled={!checkPermission(PermissionsEnum.CUD_QS, user?.permissions)}
          >
            <ListItemIcon>
              <ArchiveOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Archive
          </MenuItem>
          {/* answers?.length ? (<MenuItem>
            <a 
              href={"https://parrotboards.com/setter?keyReq=" + props.questionSet.id + "&pub=" + props.pair.pub + "&epub=" + props.pair.epub}
            >
              <ListItemIcon>
                <LockOutlinedIcon fontSize="small" />
              </ListItemIcon>
              Send results to Parrot Boards
            </a>
          </MenuItem>) : <div /> */}
        </Menu>
 
        <QuestionSetShare
          open={shareDialogOpen}
          id={props.questionSet.id}
          name={props.questionSet.name}
          handleClose={() => { setShareDialogOpen(false) }}
        />
 
        <DataTableDialog
	  {...dataProps}
          name={props.questionSet.name}
	  answers={answers}
	  fields={props.questionSet.fields}
          handleClose={() => { setDataProps({ open: false, action: "" }) }}
        />

        <ConfirmationDialog
          {...confirmationProps}
          name={props.questionSet.name}
          handleClose={() => { setConfirmationProps({ open: false, action: "" }) }}
          handleConfirm={handleConfirmDialog}
        />
        <ConfirmUpdateDialog
          open={confirmUpdateOpen}
          name={props.questionSet.name}
          handleClose={() => { setConfirmUpdateOpen(false) }}
          handleConfirm={handleUpdateQuestionSet}
        />
        <ThirdPartyShareDialog
          open={thirdPartyOpen}
          handleClose={() => { setThirdPartyOpen(false) }}
          addNew={handleNewThirdParty}
          thirdParties={thirdParties}
        />
 
        <GDPRDialog open={GDPROpen} terms={() => getTerms(props.setterId)} questionSet={{ ...props.questionSet, questionsList }} onClose={() => { setGDPROpen(false) }} />
 
      </CardContent>
      {!answerShowing ? <CardContent className={classes.cardContent}>
        <Box>
 
          {
            props.pair.pub !== props.questionSet.setterPub && (
              <Alert severity="info">This question set was made on a different device.</Alert>
            )
          }
 
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>        
            <Typography variant="h6" color="primary">{props.questionSet.id}</Typography>
            {answers?.length ? <Button onClick={downloadCSV} aria-label="download csv" style={{ width: "20px", overflow: "hidden", position: "absolute", right: "10px" }}>csv</Button> : <span />}
            {answers?.length ? <Button onClick={() => {setDataProps({open: true, action: ""})}} style={{ width: "30px", overflow: "hidden", position: "absolute", right: "60px" }}>Table</Button> : <span />}
            {/* answers?.length && checkPermission(PermissionsEnum.DOWNLOAD_ANSWERS, user?.permissions) ? <FileDownloadIcon sx={{ color: themeConfig.palette.tertiary.main, fontSize: 35, cursor: "pointer" }} onClick={downloadExcel} /> : "" */}
          </Box>
          <Typography variant="h6">{props.questionSet.name}</Typography>
          <Typography variant="h5" sx={{ mt: 2 }}>
            Responses {answers ? `(${answers.length})` : ""}
          </Typography>
          {/* <HelpCenterIcon fontSize="large" color="primary" /> */}
        </Box>
 
        {hiddenAnswers?.length !== 0 && (
          <Box sx={{ textAlign: "center", backgroundColor: "#efefefb8", mt: 1, display: "flex", flexDirection: "column" }}>
            {hiddenAnswers?.length} hidden response(s) this month.
            <Button variant="text" color="secondary"
              onClick={() => { navigate("/ask/plans") }}
            >
              Upgrade plan
            </Button>
          </Box>
        )}
 
        {!checkPermission(PermissionsEnum.READ_ANSWERS, user?.permissions) ? "You do not have permission to see the answers." : !answers ? "Loading ..." : answers.length ? <div className="cardList">
          {answers.map((answer: ExtendedAnswer) => {
            const answerId = answer.id?.slice(-8).toUpperCase()
 
            return (
              <Box key={answer.id || ""}
                onClick={() => { handleViewAnswer(answer) }}
              >
                <Box sx={{ display: "flex", my: 1 }}>
                  <Box sx={{ flexGrow: 1.5 }}>
                    <Typography variant="body1">{/* {answer.title} -  */}{answerId}</Typography>
                    {answer.timestamp && <Typography variant="body1">{formatDate(answer.timestamp!, DATE_FORMAT_NO_SECONDS)}</Typography>}
                    {answer.available && <Typography variant="body1">Available for {answer.minutesLeft || SHOW_TIMES[answer.time]?.label}</Typography>}
                  </Box>
                  <Box sx={{ flexGrow: 0.5, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    {answer.signed && <img src={SignatureIcon} alt="Signature" width="35" />}
                    {answer.available ? <VisibilityRoundedIcon fontSize="large" color="primary" sx={{ ml: 1 }} />
                      : answer.saved ? <SaveIcon fontSize="large" color="primary" sx={{ ml: 1 }} /> : <VisibilityOffRoundedIcon fontSize="large" color="primary" sx={{ ml: 1 }} />}
                  </Box>
                </Box>
                <Divider />
              </Box>
            )
          })} </div> : hiddenAnswers?.length === 0 ? <div className="cardList">No responses yet!</div> : ""
        }
 
        <Box sx={{
          mt: 1, mb: 0,
          display: "flex", justifyContent: "center", alignItems: "flex-end", overlow: "hidden"
        }}>
          <Fab color="secondary" aria-label="details" href="#"
            onClick={() => { setFlip(!flip) }}
          >
            <ReplySharpIcon />
          </Fab>
        </Box>
      </CardContent> : (
      <SeeAnswer
        answer={answerShowing}
        stopShowing={handleStopViewAnswer}
        signCard={() => { handleAnswerActions(answerShowing, "sign") }}
        revokeSignature={() => { handleAnswerActions(answerShowing, "revoke") }}
      />
    )}
  </FlipCard>
  )
}
