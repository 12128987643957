import React from 'react'
import { useNavigate } from "react-router-dom"

import {
  Box, Typography,
} from '@mui/material'

import Tutorial from "../../images/ResponderTutorial.gif"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import makeStyles from '@mui/styles/makeStyles';
import WrapperCard from '../../components/WrapperCard';
const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
}))

export default function ResponderTutorial() {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    // <WrapperCard>
    <Box className={classes.wrapper}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "95%", my: 2, ml: 1 }}
        onClick={() => { navigate("/") }}
      >
        <ArrowBackIcon sx={{ fontSize: 20, mr: 0.5, cursor: "pointer" }} />
      </Box>

      <img src={Tutorial} alt="Tutorial" width="100%" />
    </Box>
    // </WrapperCard>
  )
}
