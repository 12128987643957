import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";

import {
  Box, Card, CardContent, Button, Typography, TextField, InputAdornment, IconButton,
  Alert,
} from '@mui/material'
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import GoogleLogo from "../../images/google.svg"

import { signUpEmailPassword, sendDRYEmailVerification, doc, fs, getDoc, setDoc } from '../../services/firebase'
import { getSetterPair, setSetterPair, setUser } from '../../Helpers/localStorage'

import themeConfig from '../../theme';
import { maxHeight } from '../../Helpers/height';

import makeStyles from '@mui/styles/makeStyles';
import { setterAuth } from '../../services/api';
import { encryptKeys } from '../../Helpers/encryptionKeys';
import { SEA, hashValue } from '../../services/gun';
import { NewEncryptionCodesDialog } from '../../components/NewEncryptionCodesDialog';
import FlipCard from '../../components/FlipCard';
import {isIOS, isMobile } from "react-device-detect";
const FREE_PLAN = "free";

const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  mainCard: {
    margin: 10,
    marginTop: 15,
    display: "flex",
    boxShadow: "0px 2px 5px #888888",
    flexGrow: 1,
    height: maxHeight("100px"),
    "-webkit-overflow-scrolling": "touch",
    overflow: "auto"
  },
  cardContent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "space-between",
    width: "100%",
    "-webkit-overflow-scrolling": "touch",
  },
}))

const widthSx = { width: { xs: "90%", sm: 350, lg: 400 } }

type Invite = {
  email: string;
  setterId: string;
  encrypted: string;
  codeHash: string;
  permissions: string[];
  status: string;
  created: Date;
  expires: Date;
}

export default function SetterSignup() {
  const classes = useStyles();

  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null)

  const [inviteId] = useState((new URLSearchParams(window.location.search)).get("invite") || null)
  const [inviteData, setInviteDate] = useState<Invite | null>(null)

  const [email, setEmail] = useState({
    value: "",
    error: false,
    helperText: ""
  })
  const [password, setPassword] = useState({
    value: "",
    error: false,
    helperText: ""
  })
  const [inviteCode, setInviteCode] = useState({
    value: "",
    error: false,
    helperText: ""
  })

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [loading, setLoading] = useState(false)

  const [codesViewDialog, setCodesViewDialog] = useState({
    open: false,
    codes: [] as string[],
    firstTime: false,
  })

  useEffect(() => {

    async function getInvite() {
      if (!inviteId) return

      try {
        const inviteRef = doc(fs, "invites", inviteId)
        let invite: any = (await getDoc(inviteRef))
        if (!invite.exists()) return

        invite = { ...invite?.data(), id: inviteId } as Invite

        // TODO: check it didn't expire
        if (invite.status === "active") {
          // console.log("invite", invite)
          setInviteDate(invite)
          setEmail({
            ...email,
            value: invite.email,
          })
        }
      } catch (error: any) {
        console.error(error)
      }
    }

    getInvite()

    return () => {

    }
  }, [inviteId])


  const handleSignup = async () => {
    setError(null)

    let error = false
    if (email.value.trim() == "") {
      setEmail({
        ...email,
        error: true,
        helperText: "Email is required"
      })
      error = true
    }

    if (password.value.trim() == "") {
      setPassword({
        ...password,
        error: true,
        helperText: "Password is required"
      })
      error = true
    }

    if (inviteData) {
      if (inviteCode.value.trim() == "") {
        setInviteCode({
          ...inviteCode,
          error: true,
          helperText: "Invite code is required"
        })
        error = true
      } else {
        // Check if the code is correct
        const codeHash = await hashValue(inviteCode.value)
        const correctCode = inviteData?.codeHash === codeHash

        if (!correctCode) {
          setInviteCode({
            ...inviteCode,
            error: true,
            helperText: "Invite code is incorrect"
          })
        }
      }

    }

    if (error) return

    try {
      setLoading(true)
      const result = await signUpEmailPassword(email.value, password.value)
      // console.log("result", result)

      const emailing = await sendDRYEmailVerification()
      // console.log("emailing", emailing)

      // Save to firestore
      const token = await result?.user.getIdToken()
      // console.log("token", token)
      const authenticated = await setterAuth({ inviteId }, token)
      // console.log("authenticated", authenticated)

      // Save user data in local storage
      const { uid } = result?.user
      const user = {
        email: email.value,
        id: uid,
        method: "email",
        memberId: uid,
        setterId: authenticated.setterId,
        plan: FREE_PLAN,
        permissions: authenticated.permissions,
      }
      setUser(user)
      // console.log(user)

      setLoading(false)

      if (!inviteData) {
        // Generate keys pair
        const pair = await getSetterPair(user?.email)

        const { encryptedKeys, codes } = await encryptKeys(pair)

        // Update the setter 
        const docRef = doc(fs, "setters", user?.setterId)
        setDoc(docRef, { encryptedKeys, pubKey: pair.pub }, { merge: true })

        // Show the codes to the user
        // alert(codes.toString())
        /* setCodesViewDialog({
          open: true,
          firstTime: true,
          codes,
        }) */
        navigate("/ask")

      } else {
        // Decrypt the keys
        const decrypted = await SEA.decrypt(inviteData.encrypted, inviteCode.value)
        // console.log("decrypted", decrypted)

        // Set the keys
        setSetterPair(user?.email!, decrypted)

        // Redirect to home        
        navigate("/ask")
      }

    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("error", error)
      console.log("errorCode", errorCode)

      switch (errorCode) {
        case "auth/email-already-in-use":
          setError("The email address is already in use.")
          break
        case "auth/invalid-email":
          setError("The email address is invalid.")
          break
        case "auth/weak-password":
          setError("The password chosen is too weak. The password should be at least 6 characters.")
          break
        default:
          setError(errorCode || "Error" + ". " + errorMessage)
          break
      }
    }
  }

  const handleCloseViewCodesDialog = () => {
    // Redirect to profile
    navigate("/ask/profile", {
      state: {
        firstTime: true,
      }
    })
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width:"100%" }}>
      <FlipCard flip={false} item={{key: "newAcc"}} isIOS={isIOS}>
        <CardContent className={classes.cardContent}>
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>Signup</Typography>

          {error && <Alert severity="error" sx={{ textAlign: "left" }}>{error}</Alert>}
          <div className='lockup'>
            <img src="/parrot.svg" alt="Parrot logo" width="150" /><br />
            <h1 className="logo">Parrot</h1>
            <h6 className="logo">
              Answer or Ask Once <br /> End Forms Forever
            </h6>
	  </div>
          <Box component="form" noValidate autoComplete="on" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            {inviteData && <TextField
              id="invite-code" label="Invite code" variant="outlined" type="text" name="invite-code"
              sx={{ mt: 2 }}

              {...inviteCode}
              onChange={(event) => { setInviteCode({ value: event.target.value, error: false, helperText: "" }) }}
            />}

            <TextField
              id="email" label="Email" variant="outlined" type="email" name="email"
              autoComplete="on"
              sx={{ mt: 2 }}
              disabled={inviteData !== null}

              {...email}
              onChange={(event) => { setEmail({ value: event.target.value, error: false, helperText: "" }) }}
            />

            <TextField
              id="password" label="Password" variant="outlined" name="password"
              autoComplete="on"
              type={showPassword ? "text" : "password"}
              sx={{ mt: 2 }}

              {...password}
              onChange={(event) => {
                setError(null)
                setPassword({
                  value: event.target.value,
                  error: false,
                  helperText: ""
                })
              }}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickShowPassword}
                      size="small"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Typography variant="subtitle2" sx={{ mt: 2, textAlign: "left" }}>
              By selecting Agree and Signup below, I agree to <Link to="/ask/faq#terms-of-service" style={{ color: themeConfig.palette.secondary.main, textDecoration: "none" }}>Terms of Service</Link> and <Link to="/ask/faq#privacy-policy" style={{ color: themeConfig.palette.secondary.main, textDecoration: "none" }}>Privacy Policy</Link>
            </Typography>

            <Button
              variant="contained" color="secondary" sx={{ mt: 2 }}
              onClick={handleSignup}
              disabled={loading}
            >
              Agree and Signup
            </Button>

            {/* <Typography variant="caption" sx={{ mt: 2, mb: 1 }}>OR</Typography>

            <Button variant="contained" color="secondary" sx={{ mt: 1, backgroundColor: "#fde7c0", color: "black" }}
            onClick={handleGoogleAuth}
            >
              <img src={GoogleLogo} alt="google log" width="20" style={{ marginRight: 10 }} />
              SignUp with Google
            </Button> */}

          </Box>
          <NewEncryptionCodesDialog
            {...codesViewDialog}
            onClose={handleCloseViewCodesDialog}
          />

        </CardContent>
        <div>back</div>
      </FlipCard>
    </div>
  )
}
