type LogoPaddingStyle = "circle" | "square" | undefined
type qrStyle = "squares" | "dots" | undefined

const logoPaddingStyle = "circle" as LogoPaddingStyle
const qrStyle = "dots" as qrStyle

export const QrSettings = {
    "logoImage": "/parrot.svg",
    "bgColor": "#cec9c800",
    "fgColor": "#0b9101",
    "logoWidth": 100,
    "logoHeight": 100,
    "logoPadding": 1,
    "logoOpacity": 1,
    qrStyle,
    logoPaddingStyle,
    "removeQrCodeBehindLogo": true,
  }
