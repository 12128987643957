import React from 'react'
import { useNavigate } from "react-router-dom"

import {
  Box, Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import makeStyles from '@mui/styles/makeStyles';
import CookiePolicy from '../../components/CookiePolicy';
const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    textAlign: "left",
    paddingBottom: 20,
  },
}))

const faq = [
  {
    question: "How do I make an account? ",
    answer: "You don’t need to! Just open the app on your device and it will remember you on future visits."
  },
  {
    question: "How do I save my answers?",
    answer: "Your answers are automatically saved as you fill them into question sets. For now, that’s the only way to add answers to your personal Parrot. If you change an old answer when filling in a new question set, then Parrot will remember it to use in future answers, but it will not change historic ones."
  },
  {
    question: "How do I access a question set?",
    answer: "To access a question set, you can either scan a QR code shown to you by the people who created it, or enter a code they have given you on the Parrot home page. Once you have entered the code, on the tick button next to it. If it responds with ‘Question Set not found’, that means the code isn’t right."
  },
  {
    question: "What’s the difference between sharing and showing?",
    answer: "If you share your data with an organisation, they will be able to save it permanently. If you show your answers, then they will only be able to see it, for however long you’ve approved. Whether something is saved or shown is determined on a answer-by-answer basis, not by question set. You’ll be able to see whether an answer is going to be shown or saved when you fill in the question set."
  },
  {
    question: "How do I see who has my data?",
    answer: "You can see the previous question sets you have filled in as cards at the bottom of the home screen. When you swipe to a card, you can see who has access to it and all the past actions that have been on it."
  },
  {
    question: "How do I grant access?",
    answer: "You can share or show your answers to the organisation my swiping up from the button on a completed question set."
  },
  {
    question: "How do I revoke access?",
    answer: "In the current version of Parrot, we do not have the feature to revoke access to your data. This is coming soon. "
  },
  {
    question: "How do I fill in a question set?",
    answer: `
    To fill in a question set, first enter the question set code. Any answers you’ve already given will auto-fill, so you just have to scroll down the page, fill in any blanks – which will be saved for future occasions! When you’ve filled all of the questions in, the button at the bottom will change, and you will be able to swipe up, to show the question setter’s your answers. 
    
    You can press the flip button on the question set to see the other side of the card which will have the name of the group or organisation who made the question set, and their description of why they need that data. 
    `
  },
  {
    question: "How do I see my previous answers?",
    answer: "Your previous answers will appear as cards at the bottom of the home page. You can scroll across them by swiping left or right, until you get to the card you need."
  },
  {
    question: "How do I access other parts of Parrot?",
    answer: "Press the three dots in the top-right corner of the screen, and that will open the menu. From there, select the section you want."
  },
  {
    question: "How can I see the status of a card?",
    answer: "If you open one of your past answer cards, it will show its current status, which is whether it has been seen, signed, or access has been revoked."
  },
  {
    question: "How can I contact you?",
    answer: "If you press the three dots in the top-right corner of the screen, and then select ‘Help’, it will open an email to one of the members of the Hi9 team. As a note, we created Parrot, not the individual question sets, so it’s best to contact them directly if you have more questions. "
  },
  {
    question: "How can I contact the question setters?",
    answer: "Currently, you cannot contact the question setters through Parrot, but their contact details are on the back of the card if you wish to contact them directly. "
  },
]

export default function ResponderFAQ() {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <Box className={classes.wrapper}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2, width: "100%", }}>
        <ArrowBackIcon sx={{ fontSize: 20, mr: 0.5, ml: 1.5, cursor: "pointer" }} onClick={() => { navigate("/") }} />
        <Typography variant="h5">FAQ</Typography>
        <Box sx={{ minWidth: 10 }}></Box>
      </Box>

      {
        faq.map((qa, index) => {
          return (
            <Accordion key={index} sx={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel-content-" + index}
                id={"panel-content-" + index}
                sx={{ backgroundColor: "#efebebcc" }}
              >
                <Typography>{qa.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {qa.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })
      }

      <Accordion key="privacy-policy" id="privacy-policy">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content-pp"
          id="panel-content-pp"
          sx={{ backgroundColor: "#efebebcc" }}
        >
          <Typography>What’s your privacy policy?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <strong>Hi9 Ltd Privacy Policy</strong>
          <br /><br />
          This Privacy Policy informs you of how Hi9 Ltd collects, uses, and protects your personal data when you use our services.
          <br /><br />

          <strong>Who we are:</strong> Hi9 Ltd is the data controller for the purposes of data protection laws. Our Data Protection Officer, Wo King, can be reached at <a href="mailto:wo@hi9.io">wo@hi9.io</a> for any queries or concerns.
          <br /><br />

          <strong>Information we collect:</strong> We collect data necessary to deliver our services. This may include account information needed for the creation and operation of your account. Form responses and form creation data are not typically stored on our servers, but on the users' devices.
          <br /><br />

          <strong>Purpose of Processing:</strong> The purpose of processing this data is to provide, improve, understand, customize, support, and market our services. We are committed to ensuring that our data collection and processing is always lawful, fair, and transparent.
          <br /><br />

          <strong>Data Security:</strong> We prioritize the security of your data. All data we process is fully end-to-end encrypted and the encryption keys are saved on the users' devices. This means that your data cannot be decrypted by us, ensuring that your information remains private and secure. However, if someone were to access Parrot on your device, they would be able to see the data in the browser page.
          <br /><br />

          <strong>Data Retention:</strong> We retain your data for as long as necessary to provide our services. Form responses and form creation data are stored on users' devices and not typically on our servers.
          <br /><br />

          <strong>Data Sharing:</strong> We do not share your data with third parties unless it is necessary for the delivery of our services or if we are required to do so by law. Even when required by law, we are unable to provide unencrypted data as we do not have the keys to decrypt the data. Only the users setting the forms can see and share the data.
          <br /><br />

          <strong>Your Rights:</strong> You have several rights under data protection law, including the right to access, update, or delete your data, and the right to object to certain types of processing. If you wish to exercise any of these rights, please contact our Data Protection Officer at wo@hi9.io.
          <br /><br />

          <strong>Children's Privacy:</strong> If our services are used by children under the age of 16, parental consent will be sought in accordance with data protection laws.
          <br /><br />

          <strong>Contact us:</strong> If you have any questions about this Privacy Policy or our data practices, please contact our Data Protection Officer, Wo King, at wo@hi9.io.
          <br /><br />

          Please note that this Privacy Policy may be updated from time to time to reflect changes in our data practices or applicable laws.
        </AccordionDetails>
      </Accordion>

      <Accordion key="cookie-policy" id="cookie-policy">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="cookie-ploicy-content"
          id="cookie-ploicy-content"
          sx={{ backgroundColor: "#efebebcc" }}
        >
          <Typography>What’s your cookie policy?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CookiePolicy />
        </AccordionDetails>
      </Accordion>

      {/* <Accordion key="privacy-policy">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content-pp"
          id="panel-content-pp"
          sx={{backgroundColor:"#efebebcc"}}
        >
          <Typography>What’s your privacy policy?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This privacy policy explains how Hi9 ("us", "we", or "our") collects, uses, discloses, and safeguards any information that you ("user", "you", or "your") provide to us when using the Parrot web app (the "App").We understand the importance of your privacy and we are committed to protecting it. This privacy policy applies to all information collected through the App, and to any other interactions that you may have with us.
            <br/><br/>

            <strong>Information Collected</strong>
            <br/>
            We do not collect any personal identifiable information from you when you use the App. We use a third-party encryption service to encrypt all data and information that is stored on the App. This means that we, or anyone else apart from the intended recipient, cannot decipher the data and information stored on the App. We may collect anonymous data such as when you log in to the App, when you submit questions, and when you answer questions. 
            
            <br/><br/>
            <strong>Use of Information</strong>
            <br/>
            The information collected through the App is used to provide the services offered by the App. We may also use the information collected to improve the App or our services. 
            
            <br/><br/>
            <strong>Disclosure of Information</strong>
            <br/>
            We do not disclose any information collected through the App to any third parties.
            
            <br/><br/>
            <strong>Security</strong>
            <br/>
            We take reasonable measures to protect the information collected through the App from unauthorized access, destruction, use, modification, or disclosure. 

            <br/><br/>
            <strong>Changes to This Privacy Policy</strong>
            <br/>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
            
            <br/><br/>
            <strong>Contact Us</strong>
            <br/>
            If you have any questions or concerns about this Privacy Policy, please contact us at wo@hi9.io.

          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </Box>
  )
}
