import { Box } from '@mui/material';
import React from 'react';

const CookiePolicy = () => {
  return (
    <Box>
      <strong>Parrot Cookie Policy</strong>
      <br /><br />
      This Cookie Policy describes how Hi9 Ltd ("we", "our", "us") uses cookies and similar technologies to provide and improve our Parrot service ("Service"). This policy is part of our privacy commitment, ensuring transparency about the data we collect, use, and share.
      <br /><br />

      <strong>1. What Are Cookies?</strong>
      <br />
      Cookies are small text files that are placed on your computer or mobile device by websites that you visit. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.
      <br /><br />

      <strong>2. How We Use Cookies</strong>
      <br />
      We use cookies and similar technologies, including local storage, for several reasons:
      <br />
      a. <strong>Google Analytics:</strong> We use Google Analytics to help us understand how users interact with our Service. These cookies track information such as how long you spend on the site and the pages that you visit so we can optimize and improve the user experience. The data collected is anonymized and statistical in nature.
      <br />
      b. <strong>Firebase Authentication:</strong> We use Firebase Authentication to provide user authentication. These cookies store certain user information to keep you logged in to our Service.
      <br />
      c. <strong>Local Storage:</strong> We use local storage to save users’ encryption keys. These keys are necessary for data decryption, and we highly recommend users not to manually delete them from local storage.
      <br />
      <br /><br />

      <strong>3. Cookies Used by Our Service Providers</strong>
      <br />
      Our service providers use cookies and those cookies may be stored on your computer when you visit our website.
      <br /><br />

      <strong>4. Information We Save</strong>
      <br />
      a. <strong>Responder Side:</strong> On the responder side, we save the trail of answers (all previous answers), which is encrypted for your safety and privacy.
      <br />
      b. <strong>Setter Side:</strong> On the setter side, we save information about the logged-in user. This information allows us to personalize your experience and improve the Service.
      <br />
      <br /><br />

      <strong>5. Managing Cookies</strong>
      <br />
      Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. Please note that blocking all cookies will have a negative impact upon the usability of many websites, including ours.
      <br /><br />

      <strong>6. Changes to this Cookie Policy</strong>
      <br />
      We may update this Cookie Policy from time to time in order to reflect, for example, changes to our cookies or for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
      <br /><br />

      <strong>7. Contact Us</strong>
      <br />
      If you have any questions about our use of cookies or other technologies, please email us at wo@hi9.io.
      <br /><br />

      Last updated: 10/06/2023
    </Box>
  );
};

export default CookiePolicy;
