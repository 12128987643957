import React from 'react'

import makeStyles from '@mui/styles/makeStyles';
import { Card } from '@mui/material';
import { maxHeight } from '../Helpers/height';

const useStyles = makeStyles(_ => ({
  mainCard: {
    margin: "10px auto",
    // maxHeight: "80vh",
    display: "flex",
    boxShadow: "0px 2px 5px #888888",
    backgroundColor: "#6200ee0a",
    borderRadius: 20,
  },
  mainCardNoShadow: {
    margin: "10px auto",
    display: "flex",
    boxShadow: "none"
  },
}))

export default function WrapperCard(props: any) {
  const classes = useStyles();

  return (
    <Card className={(props.noShadow ? classes.mainCardNoShadow : classes.mainCard) + " cardWidth"}
      sx={{
        height: maxHeight("100px"),
        overflow: props.scroll ? "scroll" : "hidden",
        "-webkit-overflow-scrolling": props.scroll ? "touch" : "auto",
      }}
    >
      {props.children}
    </Card>
  )
}
