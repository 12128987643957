import { createTheme, PaletteColorOptions } from "@mui/material/styles";
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

const themeConfig = {
  // typography: {
  //   useNextVariants: true,
  // },
  palette: {
    primary: { // Red
      ...createColor('#e83b4d'),
      contrastText: '#fff',
    },
    secondary: { // Orange
      ...createColor('#f8904c'),// 21c61e - 22f720 wireframe
      contrastText: '#fff',
    },
    tertiary: { // Green
      ...createColor('#10980e'),// 21c61e - 22f720 wireframe
      contrastText: '#fff',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
};

declare module '@mui/material/styles' {
  interface CustomPalette {
    tertiary: PaletteColorOptions;
  }
  interface Palette extends CustomPalette { }
  interface PaletteOptions extends CustomPalette { }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

export default themeConfig;
