import React, { useEffect, useState} from "react"

import {
  Box, CardContent, Typography, TextField, Fab, IconButton, InputAdornment, FormControl, InputLabel,
  Dialog, OutlinedInput, Button, DialogTitle, DialogActions, DialogContent, Select, MenuItem, Chip, Alert, Checkbox, FormControlLabel,
} from "@mui/material"


import FlipIcon from "@mui/icons-material/Flip";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import WizardIcon from '@mui/icons-material/EmojiObjects';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { getQuestions, Question } from "../../services/questions";
import themeConfig from "../../theme";
import { formatDate, parseDate } from "../../Helpers/dates";

import makeStyles from "@mui/styles/makeStyles";
import { isIOS } from "react-device-detect";
import FlipCard from '../../components/FlipCard';
// import { addDoc, collection } from "firebase/firestore";
// import { fs } from "../../services/firebase";

const useStyles = makeStyles(_ => ({
  cardContent: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  questionButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    borderRadius: 2,
  }
}))

const TertiaryColor = themeConfig.palette.tertiary

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function ConfirmationDialog(props: any) {

  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Typography variant="h6">Confirm Question Set build! You cannot change the question set once it is built.</Typography>
        {/* <Typography variant="h5" sx={{ my: 2 }}>code</Typography> */}
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 3 }}>
          <IconButton aria-label="close question set" size="large" color="error"
            onClick={props.handleClose}
          >
            <CancelIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <IconButton aria-label="start question set" size="large" color="error"
            onClick={props.handleConfirm}
          >
            <CheckCircleRoundedIcon sx={{ fontSize: 60, "&.MuiSvgIcon-root": { color: TertiaryColor.main } }} />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  )
}

function EditQuestionDialog(props: any) {
  const [optionText, setOptionText] = useState("")
  const [done, setDone] = useState(true)
  
  const setRows = (value: any) => {
    const updated = {
      ...props.editQuestion,
      rows: value? 5:1,
    }
    props.setEditQuestion(updated)
    setDone(updated.label?.trim() !== "" && updated.type?.trim() !== "")
  }

  const onChange = (key: string, value: any) => {
    console.log("changing key value", key, value, props.editQuestion)
    const updated = {
      ...props.editQuestion,
      [key]: value,
    }
    props.setEditQuestion(updated)
    setDone(updated.label?.trim() !== "" && updated.type?.trim() !== "")
  }

  const handleClose = () => {
    props.handleClose()
    clearForm()
  }

  function clearForm() {
    props.setEditQuestion({
      label: "",
      type: "",
      action: "save",
      options: [],
      rows: 1,
    })
    setDone(false)
  }
  if (!props.editQuestion.action) {
    props.setEditQuestion({
      label: "",
      type: "",
      action: "save",
      options: [],
      rows: 1,
      ...props.editQuestion,
    })
  }
  console.log("props.editQuestion", props.editQuestion)
  return (
    <Dialog onClose={handleClose} open={props.open}>
      <DialogContent>
        <DialogTitle sx={{ textAlign: "center" }}>
           Edit Question
        </DialogTitle>

        <Box>
          <TextField fullWidth label="Name" variant="outlined"
            value={props.editQuestion.label}
            inputProps={{ maxLength: 40 }}
            onChange={(event) => { onChange("label", event.target.value) }}
          />
          <InputLabel id="required-select-label">Required</InputLabel>
          <Checkbox
            checked={props.editQuestion.required}
            onChange={(event) => {
              console.log("required", event.target.checked)
              onChange("required", event.target.checked)
            }}
            inputProps={{ 'aria-label': 'required' }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="action-select-label">Retention</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={props.editQuestion.action}
              label="Type"
              onChange={(event) => { onChange("action", event.target.value) }}
            >
              <MenuItem value="see" >See (You can ONLY See the response)</MenuItem>
              <MenuItem value="save">Save (You can Save the response)</MenuItem>

            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={props.editQuestion.type}
              label="Type"
              onChange={(event) => { onChange("type", event.target.value) }}
            >
              <MenuItem value="text">Free text</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="select">Select: user select one from list</MenuItem>

            </Select>
          </FormControl>
          {props.editQuestion.type === "text" && (
            <Box sx={{ mt: 2 }}>
              <FormControlLabel control={<Checkbox onChange={(event) => { setRows(event.target.checked) }} />} label="Multiline" />
            </Box>
          )}
          {props.editQuestion.type === "select" && (
            <Box sx={{ mt: 2 }}>
              <TextField fullWidth label="Options" variant="outlined"
                sx={{ mb: 1 }}
                placeholder="Enter to add the option"
                value={optionText}
                onChange={(event) => { setOptionText(event.target.value) }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    onChange("options", [
                      ...(props.editQuestion?.options||[]),
                      ...optionText.split(",").map((o: string) => o.trim())
                    ])
                    setOptionText("")
                  }
                }}
              />
              {props.editQuestion.options && Object.values(props.editQuestion.options).map((option: any) => {
                return (
                  <Chip key={option} label={option} variant="outlined"
                    sx={{ mr: 1, mt: 1 }}
                    onDelete={() => {
                      onChange("options", [
                        ...Object.values(props.editQuestion.options),
                        ...optionText.split(",").map((o: string) => o.trim()),
                      ].filter((o: any) => o.trim() !== option))
                      setOptionText("")
                    }}
                  />
                )
              })}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => {
            props.remove(props.editQuestion)
          }}
          color="primary"
        >
          Remove Question
        </Button>

        <IconButton aria-label="Cancel" size="large" color="error"
          title="Cancel"
          onClick={handleClose}
        >
          <CancelIcon sx={{ fontSize: 40 }} />
        </IconButton>
        <IconButton aria-label="Confirm question set" size="large" color="error"
          title="Confirm"
          onClick={() => {
            if (optionText.trim() !== "") {
              onChange("options", [
                ...Object.values(props.editQuestion.options),
                ...optionText.split(",").map((o: string) => o.trim())
              ])
              setOptionText("")
            }
            if (props.editQuestion.type === "select") {
              props.handleConfirm({
                ...props.editQuestion,
                options: [
                  ...Object.values(props.editQuestion.options),
                  ...optionText.split(",").map((o: string) => o.trim())
                ].filter((o: any) => o.trim() !== ""),
              })
            } else {
              props.handleConfirm(props.editQuestion)
            }
            clearForm()
          }}
        >
          <CheckCircleRoundedIcon sx={{ fontSize: 60, "&.MuiSvgIcon-root": { color: TertiaryColor.main } }} />
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}

function RequestQuestionDialog(props: any) {
  const [optionText, setOptionText] = useState("")
  const [done, setDone] = useState(true)
  
  const setRows = (value: any) => {
    const updated = {
      ...props.requestForm,
      rows: value? 5:1,
    }
    props.setRequestForm(updated)
    setDone(updated.label?.trim() !== "" && updated.type?.trim() !== "")
  }

  const onChange = (key: string, value: any) => {
    const updated = {
      ...props.requestForm,
      [key]: value,
    }
    props.setRequestForm(updated)
    setDone(updated.label?.trim() !== "" && updated.type?.trim() !== "")
  }

  const handleClose = () => {
    clearForm()
    props.handleClose()
  }

  function clearForm() {
    props.setRequestForm({
      label: "",
      type: "",
      action: "save",
      options: [],
      rows: 1,
    })
    setDone(false)
  }
  if (!props.requestForm.action) {
    props.setRequestForm({
      label: "",
      type: "",
      action: "save",
      options: [],
      rows: 1,
      ...props.requestForm,
    })
  }
  return (
    <Dialog onClose={handleClose} open={props.open}>
      <DialogContent>
        <DialogTitle sx={{ textAlign: "center" }}>
          Make a new question
        </DialogTitle>

        <Box>
          <TextField fullWidth label="Name" variant="outlined"
            value={props.requestForm.label}
            inputProps={{ maxLength: 40 }}
            onChange={(event) => { onChange("label", event.target.value) }}
          />
      <InputLabel id="required-select-label">Required</InputLabel>
          <Checkbox
            checked={props.requestForm.required}
            onChange={(event) => { onChange("required", event.target.value) }}
            inputProps={{ 'aria-label': 'required' }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="action-select-label">Retention</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={props.requestForm.action}
              label="Type"
              onChange={(event) => { onChange("action", event.target.value) }}
            >
              <MenuItem value="see" >See (You can ONLY See the response)</MenuItem>
              <MenuItem value="save">Save (You can Save the response)</MenuItem>

            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={props.requestForm.type}
              label="Type"
              onChange={(event) => { onChange("type", event.target.value) }}
            >
              <MenuItem value="text">Free text</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="select">Select: user select one from list</MenuItem>

            </Select>
          </FormControl>
          {props.requestForm.type === "text" && (
            <Box sx={{ mt: 2 }}>
              <FormControlLabel control={<Checkbox onChange={(event) => { setRows(event.target.checked) }} />} label="Multiline" />
            </Box>
          )}
          {props.requestForm.type === "select" && (
            <Box sx={{ mt: 2 }}>
              <TextField fullWidth label="Options" variant="outlined"
                sx={{ mb: 1 }}
                placeholder="Enter to add the option"
                value={optionText}
                onChange={(event) => { setOptionText(event.target.value) }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    onChange("options", [
                      ...(props.requestForm?.options||[]),
                      ...optionText.split(",").map((o: string) => o.trim())
                    ])
                    setOptionText("")
                  }
                }}
              />
              {props.requestForm.options && Object.values(props.requestForm.options).map((option: any) => {
                return (
                  <Chip key={option} label={option} variant="outlined"
                    sx={{ mr: 1, mt: 1 }}
                    onDelete={() => {
                      onChange("options", [
                        ...Object.values(props.requestForm.options),
                        ...optionText.split(",").map((o: string) => o.trim()),
                      ].filter((o: any) => o.trim() !== option))
                      setOptionText("")
                    }}
                  />
                )
              })}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <IconButton aria-label="start question set" size="large" color="error"
          title="Cancel"
          onClick={handleClose}
        >
          <CancelIcon sx={{ fontSize: 40 }} />
        </IconButton>
        <IconButton aria-label="start question set" size="large" color="error"
          title="Confirm"
          onClick={() => {
            if (optionText.trim() !== "") {
              onChange("options", [
                ...Object.values(props.requestForm.options),
                ...optionText.split(",").map((o: string) => o.trim())
              ])
              setOptionText("")
            }
            props.handleConfirm({
              ...props.requestForm,
              options: [
                ...Object.values(props.requestForm.options),
                ...optionText.split(",").map((o: string) => o.trim())
              ].filter((o: any) => o.trim() !== ""),
            })
            clearForm()
          }}
        >
          <CheckCircleRoundedIcon sx={{ fontSize: 60, "&.MuiSvgIcon-root": {color: TertiaryColor.main }}} />
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}
export default function NewQuestionSet(props: any) {
  const classes = useStyles();

  const [flip, setFlip] = useState(false)
  const [questionSet, setQuestionSet] = useState({
    name: props.questionSet?.name || "",
    expiryDate: props.questionSet?.expiryDate ? parseDate(props.questionSet?.expiryDate) : null,
    description: props.questionSet?.description || "",
  })


  const [search, setSearch] = useState("")

  const [dialogOpen, setDialogOpen] = useState(false)
  const [requestQuestionOpen, setRequestQuestionOpen] = useState(false)
  const [editQuestionOpen, setEditQuestionOpen] = useState(false)

  const [infoMessage, setInfoMessage] = useState<string | null>(null)

  const [questionsList, setQuestionList] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [chosenQuestions, setChosenQuestions] = useState<any[]>(props.questionSet?.fields || [])

  const [otherQuestionsFiltered, setOtherQuestionsFiltered] = useState<Question[]>([])
  const [timer, setTimer] = useState<any>(0)
  const [ideas, setIdeas] = useState<any>(0)
  const [requestForm, setRequestForm] = useState({
    label: "",
    type: "",
    options: []
  })

  const [editQuestion, setEditQuestion] = useState({
    label: "",
    type: "",
    options: [],
  })

  function askForQuestions(title: string, description: string) {
    if (chosenQuestions.length || isLoading) return
    setIsLoading(true)
    window.fetch(`https://europe-west2-your-vault.cloudfunctions.net/build_questionnaire_v1?name=${encodeURIComponent(title)}&description=${encodeURIComponent(description)}` ).then((response) => {
      return response.json();
    }).then((data) => {
      console.log("data", data)

      const questions = JSON.parse(data.result).questions || []
      console.log("questions", questions)
      const newQuestions = [
        ...questions.map((question: any) => {
          console.log("question", question)
          return {
            ...question,
            name: question.label.replace(/ /g, "_").toLowerCase(),
            doNotSave: false,
            required: false,
            rows: 1,
            multiline: false,
            tags: [],
            decrypted: "",
            action: "save",
          }
        })
      ]
      return newQuestions
    }).then(newQuestions => {
      setChosenQuestions(newQuestions)

      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      console.log(err);
    });
  }
  function askForDescription(title: string) {
    // if (questionSet.description) return
    setQuestionSet({
      ...questionSet,
      description: "generating description..."
    })
    setIsLoading(true)
    window.fetch( "https://europe-west2-your-vault.cloudfunctions.net/name_generate_description_v1?input_name="+encodeURIComponent(title)).then((response) => {
      return response.json();
    }).then((data) => {
      console.log("data", data)
      setQuestionSet({
        ...questionSet,
        description: data.result.description,
        name: data.result.title,
      })
      setIsLoading(false)
      console.log("now for the questions")
      askForQuestions(data.result.title, data.result.description)
    }).catch((err) => {
      console.log(err);
      setIsLoading(false)
    });
  }

  //#region handlers
  const handleAddQuestion = (question: any, action: string) => {
    const newChosenQuestions = [...chosenQuestions, { required: false, ...question, action }]
    setChosenQuestions(newChosenQuestions)

    setOtherQuestionsFiltered( search.trim() === "" ?
      questionsList.filter(question => !newChosenQuestions.map(question => question.label.toLowerCase()).includes(question.label.toLowerCase()))
      : questionsList.filter(question => !newChosenQuestions.map(question => question.label.toLowerCase()).includes(question.label.toLowerCase()))
        .filter(question => question.label.toLowerCase().includes(search.toLowerCase())))
  }
  const handleAddIdea = (question: any) => {
    console.log(question)
    //ask for confirmation and type
    setRequestQuestionOpen(true)
    setRequestForm({
      options:[],
      label: question.label || "",
      type: question.type || "",
      ...question,
    })
  }
  const handleQuestionChange = (question: any, action: any) => {
    console.log(question, action)
    const newChosenQuestions = chosenQuestions.map(q => q.name === question.name ? { ...question, ...action } : q)
    console.log(newChosenQuestions.map(q => q.required))
    setChosenQuestions(newChosenQuestions)
  }

  const handleRemoveQuestion = (question: any) => {
    const newChosenQuestions = chosenQuestions.filter((q: any) => q.name !== question.name)
    setChosenQuestions(newChosenQuestions)

    setOtherQuestionsFiltered((search.trim() === "" ?
      questionsList.filter(question => !newChosenQuestions.map(question => question.name).includes(question.name))
      : questionsList.filter(question => !newChosenQuestions.map(question => question.name).includes(question.name))
        .filter(question => question.label.toLowerCase().includes(search.toLowerCase()))))
  }

  const handleSearchChange = (value: string) => {
    // console.log("searchValue", value)
    setSearch(value)

    const otherList = value.trim() === "" ? questionsList.filter(question => !chosenQuestions.map(question => question.name).includes(question.name))
      : questionsList.filter(question => !chosenQuestions.map(question => question.name).includes(question.name))
        .filter(question => question.label.toLowerCase().includes(value.toLowerCase()))

    setOtherQuestionsFiltered(otherList)

    setInfoMessage(null)
    if ( value.trim() ) {
      const filter = otherList.map(question => question.label.toLowerCase())
      const filterStr = window.encodeURIComponent(JSON.stringify(filter))
      clearTimeout(timer)
      setTimer(setTimeout(() => {
        const url = `https://europe-west2-your-vault.cloudfunctions.net/generate_label_v1?query=${value}&filter=${filterStr}`
        fetch(url).then(res => res.json()).then(res => {
          if (res.result && res.result.label) {
            const got = JSON.parse(res.result.label)
            console.log("got", got)
            setIdeas(got.map((idea: any) => ({...idea, options: JSON.parse(idea.options || "[]")})))
          } else {
            console.log("No results found")
          }
        }).catch(err => console.log(err))
      }, 500))
    }
  }

  useEffect(() => {
    if (questionsList.length === 0 && !isLoading) {
      console.log("fetching questions")
      setIsLoading(true)
      getQuestions().then((questions) => {
        console.log("questions", questions.length)
        setQuestionList(questions)
        setOtherQuestionsFiltered(questions)
        setIsLoading(false)
      })
    }
  }, [])

  // generating name and description
  // form api url https://europe-west2-your-vault.cloudfunctions.net/nature_lang_generate_title_description_v1?input_string=A%20Few%20Questions%20To%20Collect%20Organisation%20Details%20For%20A%20Conference%20Im%20Attending
  function getNameAndDescription(prompt: string) {
    if (prompt.trim() === "") return
    setQuestionSet({
      ...questionSet,
      name: "generating name...",
      description: "generating description..."
    })
    setIsLoading(true)
    window.fetch( "https://europe-west2-your-vault.cloudfunctions.net/nature_lang_generate_title_description_v1?input_string="+encodeURIComponent(prompt)).then((response) => {
      return response.json();
    }).then((data) => {
      console.log("data", data)
      setQuestionSet({
        ...questionSet,
    name: data.result.title,
    description: data.result.description
      })
      setIsLoading(false)
      askForQuestions(data.result.title, data.result.description)
    }).catch((err) => {
      console.log(err);
      setIsLoading(false)
    });
  }

  const handleCreateQuestionSet = () => {
    console.log("createQuestionSet", questionSet, chosenQuestions)
    props.createQuestionSet({
      ...questionSet,
      expiryDate: questionSet.expiryDate ? formatDate(questionSet.expiryDate!) : null,
      created: formatDate(new Date()),
      fields: chosenQuestions,
    }, props.questionSet?.id || null)
    setDialogOpen(false)
  }

  const handleRequestNewQuestion = (data: any) => {
    props.handleRequestNewQuestion({
      ...data,
      requestedOn: new Date(),
    })
    setRequestQuestionOpen(false)

    const addMe = {
      doNotSave: false,
      name: data.label.toLowerCase().replace(/ /g, "_"),
      multiline: data.rows > 1,
      required: false,
      ...data,
    }
    handleAddQuestion(addMe, "save")
    openEditQuestion(addMe)


    // Show info message
    //setInfoMessage("Request sent!")
  }
  const [thePrompt, setPrompt] = useState("")

  //#endregion handlers

  const openEditQuestion = (question: any) => {
    setEditQuestion(question)
    setEditQuestionOpen(true)
    setSearch("")
  }

  return (
    <FlipCard flip={flip} item={{key: "newQuestionSet"}} isIOS={isIOS}>
      <CardContent className={classes.cardContent} sx={{ height: "100%", minHeight:"56vh", overflow: "auto"}}>
        <Box className={classes.title} sx={{ overflow: "auto" }}>
          <Typography variant="h6" sx={{ mt: 0 }}>
            Question Set
          </Typography>
        </Box>
        <Box sx={{ overflowY: "auto", "-webkit-overflow-scrolling": "touch", flex: 1 }}>
          <TextField
            id="prompt" variant="outlined" label="Tell me about questions you need"
            type="text"
            fullWidth sx={{ mt: 2, backgroundColor: "white", }}

            multiline
            minRows={1}
            maxRows={5}

            value={thePrompt}
            onChange={(event) => setPrompt(event.target.value)}
          />
          <Button variant="contained" disabled={!thePrompt} color="primary" sx={{ mt: 2 }} onClick={() => getNameAndDescription(thePrompt)}>Generate</Button>
          <OutlinedInput
            id="qs_name"  name="qs_name"
            placeholder="Name for your question set"
            type="text"
            fullWidth
            sx={{
              mt: 2,
              backgroundColor: "white",
            }}
            endAdornment={
              <InputAdornment position="end">
                {questionSet.name !== "" && !isLoading ? <Tooltip title="Generate a Description">
                  <WizardIcon onClick={() => { askForDescription(questionSet.name) }} />
                </Tooltip> : <></>}
              </InputAdornment>
            }

            value={questionSet.name}
            onChange={(event) => setQuestionSet({ ...questionSet, name: toTitleCase(event.target.value) })}
          />

          <TextField
            id="qs_description" variant="outlined" label="Description *" name="qs_description"
            type="text"
            fullWidth sx={{ mt: 2, backgroundColor: "white", }}

            multiline
            minRows={5}
            maxRows={10}

            value={questionSet.description}
            onChange={(event) => setQuestionSet({ ...questionSet, description: event.target.value })}
          />
        </Box>

        <Box sx={{
          mt: 1, mb: 0,
          display: "flex", justifyContent: "space-between", alignItems: "flex-end", overlow: "hidden"
        }}>
          <IconButton aria-label="start question set" size="large" color="error"
            onClick={() => { props.cancelNewQuestionSet(props.questionSet?.id || null) }}
          >
            <CancelIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Fab color="secondary" aria-label="flip card"
            onClick={() => { setFlip(!flip) }}
            disabled={questionSet.name.trim() === "" || questionSet.description.trim() === ""}
          >
            <FlipIcon />
          </Fab>
          
          {questionSet.name !== "" ? <Tooltip title="Generate a Description">
            <Fab color="primary" aria-label="flip card" disabled={isLoading} onClick={() => { askForDescription(questionSet.name) }} >
              {!isLoading ? <WizardIcon /> : <CircularProgress />}
            </Fab>
          </Tooltip> : <Box sx={{ minWidth: 30 }}></Box>}
        </Box>
      </CardContent>
      <CardContent className={classes.cardContent} sx={{ overflowY: "auto", "-webkit-overflow-scrolling": "touch",  minHeight: "56vh" }}>
        <Box sx={{minHeight: 30}}>
          <FormControl variant="outlined" sx={{ mt: 2 }} fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Question search</InputLabel>
            <OutlinedInput
              id="search" label="Question search" name="search" placeholder="Question search"
              type="text" fullWidth
              sx={{backgroundColor: "white"}}

              endAdornment={
                <InputAdornment position="end">
                  {search !== "" ? <ClearIcon onClick={() => { handleSearchChange("") }} />
                    : <SearchIcon />}
                </InputAdornment>
              }

              value={search}
              onChange={(event) => {
                handleSearchChange(event.target.value)
              }}
            />
          </FormControl>
        </Box>
        <div className="cardList">
          <Box className={classes.title}>
            <Typography variant="h6" sx={{ mt: 0 }}>
              Question Set
            </Typography>
            {/* <HelpCenterIcon fontSize="large" color="primary" /> */}
          </Box>

          <Box classes="setterListBox">
            {infoMessage && <Alert sx={{ mt: 2 }} severity="success">{infoMessage}</Alert>}
            {!chosenQuestions?.length && !otherQuestionsFiltered?.length ? (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 0 }}>
                <Typography>Question not listed</Typography>
                <Button variant="text" color="primary" sx={{ mt: 1 }}
                  onClick={() => { setRequestQuestionOpen(true) }}
                >
                  Request new question
                </Button>
              </Box>
            ) : ""}
         
            <Box>
              <Box sx={{ mx: 3 }}>
                {search === ""  && chosenQuestions.map((question: Question) => {
                  return (
                    <Box key={question.name} sx={{cursor: "pointer", display: "flex", justifyContent: "flex-end", alignItems: "center", my: 1 }} onClick={() => openEditQuestion(question)}>
                      <Typography sx={{ mt: 0, ml: 0, minWidth: 200, width: 200, maxWidth: 200 }}>
                        {question.label}
                      </Typography>
                      <Box
                        sx={{ ml: 1, p: "4px" }}
                      >
                        <EditIcon fontSize="small" />
                      </Box>
         
                    </Box>
                  )
                })}
              </Box>
              <Box sx={{ mx: 3 }}>
                {otherQuestionsFiltered?.map(question => {
                  return (
                    <Box key={question.name} sx={{ display: "flex", alignItems: "center", my: 1 }}>
                      <Box className={classes.questionButton}
                        sx={{ backgroundColor: TertiaryColor.main, p: "4px", mr: 1, cursor: "pointer" }}
                        onClick={() => {
                          handleAddQuestion(question, "see")
                          openEditQuestion(question)
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </Box>

                      <Typography>
                        {question.label}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>
          <Box>
            {ideas ? ideas.map((idea: any) => {
              return (<Box key={idea.label} sx={{ display: "flex", alignItems: "center", my: 1}}>
                <Box className={classes.questionButton}
                  sx={{ backgroundColor: TertiaryColor.main, p: "4px", mr: 1, cursor: "pointer" }}
                  onClick={() => handleAddIdea(idea)}
                >
                  <AddIcon fontSize="small" />
                </Box> 
                <Typography sx={{ mt: 0 }}>
                  {idea.label}
                </Typography>
              </Box>)
            }): ""}
          </Box>
        </div>
        <Box sx={{
          mt: 1, mb: 0,
          display: "flex", justifyContent: "space-around", alignItems: "flex-end", overlow: "hidden",
          width: "100%",
        }}>
          <IconButton aria-label="start question set" size="large" color="error"
            onClick={() => { props.cancelNewQuestionSet(props.questionSet?.id || null) }}
          >
            <CancelIcon sx={{ fontSize: 30 }} />
          </IconButton>

          <Fab color="secondary" aria-label="flip card"
            onClick={() => { setFlip(!flip) }}
          >
            <FlipIcon />
          </Fab>

          {questionSet.description !== "" && !chosenQuestions.length ? <Tooltip title="suggest questions">
            <Fab color="primary" aria-label="flip card" disabled={isLoading} onClick={() => { askForQuestions(questionSet.name, questionSet.description) }} >
              {!isLoading ? <WizardIcon /> : <CircularProgress />}
            </Fab>
          </Tooltip> : ""}

          {chosenQuestions?.length ? (
            <Fab aria-label="submit"
              sx={{
                color: "white",
                "&.MuiFab-root": { backgroundColor: TertiaryColor.main },
                "&.MuiFab-root:hover": { backgroundColor: TertiaryColor.dark }
              }}
              onClick={() => { setDialogOpen(true) }}
            >
              <CheckIcon sx={{ fontSize: 40 }} />
            </Fab>
          ) : ""}

        </Box>
        <ConfirmationDialog
          open={dialogOpen}
          handleClose={() => { setDialogOpen(false) }}
          handleConfirm={handleCreateQuestionSet}
        />

        <EditQuestionDialog
          open={editQuestionOpen}
          handleClose={() => { setEditQuestionOpen(false) }}
          handleConfirm={(q, change) => {
            console.log("q change", q, change)
            handleQuestionChange(q, change)
            setEditQuestionOpen(false)
          }}
          editQuestion={ editQuestion }
          setEditQuestion={ setEditQuestion }
          remove={(q) => {
            handleRemoveQuestion(q)
            setEditQuestionOpen(false)
          }}
        />

        <RequestQuestionDialog
          open={ requestQuestionOpen }
          handleClose={() => { setRequestQuestionOpen(false) }}
          handleConfirm={ handleRequestNewQuestion }
          requestForm={ requestForm }
          setRequestForm={ setRequestForm }
        />
      </CardContent>
    </FlipCard>
  )
}
