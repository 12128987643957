import { Button } from '@mui/material';
import React from 'react';
import CookieConsent from 'react-cookie-consent';

interface CookieBannerProps {
  onClickMore: () => void;
}
const CookieBanner: React.FC<CookieBannerProps> = ({ onClickMore }) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Got It"
      cookieName="parrot-cookies"
      style={{ background: '#2B373B' }}
      buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      expires={150}
      buttonWrapperClasses="cookie-banner-button-wrapper"
    >
      This website uses cookies to enhance the user experience. 
      <Button onClick={onClickMore}>Learn more</Button>
    </CookieConsent>
  );
};

export default CookieBanner;
