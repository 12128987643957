import { getDocs, collection } from "firebase/firestore"
import { fs } from "./firebase"

export type Question = {
	id?: string
	type: "text" | "email" | "number" | "date" | "select" | "radio" | "checkbox" | "organisation" | "image" | "file"
	name: string
	label: string
	required?: boolean
	value: string
	multiline?: boolean
	options?: string[]
	tags?: string[]
	rows?: number
	doNotSave?: boolean,
	isPII?: boolean,
	action?: "see" | "save"
} // TODO: add order? 

export const getQuestions = async () => {
	const querySnapshot = await getDocs(collection(fs, "questions"))
	const questions = querySnapshot.docs.map((doc) => {
		const data = doc.data()
		data.id = doc.id
		return data as Question
	})

	questions.sort((a: Question, b: Question) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))

	if (questions.length !== 0) {
		return questions as Question[]
	}

	return [
		{
			type: "text",
			label: 'First Name',
			multiline: false,
			rows: 1,
			name: "given_name",
			value: "",
			isPII: true,
		},
		{
			type: "text",
			label: 'Last Name',
			multiline: false,
			rows: 1,
			name: "surname",
			value: "",
			isPII: true,
		},
		{
			type: "email",
			label: 'Email',
			multiline: false,
			rows: 1,
			name: "email",
			value: "",
			isPII: true,
		},
		{
			type: "tel",
			label: 'Phone Number',
			multiline: false,
			rows: 1,
			name: "phone",
			value: "",
			isPII: true,
		},
		{
			type: "url",
			label: 'Website',
			multiline: false,
			rows: 1,
			name: "www",
			value: "",
			isPII: false,
		},
		{
			type: "organisation",
			label: 'Organisation',
			multiline: false,
			rows: 1,
			name: "organisation",
			value: "",
			isPII: false,
		},
		{
			type: "date",
			label: 'Date of Birth',
			multiline: false,
			rows: 1,
			name: "dob",
			value: "",
			isPII: true,
		},
		{
			type: "text",
			label: 'Address',
			multiline: true,
			rows: 4,
			name: "address",
			value: "",
			isPII: true,
		},
	] as Question[]
}

