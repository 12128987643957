import React from 'react'
import { useNavigate } from 'react-router-dom';

import {
  Box, CardContent, Button, Typography, TextField, IconButton, Alert,
} from '@mui/material'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { isMobile } from 'react-device-detect';
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  cardContent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "space-between",
    width: "100%",
    overflowY: "auto",
    "-webkit-overflow-scrolling": "touch"
  },
  questionSetCodeWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export default function InitialScreen(props: any) {
  const classes = useStyles();
  const navigate = useNavigate()

  return (
    <CardContent className={classes.cardContent}>
      <Box className="topBox">
        <div className='logo-container'>
          <img src="/parrot.svg" alt="Parrot logo" width={isMobile ? "75px" : props.desktopTableView ? "100px" : "150px"} />
	</div>
        <h1 className="logo">Parrot</h1>
        <h6 className="logo">
          Answer or Ask Once <br /> End Forms Forever
        </h6>

        <Box sx={{ mt: 8 }}>
          <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>Enter question set code</Typography>
          {props.error && <Alert severity="error" sx={{ textAlign: "left", mt: 1 }}>{props.error}</Alert>}

          <Box className={classes.questionSetCodeWrapper}>
            <TextField 
              id="set-code" size="medium"
              variant="outlined" color="secondary"
              placeholder="A23Q56"
              style={{ width: 150, textAlign: "center" }}

              value={props.questionSetCode}
              onChange={(event) => props.setQuestionSetCode(event.target.value)}
              sx={{ backgroundColor: "white" }}
            />
            <IconButton aria-label="start question set" size="large" color="secondary"
              onClick={props.handleStartClick}
              disabled={props.questionSetCode === ""}
            >
              <CheckCircleIcon sx={{ fontSize: 55 }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      {!props.kiosk && <Box sx={{ textAlign: "center" }}>
        <Button aria-pressed="false" variant="contained" id="open-account-or-login" color="tertiary" sx={{ mb: 2 }}
	  role="link"
          aria-label='open account or login'
          title='open account or login'
          onClick={() => { navigate("/ask/login") }}
        >
          Open an account or login
        </Button>
        <Typography sx={{ mb: 1 }}>Ask own questions for free!</Typography>
        <Button role="link" href="https://linktr.ee/talktoparrot" target="_blank" variant="text" color="tertiary" aria-label='link tree' >Link Tree</Button>
      </Box>}
    </CardContent>
  )
}
