import React from "react"

import {
  Box, Button, Typography
} from "@mui/material"


import makeStyles from "@mui/styles/makeStyles";
import ReactMarkdown from 'react-markdown'
import { QRCode } from "react-qrcode-logo";
import { QrSettings  } from '../../Helpers/qrcodeSettings'

export default function QuestionSetDescription(props: any) {

  return (
    <div>
      <div className="desktopSides">
        {props.questionSet?.setterLogo && <Typography variant='h6'>
          <img src={props.questionSet?.setterLogo} alt={(props.questionSet?.setterName || props.questionSet?.orgName || "") + " logo"} title={props.questionSet?.setterName || props.questionSet?.orgName || "logo"} width="150" />
        </Typography>}
        <Typography variant="h3">
          {/* TODO: no org, once old data deleted */}
          {props.questionSet?.setterName || props.questionSet?.orgName || ""}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>{props.questionSet?.name}</Typography>
       
        <Box sx={{ mb: 2, overflowY: "auto", "-webkit-overflow-scrolling": "touch", height: "100%", whiteSpaceCollapse: "break-spaces" }}>
          <ReactMarkdown>{props.questionSet?.reason || props.questionSet?.description}</ReactMarkdown>
        </Box>
      </div>
      <div className="desktopSidesQr">
        <h3>Open on mobile</h3>
	<Button href={`/${props.questionSet?.id}`} className="qrlink">
          <QRCode {...QrSettings} value={`https://parrot.cards/${props.questionSet?.id}`} size={400} />
	</Button>
      </div>
    </div>
  )
}
