// import Gun from 'gun/gun'
const SEA = require('gun/sea') // require needed typing [ts]
// const gun = Gun(['https://obscure-escarpment-30893.herokuapp.com/gun'])
// const gun = Gun()
const hashValue = async (value: string) => { return await SEA.work(value, null, null, { name: 'SHA-256', encode: 'hex' }) }

export {
 //				gun, 
	SEA, 
	hashValue 
}
