export async function WriteNFC(data: string, onSuccess: Function, onError: Function) {
	if (!window.NDEFReader) return
	try {
		const ndef = new window.NDEFReader();
		await ndef.write({
			records: [{ recordType: "url", data }]
		});
		onSuccess()
	} catch (error) {
		onError(error);
	}
}
