import React from 'react'
import {
  Box, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, IconButton
} from '@mui/material'

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const NewEncryptionCodesDialog = (props: any) => {
  const { codes, open } = props
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogTitle>Login codes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {codes.join("") !== "" ? "To ensure a smooth login experience on various devices, please save the following codes as this is your initial login to the new system. These codes will be required for future logins on other devices." : "no login codes on this device"}
        </DialogContentText>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 2 }}>
          {codes?.map((code: string) => {
            return <Typography variant="h6" key={code} onClick={() => {
              navigator.clipboard.writeText(code)
              setSnackbarOpen(true)
            }}>
              {code} <ContentCopyIcon />
            </Typography>
          })}
        </Box>
      </DialogContent>
      <Snackbar autoHideDuration={6000} open={snackbarOpen} onClose={() => setSnackbarOpen(false)} message="Copied to clipboard" />
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={props.onClose} size="large">OK</Button>
      </DialogActions>
    </Dialog>
  )
}
