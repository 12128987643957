import React, { useState, useEffect } from "react"

import { QuestionSet } from "../../services/types"

import {
	Box, CardContent, Typography, Divider, IconButton,
} from "@mui/material"

import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getUser } from "../../Helpers/localStorage"

import { doc, fs, getDoc, setDoc } from "../../services/firebase";

import WrapperCard from "../../components/WrapperCard";

import makeStyles from "@mui/styles/makeStyles";

import { useNavigate } from "react-router";
import themeConfig from "../../theme";
import { PermissionsEnum, checkPermission } from "../../Helpers/permissions";

const useStyles = makeStyles(_ => ({
	wrapper: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
	},
	cardContent: {
		display: "flex",
		flexFlow: "column",
		alignItems: "space-between",
		width: "100%"
	},
	title: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	},
}))

export default function ArchivedQuestionSets() {
	const classes = useStyles();
	const navigate = useNavigate()

	const [user] = useState(getUser())
	const [setter, setSetter] = useState<any>(null)

	const [questionSets, setQuestionSets] = useState<QuestionSet[]>([])
	const [questionSetShowing, setQuestionSetShowing] = useState<QuestionSet | null>(null)

	useEffect(() => {

		if (user?.email && user?.email !== "") {
			loadData()
		}

		return () => {
		}
	}, [user])

	async function loadData() {
		const setter = await loadSetterProfile(user?.setterId)
		setSetter({
			setterContactEmail: setter?.contactEmail || "",
			setterName: setter?.organisationName || "",
			setterLogo: setter?.logo || "",
		})

		if (setter?.questionSets) {
			// Get question sets from firestore
			let questionSets = await Promise.all(setter?.questionSets.map(async (id: string) => {
				try {
					const qs = await getDoc(doc(fs, "questionSets", id))
					if (!qs.exists()) {
						return null
					}

					let questionSet = qs.data() as QuestionSet

					if (questionSet.status !== "archived") return null

					questionSet.fields = Object.values(questionSet.fields)
					// console.log(questionSet)
					return questionSet

				} catch (error) {
					console.error(error)
					return null
				}
			}))

			// Filter the nulls
			questionSets = questionSets.filter((data: QuestionSet) => data != null)
			// Order by most recent
			questionSets.sort(function (a: QuestionSet, b: QuestionSet) {
				return new Date(a.created!) < new Date(b.created!) ? 1 : -1
			})

			setQuestionSets(questionSets)
		}
	}

	async function loadSetterProfile(setterId: string) {
		const docRef = doc(fs, "setters", setterId) // TODO
		const setter = (await getDoc(docRef))?.data()
		return setter
	}

	function handleViewQuestionSet(questionSet: QuestionSet) {
		setQuestionSetShowing(questionSet)
	}

	async function handleUnarchiveQuestionSet() {
		if (!questionSetShowing) return

		// Delete from firebase
		await setDoc(doc(fs, "questionSets", questionSetShowing.id), { status: "active" }, { merge: true });

		// Update list of question sets
		setQuestionSets(questionSets.filter((qs: QuestionSet) => qs.id !== questionSetShowing.id))

		// Updates
		setQuestionSetShowing(null)
	}

	return (
		<Box className={classes.wrapper}>
			{!questionSetShowing ? (
				<WrapperCard>
					<CardContent sx={{ flex: "1", width: "100%", display: "flex", flexDirection: "column" }}>
						<Box sx={{ flexGrow: 1, textAlign: "left" }}>
							{setter?.setterLogo && (<img src={setter?.setterLogo} width="100" />)}
							
							<Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", my: 2, width: "100%", }}>
								<ArrowBackIcon sx={{ fontSize: 20, mr: 1, cursor: "pointer" }} onClick={() => { navigate("/ask") }} />
								<Typography variant="h5">Archived question sets</Typography>
							</Box>
							
							{questionSets.length ? <Box>
								{questionSets.map((questionSet: QuestionSet) => {
									return (
										<Box key={questionSet.id} sx={{ my: 1 }}
											onClick={() => { handleViewQuestionSet(questionSet) }}
										>
											<Box sx={{ display: "flex", py: 1 }}>
												<Box sx={{ flexGrow: 1.5 }}>
													<Typography variant="body1">{questionSet.id} - {questionSet.name}</Typography>
													<Typography variant="body1">Expires: {questionSet.expiryDate?.slice(0, 10) || "N/A"}</Typography>
												</Box>
												<Box sx={{ flexGrow: 0.5, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
													<KeyboardArrowRightRoundedIcon />
												</Box>
											</Box>
											<Divider />
										</Box>
									)
								})}
							</Box> : <Typography variant="body1">No question sets archived!</Typography>
							}
						</Box>

					</CardContent>
				</WrapperCard>
			) : (
				<WrapperCard>
					<CardContent className={classes.cardContent} sx={{ textAlign: "left" }}>
						<Box className={classes.title}>
							<Typography variant="h5" sx={{ mt: 0 }}>
								Question Set
							</Typography>
							<IconButton disabled={!checkPermission(PermissionsEnum.CUD_QS, user?.permissions)} onClick={handleUnarchiveQuestionSet}>
								<UnarchiveOutlinedIcon
									fontSize="large"
									sx={{ color: checkPermission(PermissionsEnum.CUD_QS, user?.permissions) ? themeConfig.palette.tertiary.main : "gray" }}
								/>
							</IconButton>
						</Box>

						<Box sx={{ flexGrow: 1, mt: 5 }}>
							<Typography variant="h6" sx={{ color: "gray" }}>{questionSetShowing.id}</Typography>
							<Typography variant="h6">{questionSetShowing.name}</Typography>
							<Typography sx={{ my: 1 }} variant="body1">Expires: {questionSetShowing.expiryDate?.substring(0, 10) || "N/A"}</Typography>
							<Typography variant="body1">{questionSetShowing.description}</Typography>
						</Box>

						<Box sx={{ display: "flex", justifyContent: "center" }}
							onClick={() => { setQuestionSetShowing(null) }}
						>
							<IconButton aria-label="start question set" size="large" color="error"
							>
								<CancelIcon sx={{ fontSize: 30 }} />
							</IconButton>
						</Box>

					</CardContent>
				</WrapperCard>
			)}
		</Box>
	)
}
