import React from 'react'

import makeStyles from '@mui/styles/makeStyles';
import { Card } from '@mui/material';
import { maxHeight } from '../Helpers/height';
import ReactCardFlip from 'react-card-flip';

const useStyles = makeStyles(_ => ({
  mainCard: {
    margin: "10px auto",
    // maxHeight: "80vh",
    display: "flex",
    boxShadow: "0px 2px 5px #888",
    backgroundColor: "#6200ee0a",
    borderRadius: 20,
  },
  mainCardNoShadow: {
    margin: "10px auto",
    display: "flex",
    boxShadow: "none"
  },
}))

export default function FlipCard(props: any) {
  const classes = useStyles();

  return (
    <ReactCardFlip key={props.item.key} isFlipped={props.flip} flipDirection="horizontal"
      cardStyles={{
        back: {
          width: "100%",
        }
      }}
    >
      <Card
        className={(props.noShadow ? classes.mainCardNoShadow : classes.mainCard) + " cardWidth"}
        sx={{
          height: maxHeight("100px"),
          overflow: props.scroll ? "scroll" : "hidden",
          "-webkit-overflow-scrolling": props.scroll ? "touch" : "auto",
        }}
      >
        {props.children[0]}
      </Card>
   
      <Card
        className={(props.noShadow ? classes.mainCardNoShadow : classes.mainCard) + " cardWidth"}
        style={{display:(props.isIOS && !props.flip) ? "none" : "" }}
        sx={{
          height: maxHeight("100px"),
          overflow: props.scroll ? "scroll" : "hidden",
          "-webkit-overflow-scrolling": props.scroll ? "touch" : "auto",
        }}
      >
        {props.children[1]}
      </Card>
    </ReactCardFlip>
  )
}
