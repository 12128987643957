import React, { useState, useEffect, } from 'react'
import { useLocation } from 'react-router-dom';

import { Field, Pair, Answer, QuestionSet } from "../../services/types"

import {
  Box, CardContent, Typography, TextField, IconButton, Snackbar,
  Fab, FormControl, Button, Autocomplete,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, styled, useTheme, Avatar, Rating,
} from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert';

import FlipIcon from '@mui/icons-material/Flip';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangeHistoryRoundedIcon from '@mui/icons-material/ChangeHistoryRounded';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import LockImage from "../../images/DRY Icon White.svg"

import { getQuestions, Question } from "../../services/questions";

import { hashValue, SEA } from '../../services/gun'
import { getPair, getShowTime, loadTrail, saveTrail } from "../../Helpers/localStorage"

import Draggable from 'react-draggable';

import "../../styles/slides.css";

import InitialScreen from "./InitialScreen";
import QuestionSetDescription from "./QuestionSetDescription";
import QuestionSetDescriptionDesktop from "./QuestionSetDescriptionDesktop";
import FullSavedAnswer from './FullSavedAnswer';
import WrapperCard from '../../components/WrapperCard';

import { doc, fs, getDoc, setDoc } from "../../services/firebase";
import { CarouselItem } from '../../services/carousel';

import { formatDate, parseDate } from '../../Helpers/dates';
import { load, save } from '../../Helpers/localStorage';
import { WriteNFC } from "../../Helpers/NFC";
import LoadImage from '../../components/loadImage';

import makeStyles from '@mui/styles/makeStyles';
import { getNFCData, writeNFCData } from '../../services/api';
import themeConfig from '../../theme';
import CustomBreadcrumbs from '../../components/Breadcrumbs';

import { QRCode  } from 'react-qrcode-logo';
import { QrSettings  } from '../../Helpers/qrcodeSettings';
import { isIOS, isMobile} from 'react-device-detect';
import GDPRDialog from "../../components/GDPRDialog";
import FlipCard from '../../components/FlipCard';

const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  mainCard: {
    margin: 10,
    // maxHeight: "80vh",
    display: "flex",
    boxShadow: "0px 2px 5px #888"
  },
  mainCardNoShadow: {
    margin: 10,
    display: "flex",
    boxShadow: "none"
  },
  cardContent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "space-between",
    width: "100%"
  },
  topBox: {
    flexGrow: 1,
    textAlign: "center",
  },
  questionSetCodeWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  draggableBox: {
    float: "left",
  },
  breadcrumb: {
    display: "flex",
    alignItems: "center",
  },
}))

const INITIAL_POSITION = { x: 0, y: 74 }
const FINAL_POSITION = { x: 0, y: 0 }

const homeItem: CarouselItem = {
  key: "responder_home",
  type: "responder_home"
}

const moment = require('moment');
moment().format();

// the required distance between touchStart and touchEnd to be detected as a swipe
const minSwipeDistance = 50

// Snack Alert component
const SnackAlert = React.forwardRef<HTMLDivElement, AlertProps>(function alert(
  props,
  ref,
) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PinDialogOpenEnum = {
  WRITE: "write",
  READ: "read"
}
const BigThankyou = (props: any) => {
  return (
    <Dialog open={props.open !== false} onClose={props.handleClose}>
      <DialogTitle>Thank You</DialogTitle>
      <DialogContent>
        <DialogContentText>Thank You for Answering</DialogContentText>
        <img src="/parrotThumb.svg" alt="Thank you" />
      </DialogContent>
    </Dialog>
  )
}
const PinDialog = (props: any) => {
  const [pinCode, setPinCode] = useState("")
  const [error, setError] = useState<boolean | string>(false)

  const onChange = (e) => {
    const value = e.target.value
    setError(false)
    setPinCode(value)
  }

  const onContinue = () => {
    if (pinCode.trim() === "") {
      return setError("Pin code can't be empty")
    } else if (pinCode.length < 4) {
      return setError("Pin code should be at least 4 characters")
    }

    setPinCode("")
    props.pinCodeContinue(pinCode)
  }

  return (
    <Dialog open={props.open !== false} onClose={props.handleClose}>
      <DialogTitle>Pin</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.open === PinDialogOpenEnum.READ ? "Please enter the pin code you chose when you first used your NFC card." :
            "Please choose a pin code to secure your data then tap your NFC card."}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="pin"
          label="PIN code"
          type="text"
          fullWidth
          variant="standard"

          value={pinCode}
          onChange={onChange}

          error={error !== false}
          helperText={error || ""}

          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onContinue()
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={onContinue}>Continue</Button>
      </DialogActions>
    </Dialog>
  )
}
interface YesNoDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

function isNullOrEmpty(value: string) {
  if (value === undefined || value === null) return true
  if (typeof value === "string") return value?.trim() === ""
  return !value
}

const CustomTextField = styled(TextField)(() => {
  const theme = useTheme();

  return {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.success.main,
      },
    },
  }
});

const TertiaryColor = themeConfig.palette.tertiary

export default function Home(props: any) {
  const classes = useStyles();
  const theme = useTheme();

  //#region state
  const [gunPair, setGunPair] = useState<Pair | null>(null)

  const [carouselItems, setCarouselItem] = useState<CarouselItem[]>([homeItem])
  console.log("cards", carouselItems)

  const [carouselIndex, setCarouselIndex] = useState(0)
  let pathCode = useLocation().pathname.slice(1)
  if (pathCode !== "" && pathCode.length !== 6) {
    pathCode = ""
  }
  const [questionSetCode, setQuestionSetCode] = useState((new URLSearchParams(window.location.search)).get("code") || pathCode || window.location.hash.slice(1) || "")
  console.log("questionSetCode", questionSetCode)
  if (questionSetCode !== "" && questionSetCode.length !== 6) {
    // setQuestionSetCode("")
  } else {
    console.log("questionSetCode", questionSetCode)
    // window.location.pathname = "/"+questionSetCode
  }
  const [desktopTableView, setDesktopTableView] = useState<Boolean>(!isMobile && !questionSetCode && carouselItems.length > 1)

  useEffect(() => {
    console.log("isMobile", isMobile)
    console.log("questionSetCode", questionSetCode)
    console.log("carouselItems", carouselItems.length)

    setDesktopTableView(!isMobile && !questionSetCode && carouselItems.length > 1)
  }, [isMobile, questionSetCode, carouselItems, setDesktopTableView])


  const [error, setError] = useState<string | null>(null)

  const [pinDialogOpen, setPinDialogOpen] = useState<boolean | string>(false)
  const [NFCOptionOpen, setNFCOptionOpen] = useState(false)
  const [NFCData, setNFCData] = useState<any>(null)
  const [NFCParam, setNFCParam] = useState((new URLSearchParams(window.location.search)).get("nfc") || "")

  const [questionSet, setQuestionSet] = useState<QuestionSet | null>(null)
  const [answerComplete, setAnswerComplete] = useState(false)

  const [flip, setFlip] = useState(false)

  const [draggable, setDraggable] = useState({
    initialPosition: INITIAL_POSITION,
    deltaPosition: INITIAL_POSITION,
    disabled: false,
    controlledPosition: INITIAL_POSITION,
  })

  const [questionsList, setQuestionsList] = useState<Question[]>([])

  const [GDPROpen, setGDPROpen] = useState(false)
  const [snackAlertOpen, setSnackAlertOpen] = useState<any>(false)
  const [showingBigThankYou, showBigThankYou] = useState<any>(false)


  //#endregion

  useEffect(() => {
    // console.log("window.location.href", window.location.href)
    // console.log("props.kiosk", props.kiosk)

    // Get saved data and previous answers + 
    async function getGunPair(keys: string) {
      const pair = await getPair(keys) // From local storage

      setGunPair(pair)
      // console.log("pair", pair)

      // Fetch trail if exists
      fetchAnswersTrail(pair)

      return pair as Pair
    }

    // Only if not Kiosk mode
    if (!props.kiosk) getGunPair("keys")
    else {
      // clear non-kiosk data
      setGunPair(null)
      setCarouselItem([homeItem])
      setCarouselIndex(0)
      setError(null)
      // setQuestionSetCode
      setQuestionSet(null)
      setAnswerComplete(false)
      setFlip(false)
      setDraggable({
        initialPosition: INITIAL_POSITION,
        deltaPosition: INITIAL_POSITION,
        disabled: false,
        controlledPosition: INITIAL_POSITION,
      })
    }

    getQuestions().then((questions) => {
      // console.log("questions", questions.length)
      setQuestionsList(questions)
    })

    return () => {
    }
  }, [props.kiosk])

  useEffect(() => {
    // console.log("NFCParam", NFCParam)
    if (NFCParam) {
      try {
        getNFCData({ id: NFCParam }).then((NFCRead: any) => {
          // alert(JSON.stringify(NFCRead))
          if (NFCRead.data) {
            setNFCData(NFCRead.data)
            setPinDialogOpen(PinDialogOpenEnum.READ)
          } else {
            throw new Error("No data")
          }
        })
      } catch (err) {
        alert("Error while reading NFC data")
      }
    }
    return () => {
    }
  }, [NFCParam])

  useEffect(() => {
    const { hash } = window.location
    if (props.kiosk && questionSetCode.length === 6) {
      save("kiosk", { questionSetCode })
    }

    if (props.kiosk && questionSetCode === "" && typeof hash === "string" && hash.length === 7) {
      setQuestionSetCode(hash.slice(-6))
    } else if (questionSetCode.length === 6 && ((gunPair && !props.kiosk) || props.kiosk)) {
    //  window.history.pushState({}, document.title, props.kiosk ? "/kiosk" : "/");
      handleStartClick()
    }
  }, [questionSetCode, gunPair, props.kiosk])

  //#region handlers
  const goToSlide = (id: string) => {
    // console.log("goToSlide", id)
    // console.log("window.location", window.location)
    const { pathname, search } = window.location
    const newHref = pathname + search + "#" + id
    // console.log("newHref", newHref)
    window.location.href = newHref
  }

  const fetchAnswersTrail = async (pair: Pair) => {
    let answers: Answer[] = []

    // First check local storage
    const trail = loadTrail()
    // console.log("trail", trail)
    // console.log("Answers length", Object.keys(trail).length)

    if (Object.keys(trail).length) {
      answers = await Promise.all(Object.keys(trail).map(async (key: string) => {
        const encrypted = trail[key]
        try {
          const decrypted = await SEA.decrypt(encrypted, pair)
          return decrypted
        } catch (err: any) {
          console.error("unable to decrypt", key)
        }
      }))
    }

    // console.log("answers", answers)
    if (answers && answers.length) {
      // Order by timestamp
      answers.sort(function (a: Answer, b: Answer) {
        return new Date(a.timestamp!) < new Date(b.timestamp!) ? 1 : -1
      })

      const answersAlreadyInCarousel = carouselItems.filter((item: CarouselItem) => item.type === "responder_saved_answer").map((item: CarouselItem) => item.key)
      // console.log("answersAlreadyInCarousel", answersAlreadyInCarousel.length)
      answers = answers.filter((answer: Answer) => !answersAlreadyInCarousel.includes(answer.id))

      // Set state
      setCarouselItem([
        ...carouselItems,
        ...answers.map((answer: Answer): CarouselItem => {
          return {
            key: answer.id || "",
            type: "responder_saved_answer",
            answer,
          }
        })
      ])
    }
  }

  function addQuestionSetToCarousel() {
    // Add question set to carousel
    const questionSetItem: CarouselItem = {
      type: "responder_question_set",
      key: questionSetCode
    }
    // Check if another question set was requested / if yes, replace it with new / if not add current to index 1
    const questionSetFound = carouselItems.find((item: CarouselItem) => item.type == "responder_question_set")
    setCarouselItem([
      ...carouselItems.slice(0, 1),
      questionSetItem,
      ...carouselItems.slice((questionSetFound && questionSetFound?.key !== questionSetCode) ? 2 : 1),
    ])
    setCarouselIndex(1)

    // Wait for the carousel to update
    setTimeout(
      () => {
        goToSlide(questionSetCode)
      },
      10
    );
  }

  const handleStartClick = async () => {
    if (questionSetCode.trim() === "") return

    console.log("handleStartClick", questionSetCode)

    // Check that the question set is not already in the carousel
    const questionSetIndex = carouselItems.findIndex((item: CarouselItem) => item.key == questionSetCode && item.type == "responder_question_set")
    if (questionSetIndex !== -1) {
      setCarouselIndex(questionSetIndex)
      goToSlide(questionSetCode)
      return
    }

    let questionSet: QuestionSet

    try {
      console.log("firebase: get question set")
      const qs = await getDoc(doc(fs, "questionSets", questionSetCode))
      if (!qs.exists()) {
        return setError("Question set not found. Please try another code.")
      }

      questionSet = qs.data() as QuestionSet
      questionSet.fields = await loadFields(questionSet.fields)

    } catch (error) {
      console.error(error)
      // Show error message
      return setError("Question set not found. Please try another code.")
    }

    // Question set found, hide error message
    setError(null)

    if (questionSet.expiryDate && moment().isAfter(parseDate(questionSet.expiryDate))) {
      return setError("Question set expired. Please try another code.")
    } else if (questionSet?.status === "archived") {
      return setError("Question set archived. Please try another code.")
    }

    setQuestionSet(questionSet)
    setDraggable({
      initialPosition: INITIAL_POSITION,
      deltaPosition: INITIAL_POSITION,
      disabled: false,
      controlledPosition: INITIAL_POSITION,
    })
    addQuestionSetToCarousel()

    // For now just show the "swipe" message and change button to draggable
    const notOkayFields = questionSet.fields?.filter((field: Field) => (isNullOrEmpty(field.decrypted) && (field.required === true)))
    const allFieldsFilled = notOkayFields?.length === 0
    console.log(allFieldsFilled)

    if (allFieldsFilled) {
      // Ready to show
      setAnswerComplete(true)

      // Animate the draggable button. Look into using the draggable component ajust x and y
      // Show animation
      animateDraggableButton()
    } else {
      setAnswerComplete(false)
    }

  }

  function getField(name: string, pair: Pair): Promise<string> {
    return new Promise((resolve, reject) => {
      getDoc(doc(fs, "userDB", pair.pub + "-" + name)).then(async (msgRef: any) => {
        if (!msgRef.exists()) return resolve("")

        const msg = msgRef.data()
        console.log("msg", msg)

        if (typeof msg === "object" && msg !== null) {
          let dec: any
          try {
            dec = (await SEA.decrypt(msg, pair))
          } catch (error) {
            console.error(error)
            reject(error)
          }

          // console.log("dec", name, dec, typeof dec)
          if (typeof dec === "string") {
            resolve(dec)
          } else if (typeof dec === "number" || typeof dec === "boolean") {
            resolve(dec.toString())
          }
          reject("not string")
        }
        reject("not object or is null")
      })
    })
  }

  async function loadFields(fields: Field[]): Promise<Field[]> {
    const fieldsIndex = Object.keys(fields).filter((id: string) => id !== "_")
    // console.log(fieldsIndex)
    return await Promise.all(fieldsIndex.map(async (index: string) => {
      return await new Promise(async resolve => {
        const field = fields[index]
        // console.log(index, field)
        if (!field) return

        const { name } = field as Field

        let decrypted: any = ""
        if (gunPair) {// KIOSK: no decrypting as gunPair is null
          try {
            decrypted = (await getField(name, gunPair as Pair))
          } catch (err: any) {
            // console.log(field.name, err)
            decrypted = ""
          }
        }

        // Note: this won't ensure the order of the fields!
        resolve({ ...field, decrypted })
      })
    }))
  }

  function animateDraggableButton() {
    const { x, y } = INITIAL_POSITION
    // console.log("draggable", draggable)

    let timeout = 100
    const yStep = 5
    const timeStep = 50

    const setTimeoutPosition = (offset: number, timeout: number) => {
      setTimeout(function () {
        setDraggable({
          ...draggable,
          disabled: false,
          deltaPosition: {
            x: x,
            y: y - offset,
          },
          controlledPosition: {
            x: x,
            y: y - offset,
          },
        })
      }, timeout);
    }

    for (let i = 1; i <= 3; i++) {
      setTimeoutPosition(i * yStep, timeout)
      timeout += timeStep
    }
    timeout += timeStep
    for (let i = 2; i >= 0; i--) {
      setTimeoutPosition(i * yStep, timeout)
      timeout += timeStep
    }

    for (let i = 1; i <= 3; i++) {
      setTimeoutPosition(i * yStep, timeout)
      timeout += timeStep
    }
    timeout += timeStep
    for (let i = 2; i >= 0; i--) {
      setTimeoutPosition(i * yStep, timeout)
      timeout += timeStep
    }
  }

  const handleCancelAnswering = () => {
    setCarouselItem([
      ...carouselItems.slice(0, 1),
      ...carouselItems.slice(2),
    ])
    setCarouselIndex(0)
    goToSlide("responder_home")
    setQuestionSetCode("")
    setQuestionSet(null)
    setAnswerComplete(false)

    if (props.kiosk) setGunPair(null)
  }

  const handleFieldChange = (event: any, theName: any = null, theValue: any = null) => {
    const name: string = theName || event?.target.name
    const decrypted: string = theValue || event?.target.value

    console.log("handleFieldChange", name, decrypted, event)

    setQuestionSet((oldSet: QuestionSet | null) => {
      if (!oldSet) return null

      const newFields = oldSet?.fields.map(field => {
        if (field.name === name) {
          return { ...field, decrypted }
        }
        return field
      })
      // For now just show the "swipe" message and change button to draggable
      const notOkayFields = newFields?.filter((field: Field) => (isNullOrEmpty(field.decrypted) && (field.required === true)))
      const allFieldsFilled = notOkayFields?.length === 0
      console.log(allFieldsFilled)

      if (!answerComplete && allFieldsFilled) {

        // Ready to show
        setAnswerComplete(true)

        // Animate the draggable button
        animateDraggableButton()
      } else if (answerComplete && !allFieldsFilled) {
        setAnswerComplete(false)
      }

      return { ...oldSet, fields: newFields }
    })
  }

  async function saveField(name: string, decrypted: string) {
    // console.log(name, decrypted)
    const enc = await SEA.encrypt(decrypted, gunPair)
    const data = await SEA.sign(enc, gunPair)
    const msg = await SEA.verify(data, gunPair?.pub)

    if (typeof msg === "object" && msg !== null) {
      console.log("msg", name, decrypted, msg)
      return setDoc(doc(fs, "userDB", gunPair?.pub + "-" + name), msg)
    }
  }

  function saveToNFC(pair) {
    console.log("saveToNFC", pair)
    setPinDialogOpen(PinDialogOpenEnum.WRITE)
  }

  const handleDragButton = (e: any, ui: any) => {
    const { x, y } = draggable.deltaPosition;

    if (x == FINAL_POSITION.x && y == FINAL_POSITION.y) return
    // console.log("x", x, ui.deltaX, x + ui.deltaX, FINAL_POSITION.x)
    // console.log("y", y, ui.deltaY, y + ui.deltaY, FINAL_POSITION.y)

    const newX = x + ui.deltaX
    const newY = y + ui.deltaY
    // console.log("x,y", newX, newY)

    if (newX >= FINAL_POSITION.x && newY == FINAL_POSITION.y) {
      // console.log("done")
      setDraggable({
        ...draggable,
        disabled: true,
        deltaPosition: {
          x: Math.min(newX, FINAL_POSITION.x),
          y: newY,
        },
        controlledPosition: {
          x: ui.x,
          y: ui.y,
        },
      })

      handleShareAnswers()
    } else {
      setDraggable({
        ...draggable,
        deltaPosition: {
          x: newX,
          y: newY,
        },
        controlledPosition: {
          x: ui.x,
          y: ui.y,
        },
      });
    }
  };

  function NFCOptionDialog(props: YesNoDialogProps) {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose(false);
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        {/* TODO: correct text  */}
        <DialogTitle>Would you like to secure your data with an NFC card?</DialogTitle>
        <Button onClick={() => { onClose(true) }}>Yes</Button>
        <Button onClick={() => { onClose(false) }}>No</Button>
      </Dialog>
    );
  }

  const onCloseNFCOptionDialog = (answer: boolean) => {
    console.log("onCloseNFCOptionDialog", gunPair)
    setNFCOptionOpen(false)

    if (answer) {
      saveToNFC(gunPair)
    } else {
      setGunPair(null)
      kioskReset()
    }
  }

  async function handleShareAnswers() {
    console.log("handleShareAnswers")
    // console.log("questionSet", questionSet)

    // KIOSK: if no gunpair && kiosk mode, then create a new gunpair
    if (!questionSet || (!gunPair && !props.kiosk)) return

    let gunPairLocal: Pair = gunPair || await SEA.pair()
    // console.log(props.kiosk, gunPair, gunPairLocal)

    const { id, setterContactEmail, setterName, setterAbout, setterContactFullName, setterLogo, setterEpub, setterPub, fields } = questionSet

    const timestamp = new Date()
    let answer: Answer = {
      setterContactEmail, setterName, setterContactFullName, setterLogo, setterEpub, setterPub,
      setterAbout,
      title: questionSet.name,
      reason: questionSet.description,

      id,
      userPub: gunPairLocal.pub,
      userEpub: gunPairLocal.epub,
      fields,
      timestamp,
    }

    const ansHash = id + "-" + (await hashValue(JSON.stringify(answer)))

    // console.log("answer", ansHash, answer)
    answer = { ...answer, id: ansHash }
    const answerString = JSON.stringify(answer)

    // Share 
    const secret = await SEA.secret(setterEpub, gunPairLocal)
    const enc = await SEA.encrypt(answerString, secret)
    const data = await SEA.sign(enc, gunPairLocal)
    const msg = await SEA.verify(data, gunPairLocal.pub)

    // Save to firestore
    const showTime = getShowTime() || "1m"
    await setDoc(doc(fs, "answers", setterPub, "hash", ansHash), {
      encrypted: msg,
      time: showTime,
      created: formatDate(timestamp),
      questionSetId: id,
      responderEpub: gunPairLocal.epub,
    })

    // Save encrypted (with pub) answer to ~ directory if not kiosk mode
    if (!props.kiosk) {
      // Encrypt and save fields to firestore
      await Promise.all(fields.map(async (field: Field) => {
        return saveField(field.name, field.decrypted)
      })).then(() => {
	console.log("fields saved")
      }).catch((err) => {
	console.error("error while firestore put", err)
      })

      // Encrypt and save trail
      const localEnc = await SEA.encrypt(answer, gunPairLocal)
      const localData = await SEA.sign(localEnc, gunPairLocal)
      const localMsg = await SEA.verify(localData, gunPairLocal?.pub)

      /* if (typeof localMsg === "object" && localMsg !== null) {
        gun.get("~" + gunPairLocal?.pub).get("trail").get(ansHash).put(localMsg)
      } */

      // Save encrypted answer to localstorage
      saveTrail(ansHash, localMsg)

      // Delete question set and add answers card
      setCarouselItem([
        ...carouselItems.slice(0, 1),
        {
          key: ansHash,
          type: "responder_saved_answer",
          answer,
        },
        ...carouselItems.slice(2),
      ])
      goToSlide(ansHash)
      setCarouselIndex(1)

      setQuestionSetCode("")

      // reset state
      setQuestionSet(null)
      setAnswerComplete(false)
      setSnackAlertOpen({
        severity: "success",
        text: "Thank you for Answering!"
      })
      const ref = window.location.search.split("ref=")[1] || document.referrer.replace(/http?s\:\/\//, "") ||  ""
      console.log("document.referrer", document.referrer, ref)
      if (ref) {
        showBigThankYou(true)
      }
      // reload home page after 2 second or close window if referrer
      setTimeout(() => {
        window.location.href = "https://" + ref || "/";
      }, 2000)

    } else {
      // if kiosk mode
      // Success snackbar
      setSnackAlertOpen({
        severity: "success",
        text: "Answer submitted successfully!"
      })

      // NFC and no NFC data read
      if (window.NDEFReader && !NFCData) {
        console.log("NFCOptionOpen", NFCOptionOpen)
        setGunPair(gunPairLocal)
        setNFCOptionOpen(true)
      } else if (NFCData) {
        if (questionSet && questionSet.fields && questionSet.fields.length && gunPairLocal) {
          try {
            Promise.all(questionSet.fields.map((field: Field): Promise<any> => {
              return saveField(field.name, field.decrypted)
            })).then(() => {
              kioskReset()
            }).catch((err) => {
              console.error("error while firestore put", err)
              // TODO try again
              kioskReset()
            })
          } catch (err) {
            alert("Err firestore part" + err)
          }
        } else {
          kioskReset()
        }
      } else {
        kioskReset()
      }

    }
  }

  function kioskReset() {
    console.log("kioskReset")
    // reset state
    setQuestionSet(null)
    setAnswerComplete(false)

    // Delete question set and add answers card
    setCarouselItem([
      ...carouselItems.slice(0, 1),
    ])
    goToSlide(carouselItems[0].key)
    setCarouselIndex(0)

    setGunPair(null)

    if (NFCData) {
      setNFCData(null)
      setNFCParam("")
    }

    // TODO: why is it opening the question set again?
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackAlertOpen(false);
  };

  const onEnterPinCode = async (pinCode: string) => {
    if (pinDialogOpen === PinDialogOpenEnum.READ) {

      setPinDialogOpen(false)
      console.log("NFCData", NFCData)
      const decrypted = await SEA.decrypt(NFCData.encrypted, pinCode)
      console.log(" onEnterPinCode decrypted", decrypted)

      if (!decrypted) {
        setSnackAlertOpen({
          severity: "error",
          text: "Pin code is wrong!"
        })
      } else {
        setSnackAlertOpen({
          severity: "success",
          text: "Pin code is correct!"
        })
        const pair = {
          pub: NFCData.pub,
          epub: NFCData.epub,
          ...decrypted,
        }
        loadNFCData(pair)
      }
    } else if (pinDialogOpen === PinDialogOpenEnum.WRITE && gunPair !== null) {
      setPinDialogOpen(false)

      SEA.encrypt(gunPair, pinCode).then(async (encrypted: string) => {
        console.log("data", encrypted)

        async function NFCWrapper() {
          // TODO: show message to tap your card

          let pubKeyHash = await hashValue(gunPair?.pub || "")
          const url = window.location.href.split("#")[0] + "?nfc=" + pubKeyHash
          WriteNFC(url, async () => {
            // save to server under pubKeyHash
            await writeNFCData({ encrypted, id: pubKeyHash })

            setSnackAlertOpen({
              severity: "success",
              text: "Successfully written to NFC!"
            })

            // Save to Firestore
            if (questionSet && questionSet.fields && questionSet.fields.length && gunPair) {
              try {
                Promise.all(questionSet.fields.map((field: Field): Promise<any> => {
                  return saveField(field.name, field.decrypted)
                })).then(() => {
                  setGunPair(null)
                  kioskReset()
                }).catch((err) => {
                  console.error("error while gun put", err)
                  // TODO try again
                  setGunPair(null)
                  kioskReset()
                })
              } catch (err) {
                alert("Err firestore part" + err)
              }
            } else {
              kioskReset()
            }

          }, (err) => {
            console.error("Error writing to NFC: " + err)

            if (window.confirm("NFC failed. Would you like to try again?" + err)) {
              NFCWrapper()
            } else {
              kioskReset()
            }
          })
        }

        NFCWrapper()
      }).catch((err) => {
        console.log("err", err)
        alert("Error " + err)
      })
    }
  }

  async function loadNFCData(pair: any) {
    console.log("loadNFCData", pair)

    // Get question set from local storage
    if (questionSetCode === "" && props.kiosk) {
      setQuestionSetCode(load("kiosk", { questionSetCode: "" }).questionSetCode)
    }
    setGunPair(pair)

    // get data
    if (questionSet && questionSet.fields) {
      Promise.all(questionSet.fields.map((field: Field): Promise<any> => {
        return new Promise(async (resolve) => {
          let decrypted = ""
          try {
            decrypted = (await getField(field.name, pair as Pair))
          } catch (err: any) {
            // console.log(field.name, err)
            decrypted = ""
          }
          resolve({ ...field, decrypted })
        })
      })).then((fields) => {
        setQuestionSet({ ...questionSet, fields })

        // For now just show the "swipe" message and change button to draggable
        if (fields.filter((field: Field) => field.decrypted !== "").length === questionSet.fields.length) {
          // Ready to show
          setAnswerComplete(true)

          // Animate the draggable button. Look into using the draggable component ajust x and y
          // Show animation
          animateDraggableButton()
        } else {
          setAnswerComplete(false)
        }

      }).catch((err) => {
        console.error("error in firestore get", err)
      })
    }
  }

  //#endregion handlers
  return (
    <Box className={(desktopTableView ? "desktopTableView " : "") + classes.wrapper}>
      <Box className="slide-container">
        {
          carouselItems.map((item: CarouselItem) => {
            // console.log("item.type", item.type)
            switch (item.type) {
              case "responder_home":
                return (
                  <Box className="slide home" key={item.key} id={item.key}>
                    {!isMobile && !desktopTableView && (<Box sx={{width: 412, display: "inline-block"}}>
                      <div className='desktopSides'>
                        <div>
                          <h2>Answer</h2>
                          <p>
                            Fast, Secure, Single-Responses <br />
                            <br />
                            All Phone Types <br />
                            End-to-End Encryption <br />
                            One-Time Answer <br />
                            Free Use <br />
                            Audited trail of all data <br />
                          </p>
                        </div>
                      </div>
                      <div className='desktopSidesQr'>
                       <h3> Open on mobile </h3>
                       <Button href="/" className="qrlink">
                         <QRCode {...QrSettings} fgColor="#0b9101" value={`https://parrot.cards`} size={400} />
                       </Button>
                      </div>
                    </Box>)}
                    <WrapperCard key={item.key}>
                      <InitialScreen
                        error={error}
                        questionSetCode={questionSetCode}
                        setQuestionSetCode={(code: string) => {
                          setQuestionSetCode(code?.toUpperCase())
                          setError(null)
                        }}
                        handleStartClick={handleStartClick}
                        kiosk={props.kiosk}
                        desktopTableView={desktopTableView}
                      />
                    </WrapperCard>
                    {!isMobile && !desktopTableView && (<Box sx={{width: 412, display: "inline-block"}}>
                      <div className='desktopSides'>
                        <div>
                          <h2> Ask </h2>
                          <p>
                            Quick, Secure, and Accessible <br />
                            <br />
                            Fast Questions<br />
                            Fast Answers<br />
                            End-to-end encryption<br />
                            AI question creation.<br />
                            Automatic Data Policies<br />
                            Digital signatures.<br />
                            Multilingual, suitable for all users.<br />
                            All Phone Types<br />
                            Can write onto NFC cards
                          </p>
                        </div>
                      </div>
                      <div className='desktopSidesQr'>
                       <h3> Create Questions </h3>
                       <Button href="/ask" className="qrlink">
                         <QRCode {...QrSettings} fgColor="#c10c1e" value={`https://parrot.cards/ask`} size={400} />
                       </Button>
                      </div>
                    </Box>)}
                  </Box>
                )
              case "responder_question_set":
                /* TODO: refactor to separate file */
                return (
                  <Box className={"slide " + (isMobile ? (isIOS ? "respondingMobile iphone" : "respondingMobile "): "responding") + (desktopTableView ? "desktopTableView" : "" )} key={item.key} id={item.key}>
                    <div className='form'>
                    {!isMobile && (<QuestionSetDescriptionDesktop
                      style={{ padding: "40px", height: "100%" }}
                      isAnswering={true}
                      questionSet={{ ...questionSet, questionsList }}
                      noflip={true}
                    />)}
                    <FlipCard flip={flip} item={item} isIOS={isIOS}>
                      <CardContent sx={{ width: "100%", textAlign: "left", display: "flex", flexDirection: "column", height: "100%", overflowY: "auto", "-webkit-overflow-scrolling": "touch" }}>
                        {isMobile && (<Typography sx={{ color: "gray", mb: 2 }}>{questionSet?.id?.slice(-8)?.toUpperCase()}</Typography>)}
                        {isMobile && questionSet?.setterLogo && <Typography variant='h6'>
                          <img src={questionSet?.setterLogo} alt="setter logo" width="100" className='setterLogoInCard' />
                        </Typography>}
                        {isMobile && <Typography variant='h6'>
                          {questionSet?.setterName || ""}
                        </Typography>}
                        {isMobile && (<Typography variant='subtitle1' sx={{ mb: 2 }}>{questionSet?.name}</Typography>)}

                        <div className="cardList" style={{height: isMobile? "70vh":"75vh", overflowY: "auto"}}>
                          {(<Box sx={{ flexGrow: 1, display: flip ? "none" : "block" }}> {/* Inputs */} 
                            {questionSet?.fields.map(field => {
                              console.log("field #####", field)
                              const options = Object.values(field.options || {})
			      console.log("options", options)

                              // console.log(field.type, field.decrypted)
                              switch (field.type) {
                                case "select":
				  console.log("field select", field)
                                  return (
                                    <Box key={`field_${field.name}`} sx={{ display: "flex", mb: 2 }}
				      className={(field.required && isNullOrEmpty(field.decrypted)) ? "error" : "done"}
				    >
                                      <Autocomplete
                                        id={`field_${field.name}`}
                                        disablePortal
                                        freeSolo
                                        key={`select_field_${field.name}`}
                                        options={options}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params}
					  placeholder={field.required === false ? "optional" : ""}
					  value={field.decrypted}
					  label={field.label}
					  name={field.name}
                                          onChange={e => {handleFieldChange(null, field.name, e.target.value)}}
                                          onSelect={handleFieldChange}
                                          error={!((field.required !== false) || isNullOrEmpty(field.decrypted))}
					/>}
                                        value={field.decrypted}
                                      />
                                      {( isNullOrEmpty(field.decrypted) && field.required === true ) ? "" : <Box sx={{ ml: 1, display: "flex", alignItems: "center", color: "gray" }}>
                                        <CheckIcon fontSize="small" color="success" sx={{ mr: 2 }} />
                                       </Box>}
                                      <Box sx={{ ml: 1, display: "flex", alignItems: "center", color: "gray" }}>
                                        {field.action === "see" ? <VisibilityIcon fontSize="small" />
                                          : <SaveIcon fontSize="small" />}
                                      </Box>
				    </Box>
                                  )
                                case "image":
                                  return (
                                    <Box key={`field_${field.name}`}
                                      sx={{ mb: 2 }}
                                    >
                                      <Typography>{field.label}</Typography>
  
                                      <LoadImage
                                        error={(field.required !== false) && isNullOrEmpty(field.decrypted)}
                                        value={field.decrypted}
                                        setImage={(img: string) => {
                                          handleFieldChange(null, field.name, img)
                                        }} />
                                    </Box>

                                  )
                                case "rating":
                                  return (
                                    <Box key={`field_${field.name}`}
                                      sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
                                    >
                                      <Rating
                                        {...{ ...field, value: parseInt(field.decrypted) }}
                                        onChange={handleFieldChange}
                                        size="large"
                                      />
                                      {((isNullOrEmpty(field.decrypted))) ? "" : <InputAdornment position="end">
                                        <CheckIcon fontSize="small" color="success" sx={{mt:3.5}} />
                                      </InputAdornment>}
  
                                      <Box sx={{ ml: 1, display: "flex", alignItems: "center", color: "gray" }}>
                                        {field.action === "see" ? <VisibilityIcon fontSize="small" />
                                          : <SaveIcon fontSize="small" />}
                                      </Box>
                                    </Box>

                                  )
                                case "date":
                                  return (
                                    <Box key={`field_${field.name}`}
                                      sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
                                    >
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker className="date-picker"
                                          inputFormat='DD/MM/YYYY'
                                          label={field.label}
                                          value={field.decrypted}
                                          onChange={(date) => handleFieldChange(null, field.name, date)}
                                          renderInput={(params) => <TextField 
                                            {...params} 
                                            label={field.label}
                                            error={(field.required !== false) && isNullOrEmpty(field.decrypted)}
                                          />}
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                  )
                                default:
                                  return (
                                    <Box key={`field_${field.name}`}
                                      sx={{ display: "flex", mb: 2 }}
                                    >
                                      <CustomTextField
                                        fullWidth
                                        id={`field_${field.name}`}
  
                                        {...field}
                                        size="small"
                                        label={field.label}
  
                                        value={field.decrypted}
                                        onChange={handleFieldChange}
  
                                        error={(field.required !== false) && isNullOrEmpty(field.decrypted)}
                                        placeholder={field.required === false ? "optional" : ""}
  
                                        color="success"
  
                                        InputLabelProps={{
                                          style: { color: (field.required && isNullOrEmpty(field.decrypted)) ? theme.palette.error.main : theme.palette.success.main },
                                        }}
                                        sx={{ backgroundColor: "white" }}
  
                                        InputProps={{
                                          endAdornment: (( isNullOrEmpty(field.decrypted) )) ? "" : <InputAdornment position="end">
                                            <CheckIcon fontSize="small" color="success" sx={{mr:2}} />
                                          </InputAdornment>
                                        }}
  
                                      />

                                      <Box sx={{ ml: 1, display: "flex", alignItems: "center", color: "gray" }}>
                                        {field.action === "see" ? <VisibilityIcon fontSize="small" />
                                          : <SaveIcon fontSize="small" />}
                                      </Box>
                                    </Box>
                                  )
                                }
                              })}
                          </Box>)}
                        </div>
                        {(<Box> {/* input submit */}
                          <Box sx={{
                            mt: 1, mb: 0,
                            display: "flex", justifyContent: "space-between", alignItems: "flex-end", overlow: "hidden"
                          }}>
                            <IconButton aria-label="start question set" size="large" color="error"
                              onClick={handleCancelAnswering}
                            >
                              <CancelIcon sx={{ fontSize: 35 }} />
                            </IconButton>

                            <div className={classes.draggableBox} style={{marginTop:"-87px", height: '130px', width: '80px', position: 'relative', /* border: "none",  */padding: '0' }}>
                              {!answerComplete ? (
                                <div style={{ height: "130px", width: "57px", paddingBottom: 0 }}>
                                  <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%" }}>
                                    <Fab color="secondary" aria-label="flip card"
                                      disabled={true}
                                    >
                                      {/* <FlipIcon /> */}
                                      <img src={LockImage} width="11" />
                                    </Fab>
                                  </Box>
                                </div>
                              ) : (
                                <div style={{
                                  height: "130px", width: "57px", paddingBottom: 0, borderRadius: "0px 0px 45px 45px",
                                  background: "linear-gradient(0deg, rgba(140,229,139,.5) 0%, rgba(140,229,139,.5) 45%, rgba(33,247,232,0) 100%)"
                                }}>
                                  <div style={{ position: "absolute", left: 0, top: "30px", width: "57px", flex: "1", display: "flex", justifyContent: "center", color: "#4534159c" }}>
                                    <KeyboardDoubleArrowUpIcon fontSize="medium" />
                                  </div>
                                  <Draggable
                                    axis="y" bounds="parent"
                                    defaultPosition={INITIAL_POSITION}
                                    onDrag={handleDragButton}
                                    disabled={draggable.disabled}
                                    position={draggable.controlledPosition}
                                  >
                                    {/* <LoadingButton loading variant="outlined"> */}
                                    <Fab aria-label="show"
                                      sx={{
                                        color: "white",
                                        "&.MuiFab-root": { backgroundColor: TertiaryColor.main },
                                        "&.MuiFab-root:hover": { backgroundColor: TertiaryColor.dark }
                                      }}
                                    >
                                      <ChangeHistoryRoundedIcon />
                                    </Fab>
                                    {/* </LoadingButton> */}
                                  </Draggable>
                                </div>
                              )}

                            </div>

                            <Box sx={{ padding: "12px" }}>
                              {isMobile && (<Avatar sx={{ width: 35, height: 35, backgroundColor: theme.palette.secondary.main, cursor: "pointer" }}
                                onClick={() => { setFlip(!flip) }}
                              >
                                <FlipIcon sx={{ fontSize: 20 }} />
                              </Avatar>)}
                            </Box>
                          </Box>
                          {answerComplete && (
                            <Box sx={{ display: "flex", justifyContent: "center", mr: 2, mt: 1 }}>
                              <Typography variant="caption">
                                Swipe up to submit!
                              </Typography>
                            </Box>
                          )}
                        </Box>)}
                      </CardContent>

                      {/* Description - Flip == true */}
                      <QuestionSetDescription
                        isAnswering={true}
                        questionSet={{ ...questionSet, questionsList }}
                        flip={() => { setFlip(!flip) }}
                      />
                    </FlipCard>
                    {!isMobile && (<Box sx={{ width: 230, display: "inline-block", textAlign:"left"}}>
                      <div className='desktopSides'>
                        <div>
                          <h3> About </h3>
                          <p> {questionSet?.setterAbout || ""} </p>
                          <a href={'#' + questionSetCode} onClick={() => { setGDPROpen(true) }}>GDPR and Privacy Statement</a>
                          <GDPRDialog open={GDPROpen} questionSet={questionSet as QuestionSet} onClose={() => { setGDPROpen(false) }} />
                        </div>
                      </div>
                      <div className='desktopSidesQr'>
                       <h3> Create Questions </h3>
                       <Button href="/ask" className="qrlink">
                         <QRCode {...QrSettings} value={`https://parrot.cards/ask`}  fgColor="#c10c1e" size={400} />
                       </Button>
                      </div>
                    </Box>)}
                    </div>
                  </Box>
                )
              case "responder_saved_answer":
                return (
                  <Box className="slide" key={item.key} id={item.key}>
                    <FullSavedAnswer
                      key={item.key}
                      answer={{ ...item.answer, questionsList }}
                    />
                  </Box>
                )
            }
          })
        }
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
        <Box className="cardWidth"></Box>
      </Box>
      {/* <Breadcrumbs style={{ position: "absolute", bottom: 10, height: 35 }} color="primary" separator="-" aria-label="breadcrumb"
        sx={{
          "& li.MuiBreadcrumbs-separator": { ml: 0.5, mr: 0.5 }
        }}
      >
        <Box className={classes.breadcrumb} onClick={() => {
          setCarouselIndex(0)
          goToSlide("responder_home")
        }}>
          <HomeRoundedIcon fontSize="medium" />
        </Box>
        {carouselItems.length <= 4 && (
          carouselItems.map((item: CarouselItem, index: number) => {
            if (index === 0) return ""

            return (
              <Box key={item.key} className={classes.breadcrumb}>
                <CropPortraitRoundedIcon fontSize="medium" onClick={() => {
                  setCarouselIndex(index)
                  goToSlide(item.key)
                }} />
              </Box>
            )
          })
        )}
        {carouselItems.length > 4 && (<Box className={classes.breadcrumb}>
          <CropPortraitRoundedIcon fontSize="medium" onClick={() => {
            // const newIndex = carouselIndex ? carouselIndex - 1 : 0
            const newIndex = 1
            goToSlide(carouselItems[newIndex].key)
            setCarouselIndex(newIndex)
          }} />
        </Box>)}
        {carouselItems.length > 4 && (<Box className={classes.breadcrumb}>
          <MoreHorizOutlinedIcon sx={{ fontSize: 15 }} />
        </Box>)}
        {carouselItems.length > 4 && (<Box className={classes.breadcrumb}>
          <CropPortraitRoundedIcon fontSize="medium" onClick={() => {
            // const newIndex = carouselIndex !== carouselItems.length - 1 ? carouselIndex + 1 : carouselIndex
            const newIndex = carouselItems.length - 1
            goToSlide(carouselItems[newIndex].key)
            setCarouselIndex(newIndex)
          }} />
        </Box>)}
      </Breadcrumbs> */}
      {/*!desktopTableView && <CustomBreadcrumbs
        slides={carouselItems}
        activeIndex={carouselIndex}
        onClickHome={() => {
          setCarouselIndex(0)
          goToSlide("responder_home")
        }}
      /> */}

      <Snackbar open={snackAlertOpen !== false} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <SnackAlert onClose={handleCloseSnackbar} severity={snackAlertOpen.severity} sx={{ width: '100%' }}>
          {/* Answer submitted successfully! */}
          {snackAlertOpen.text}
        </SnackAlert>
      </Snackbar>

      <BigThankyou open={showingBigThankYou} onClose={() => { showBigThankYou(false) }} />

      <PinDialog open={pinDialogOpen} handleClose={() => { setPinDialogOpen(false) }} pinCodeContinue={(pinCode: string) => { onEnterPinCode(pinCode) }} />
      
      <NFCOptionDialog open={NFCOptionOpen} onClose={(answer) => { onCloseNFCOptionDialog(answer) }} />
    </Box >
  )
}
