const moment = require('moment');
export const DATE_FORMAT = "DD/MM/YYYY HH:mm:ss"
export const DATE_FORMAT_NO_SECONDS = "DD/MM/YYYY HH:mm"

export function formatDate(date: any, format: string = "") {
  if (format) {
    return moment(date).format(format)
  }
  if (typeof date === 'string') {
    return moment(date.replace("T00:00:00.000Z", "T12:00:00.000Z")).format(format)
  }
  return moment(date).format('DD/MM/YYYY')
}

export function parseDate(date: string, format: string = DATE_FORMAT) {
  const dateRet = moment(date, format)
  if (!dateRet.isValid()) {
    return moment(date, 'DD/MM/YYYY HH:mm:ss')
  }
  return dateRet
}
