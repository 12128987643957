import { SEA } from "../services/gun";
export type User = {
	id: string | null
	email: string | null
	displayName?: string | null
	photoURL?: string | null
	method?: string | null
}
type Pair = {
	pub: string
	epub: string
	priv: string
	epriv: string
}
export function setUser(user: User | null) {
	localStorage.setItem("user", JSON.stringify(user))
}

export function load(what: string, startAs: object) {
	return JSON.parse(localStorage.getItem(what) || JSON.stringify(startAs))
}

export function save(what: string, toSave: object) {
	localStorage.setItem(what, JSON.stringify(toSave))
}
export function getUser() {
	// let user = {
	//     id: "" as string | null,
	//     email: "" as string | null,
	//     displayName: "" as string | null,
	//     photoURL: "" as string | null,
	// }
	// return load( "user", user)
	const user = localStorage.getItem("user")
	return user ? JSON.parse(user) : null
}

export async function getPair(keys = "keys") {
	const keysAsJSON = localStorage.getItem(keys)
	if (keysAsJSON) {
		return JSON.parse(keysAsJSON)
	}
	const pair = await SEA.pair()
	localStorage.setItem(keys, JSON.stringify(pair))
	return pair as Pair
}

export async function getSetterPair(email: string, generateNew = true) {
	const name = "setterKeys"
	let keysAsJSON: any = localStorage.getItem(name)
	keysAsJSON = keysAsJSON ? JSON.parse(keysAsJSON) : {}

	if (keysAsJSON[email]) {
		return keysAsJSON[email]
	}

	if (!generateNew) return {}

	const pair = await SEA.pair()
	const updatedKeys = {
		...keysAsJSON,
		[email]: pair
	}
	localStorage.setItem(name, JSON.stringify(updatedKeys))
	return pair as Pair
}

export function setSetterPair(email: string, pair: Pair) {
	const name = "setterKeys"
	let keysAsJSON: any = localStorage.getItem(name)
	keysAsJSON = keysAsJSON ? JSON.parse(keysAsJSON) : {}

	const updatedKeys = {
		...keysAsJSON,
		[email]: pair
	}
	localStorage.setItem(name, JSON.stringify(updatedKeys))
	return pair as Pair
}

export function saveShowTime(value: string) {
	localStorage.setItem("DRY_Show_Time", value)
}

export function getShowTime(): string {
	return localStorage.getItem("DRY_Show_Time") || ""
}

export function loadTrail(): Record<string, any> {
	return JSON.parse(localStorage.getItem("DRY_Trail") || JSON.stringify({}))
}

export function saveTrail(id: string, encrypted: any) {
	const trail: Record<string, any> = loadTrail()
	trail[id] = encrypted
	localStorage.setItem("DRY_Trail", JSON.stringify(trail))
}