import { Button } from "@mui/material";
import React, { useState } from "react"
import Resizer from "react-image-file-resizer"
// import EXIF from "exif-js"

const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, 600, 600, "JPEG", 100,
      0,
      (uri) => {
        resolve(uri);
      }, "base64"
    );
  });
}
/* function getTrans(o) {
  if (o > 1 && o < 9) {
    const s = [
      { transform: "scale(-1, 1)" },
      { transform: "rotate(180deg)" },
      { transform: "scale(-1, 1) rotate(180deg)" },
      { transform: "rotate(90deg) scale(1, -1)" },
      { transform: "rotate(90deg)" },
      { transform: "rotate(-90deg) scale(1, -1)" },
      { transform: "rotate(-90deg)" },
    ]
    return s[o - 2]
  } else {
    return {}
  }
} */

export default function LoadImage(props: any) {
  const [theImage, setImage] = useState(props.value || "")
  // const [orientation, setOrientation] = useState(0)
  //  const trans = getTrans(orientation)
  const onChange = async (event: any) => {
    try {
      const file = event.target.files[0]
      /* EXIF.getData(file, function () {
        var exifData = EXIF.pretty(this); // eslint-disable-line
        if (exifData) {
          console.log(EXIF.getTag(this, "Orientation"))
          setOrientation(EXIF.getTag(this, "Orientation"))
        } else {
          console.log("No EXIF data found in image '" + file.name + "'.");
        }
      }); */
      const img = `${await resizeFile(file)}`
      setImage(img)
      props.setImage(img)
      console.log(props)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <label style={{ height: "70px", width: "100px", lineHeight: "70px", textAlign: "center", color: "white" }}>
      <input accept="image/*" type="file" onChange={onChange} hidden={true /* !!theImage */} />
      <Button
      variant="outlined" 
      color={props.error ? "error" : "success"}
        component="span"
        sx={{ display: theImage ? "none" : "block", width: "150px", mt: 1 }}
      >
        Choose Image
      </Button>
      <img src={theImage} style={{ maxWidth: "100px", maxHeight: "100px", display: !theImage ? "none" : "block", marginTop: 8 }} />
    </label>
  )
}
