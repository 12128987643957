import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Tooltip, MenuItem, Divider, Link,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert';
// import HomeIcon from '@mui/icons-material/Home';

import { auth, doc, fs, logOut, onAuthStateChanged, setDoc } from '../services/firebase';
import { getUser, setUser } from '../Helpers/localStorage';

import { FREE_PLAN, pricingTable } from '../containers/Ask/pricingTable';

import { VERSION } from "../version"

import theme from '../theme';
import makeStyles from '@mui/styles/makeStyles';
import { PermissionsEnum } from '../Helpers/permissions';
import { deleteDoc } from 'firebase/firestore';

const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    // minHeight: "100vh"
  },
  main: {
    // flex: "1 1 auto",
    display: "flex"
  },
  header: {
    flex: "0 1 auto"
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  initialsAvatar: {
    /* Center the content */
    alignItems: "center",
    display: "flex",
    justifyContent: "center",

    /* Colors */
    backgroundColor: theme.palette?.primary.light,
    color: "#000",

    /* Rounded border */
    borderRadius: "50%",
    height: "2rem",
    width: "2rem",
  },
  googlePhoto: {
    width: "2rem",
    borderRadius: "50%",
    height: "2rem",
    margin: "3px",
  }

}))

type PropsType = {
  children: React.ReactElement,
}

/* if (!user && !AUTH_NOT_REQUIRED.includes(window.location.pathname)) {
  console.log("logout")
  handleLogout()
} */

// const AUTH_NOT_REQUIRED = ["/ask/login", "/ask/signup", "/ask/forgot-password", "/ask/faq"]

function AskLayout(props: PropsType) {
  const classes = useStyles();
  const { children } = props;
  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [localUser] = useState(getUser())

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })

    return unsub
  }, [])

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorUserMenu(null);
  };

  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const handleClickConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
  };

  const handleConfirmDeleteAccount = async () => {
    setConfirmOpen(false);

    if (!auth.currentUser) return

    console.log("auth.currentUser", auth.currentUser)

    const docRef = doc(fs, "members", auth.currentUser.uid)
    await setDoc(docRef, { deleted: true }, { merge: true }) // soft delete, hard delete by admin function(deleteUser)

    handleLogout()
  }

  const handleLogout = () => {
    logOut()
    setUser(null)
    navigate("/")
  };

  const handleNavigate = (path: string) => {
    navigate(`/ask/${path}`);
    handleCloseMenu()
  }
  const teams = Object.keys(JSON.parse(localStorage.getItem("setterKeys") || "{}") || {})
  console.log("teams", teams)
  return (
    <Box className={classes.wrapper + " ask"}>
      <AppBar position="static" className="bar">
        <Container maxWidth="xl">
          <Toolbar disableGutters className={classes.toolbar}>
	    <h1>
              <a href="https://parrot.cards/ask" title="home">
                <img src="/parrot.svg" alt="Parrot logo" width="50px" /> <span>Parrot</span>
              </a>
	    </h1>
            <Box sx={{ flexGrow: 0, display: "flex" }}>
              {currentUser && <Box sx={{ mr: 1, display: "flex", cursor: "pointer" }} onClick={handleOpenUserMenu}>
                {currentUser?.photoURL ? (
                  <img src={currentUser?.photoURL}
                    alt="avatar" className={classes.googlePhoto} />
                ) : (currentUser?.displayName || currentUser?.email) ? (
                  <Box className={classes.initialsAvatar}>
                    {(currentUser?.displayName || currentUser?.email)?.charAt(0).toUpperCase() || "NA"}
                  </Box>
                ) : ""}
              </Box>}
              <Tooltip title="Open menu">
                <IconButton onClick={handleOpenMenu} >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-user"
              anchorEl={anchorUserMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorUserMenu)}
              onClose={handleCloseUserMenu}
            >
              {currentUser?.displayName &&
                <MenuItem sx={{ minHeight: 5, justifyContent: "center" }}>
                  <Typography textAlign="center" variant="subtitle2">{currentUser?.displayName}</Typography>
                </MenuItem>}
              {currentUser?.email &&
                <MenuItem sx={{ minHeight: 5, justifyContent: "center" }}>
                  <Typography textAlign="center" variant="caption">{currentUser?.email}</Typography>
                </MenuItem>}
              <Divider />
	      {teams.length > 1 ? teams.map((team) => (
		team === currentUser?.email ? <MenuItem key={team} onClick={() => { window.location.href = `/ask/` }}>
		  <Typography textAlign="left">Personal</Typography>
		</MenuItem>:
		<MenuItem key={team} onClick={() => { window.location.href = `/ask/?team=${team}` }}>
		  <Typography textAlign="left">Team: {team}</Typography>
		</MenuItem>
	      )):""}
              <MenuItem onClick={handleClickConfirmOpen}>
                <Typography textAlign="left">Delete account</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="left">Logout</Typography>
              </MenuItem>
            </Menu>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorMenu)}
              onClose={handleCloseMenu}
            >
              {currentUser &&
                <MenuItem onClick={() => { handleNavigate("plan") }}>
                  <Typography textAlign="left">Plan: <span style={{ fontSize: 14, backgroundColor: "#f6f0fd", padding: 6, borderRadius: 5, marginBottom: 5 }}>
                    {pricingTable[localUser?.plan || FREE_PLAN]?.name}
                  </span>
                  </Typography>
                </MenuItem>}
              {/* <MenuItem>
                <Typography textAlign="left" onClick={() => { console.log("tutorial") }}>Tutorial</Typography>
              </MenuItem> */}
              {currentUser &&
                <MenuItem onClick={() => { handleNavigate("profile") }}>
                  <Typography textAlign="left">Profile</Typography>
                </MenuItem>}
              {currentUser
                && localUser?.permissions?.includes(PermissionsEnum.ADMIN)
                && <MenuItem onClick={() => { handleNavigate("team") }}>
                  <Typography textAlign="left">Team</Typography>
                </MenuItem>}
              {currentUser &&
                <MenuItem onClick={() => { handleNavigate("archive") }}>
                  <Typography textAlign="left">Archive</Typography>
                </MenuItem>}
              {/* {user && <MenuItem> later
                <Typography textAlign="left" onClick={() => { console.log("Privacy Policy") }}>Your Privacy Policy</Typography>
              </MenuItem>} */}
              <MenuItem onClick={() => { handleNavigate("faq") }}>
                <Typography textAlign="left">FAQ</Typography>
              </MenuItem>
              <MenuItem onClick={() => { handleNavigate("showCodes") }}>
                <Typography textAlign="left">Show Codes</Typography>
              </MenuItem>
              <MenuItem component={Link} href="/">
                <Typography textAlign="left">Back to Answers</Typography>
              </MenuItem>
              <MenuItem component={Link} href="https://linktr.ee/talktoparrot" target="_blank">
                <Typography textAlign="left">Link Tree</Typography>
              </MenuItem>


              <MenuItem disabled>
                <Typography textAlign="left">{VERSION}</Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog
        open={confirmOpen}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign="center">
          Are you sure you want to delete your account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" textAlign="center">
            This action is irreversible and will result in the loss of access to all your question sets and answers.
            <br />
            If you choose to confirm, you'll be logged out automatically.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} autoFocus>Cancel</Button>
          <Button onClick={handleConfirmDeleteAccount}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Container maxWidth="xl" className={classes.main}>
        {children}
      </Container>

    </Box>
  );
}
export default AskLayout;
