import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import {addDoc, fs, collection} from './services/firebase';
import {timeStamp} from 'console';
function post(type: string, value: any){
  
  addDoc(collection(fs, "logs"), {
    type: type,
    ua: navigator.userAgent, 
    message: JSON.stringify(value),
    url: window.location.href,
    timeStamp: Date.now(),
  })
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if ("localhost" !== window.location.hostname) {
  var console = (function(oldCons){
    return {
      log: function(){
        oldCons.log.apply(null, arguments as any)
        // post("log", arguments)
      },
      info: function () {
        oldCons.info.apply(null, arguments as any)
        post("info", arguments)
      },
      warn: function () {
        oldCons.warn.apply(null, arguments as any)
        post("warn", arguments)
      },
      error: function () {
        oldCons.error.apply(null, arguments as any)
        post("error", arguments)
      }
    }
  }(window.console))
  window.console = console as any
}
