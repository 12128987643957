import { isMobile, isIOS } from 'react-device-detect';

export function maxHeight(minus: string): string {
		
	let height = 100
	if (window.matchMedia('(display-mode: fullscreen)').matches) {
		// PWA
		height = 100
	} else if (isMobile && isIOS) {
		height = 86
	} else if (isMobile) {
		height = 93
	}

	/* 
	https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d

	Use inner height in safari instead of vh

	window.innerHeight
	*/
	// console.log("height", isMobile, isIOS, height, minus)
	return `calc(${height}vh - ${minus})`
}
