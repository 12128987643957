import React, { useState, useEffect } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import defaultTheme from "./theme";

import AddDevice from "./containers/export"

import Home from './containers/Home';
import NotHome from './containers/Home';

import AnswerLayout from './Layouts/AnswerLayout';

import AskLayout from './Layouts/AskLayout';
import AskLogin from './containers/Ask/Login';
import AskGoogleOrEmail from './containers/Ask/GoogleOrEmail';
import AskSignup from './containers/Ask/SignUp';
import AskForgotPassword from './containers/Ask/ForgotPassword';
import AskProfile from './containers/Ask/Profile';
import AskHome from './containers/Ask/Home';
import AskTeam from './containers/Ask/Team';
import AskShowCodes from './containers/Ask/ShowCodes';
import SubscriptionPlans from './containers/Ask/SubscriptionPlans';
import AfterPayment from './containers/Ask/AfterPayment';
import AnswerTutorial from './containers/Help/AnswerTutorial';
import AnswerFAQ from './containers/Help/AnswerFAQ';
import AboutUs from './containers/Home/AboutUs';
import AskFAQ from './containers/Help/AskFAQ';
import ArchivedQuestionSets from './containers/Ask/ArchivedQuestionSets';
import CurrentPlan from './containers/Ask/CurrentPlan';

// import { getUser } from './Helpers/localStorage';
import { Box } from '@mui/material';
import { auth } from './services/firebase';
import { getUser } from './Helpers/localStorage';
import { isDesktop, isMobile } from 'react-device-detect';
import { detectIncognito } from "detectincognitojs";
import useMediaQuery from '@mui/material/useMediaQuery';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CookieBanner from './components/CookieBanner';
import CookiePolicy from './components/CookiePolicy';


const PrivateRoute = () => {
  // console.log("PrivateRoute", getUser())
  // getUser()
  // console.log("PrivateRoute", auth.currentUser, getUser())
  return (auth.currentUser || getUser()) ? <Outlet /> : <Navigate to={`/ask/login`} />
};

const AuthRoute = () => {
  // console.log("AuthRoute", getUser())
  // !getUser()
  // console.log("AuthRoute", auth.currentUser, getUser())
  return !(auth.currentUser || getUser()) ? <Outlet /> : <Navigate to={`/ask`} />
};

export function App(prob: any) {
  const [prev, setRefresh] = useState(false); // state to trigger re-render
  console.log("App", prob)
  if (prob.action === "reload") {
    setRefresh(!prev); // trigger re-render
  }
  // console.log("Orientation", isDesktop, isMobile, isTablet, window.innerWidth, window.outerWidth)
  const matches = useMediaQuery('(orientation: landscape) and (min-width:400px)');

  const [cookiePolicyOpen, setCookiePolicyOpen] = useState(false)
  const [isIncognito, setIsIncognito] = useState(false)

  // see if the browser is in incognito mode
  useEffect(() => {
    detectIncognito().then((result) => {
      console.log("isIncognito", result)
      setIsIncognito(result.isPrivate)
    });
  }, [])

  // save the hash part of the url
  useEffect(() => {
    console.log("hash", window.location.hash)
    if (window.location.hash) {
      localStorage.setItem("hash", window.location.hash)
    }
  }, [])

  // console.log("matches", matches)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(defaultTheme)}>
        <CssBaseline />
        {!isMobile && <CookieBanner onClickMore={() => { setCookiePolicyOpen(true) }} />}
        <div style={{width:"100%", display: "flex", justifyContent: "center" }}>
          <Box className="App" sx={{ maxWidth: { xs: "100%", sm: "100%" }, minWidth: { xs: "100%", sm: "100%" } }}>
            <Router>
              <Routes>
                {/* <Route path="/" element={<OrientationRoute />}> */}
                <Route path='/ask' element={<AuthRoute />}>
                  <Route path="/ask/login" element={<AskLayout><AskGoogleOrEmail /></AskLayout>} />
                  <Route path="/ask/email" element={<AskLayout><AskLogin /></AskLayout>} />
                  <Route path="/ask/signup" element={<AskLayout><AskSignup /></AskLayout>} />
                  <Route path="/ask/forgot-password" element={<AskLayout><AskForgotPassword /></AskLayout>} />
                </Route>

                <Route path='/ask' element={<PrivateRoute />}>
                  <Route path="/ask/team" element={<AskLayout><AskTeam /></AskLayout>} />
                  <Route path="/ask/after-payment" element={<AskLayout><AfterPayment /></AskLayout>} />
                  <Route path="/ask/after-payment-cancel" element={<AskLayout><AfterPayment /></AskLayout>} />
                  <Route path="/ask/plan" element={<AskLayout><CurrentPlan /></AskLayout>} />
                  <Route path="/ask/plans" element={<AskLayout><SubscriptionPlans /></AskLayout>} />
                  <Route path="/ask/profile" element={<AskLayout><AskProfile /></AskLayout>} />
                  <Route path="/ask/archive" element={<AskLayout><ArchivedQuestionSets /></AskLayout>} />
                  <Route path="/ask/showCodes" element={<AskLayout><AskShowCodes /></AskLayout>} />
                  <Route path="/ask" element={<AskLayout><AskHome /></AskLayout>} />
                </Route>
                <Route path="/ask/faq" element={<AskLayout><AskFAQ /></AskLayout>} />

                {/* TODO: delete route? */}
                <Route path="/adddevice" element={<AddDevice />} /> {/* copys keys to new device */}

                <Route path="/answer/faq" element={<AnswerLayout><AnswerFAQ /></AnswerLayout>} />
                <Route path="/answer/tutorial" element={<AnswerLayout><AnswerTutorial /></AnswerLayout>} />
                <Route exact-path path="/" element={<AnswerLayout><Home /></AnswerLayout>} />

                <Route exact-path path="/code" element={<AnswerLayout><Home /></AnswerLayout>} />
                <Route exact-path path="/home" element={<AnswerLayout><Home /></AnswerLayout>} />
                <Route exact-path path="/aboutus" element={<AnswerLayout><AboutUs /></AnswerLayout>} />
                <Route exact-path path="/kiosk" element={<AnswerLayout><Home kiosk={true} /></AnswerLayout>} />

                <Route path='/:code' element={<AnswerLayout><NotHome /></AnswerLayout>} />

                <Route path='*' element={<AnswerLayout><div>URL not found</div></AnswerLayout>} />

              </Routes>
            </Router>

            <Dialog
              open={!(isDesktop || !matches)}
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ fontSize: "1.25rem" }}>
                  Parrot does not support Landscape mode. Please rotate your device to Portrait mode.
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Dialog
              open={isIncognito}
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ fontSize: "1.25rem" }}>
                  Parrot does not work in Incognito mode or Private mode. Please disable Private mode to continue.
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Dialog
              open={cookiePolicyOpen}
              aria-describedby="cookie-pokicy-description"
              onClose={() => { setCookiePolicyOpen(false) }}
            >
              <DialogContent>
                <DialogContentText id="cookie-pokicy-description">
                  <CookiePolicy />
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </Box>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App;
