import React, { useEffect, useState } from 'react';

import {
  AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Tooltip, MenuItem, Link,
  Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SquareIcon from '@mui/icons-material/Square';

import { saveShowTime, getShowTime } from "../Helpers/localStorage"
import { useNavigate } from 'react-router';

import { VERSION } from "../version"
import { isMobile } from 'react-device-detect';

const showTimeOptions = {
  "1m": "1 minute",
  "10m": "10 minutes",
  "1h": "1 hour",
  "1d": "1 day",
}
const DEFAULT_SHOW_TIME = "1m"

const KioskWarningDialog = (props: any) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent sx={{ mx: 2, mb: 2, textAlign: "left" }}> 
        <Typography variant="body1" sx={{ mb: 2 }}>You are about to enter the Kiosk Mode of Parrot!</Typography>
        <br /><br />
        <Typography variant="body1" sx={{ mb: 2 }}>In order to exit Kiosk mode in Parrot and return to the regular mode, follow these steps:</Typography>
        <ol>
          <li>Close the current tab you are using in the Kiosk mode.</li>
          <li>Open a new tab in your web browser.</li>
          <li>Type in the URL parrot.cards and press Enter.</li>
          <li>This will take you back to Parrot without Kiosk mode enabled.</li>
        </ol>
        <br />
        <Typography variant="body1" sx={{ mb: 2 }}>Are you sure you wish to continue?</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={props.onClose}> Cancel </Button>
        <Button onClick={props.onContinue}> Continue </Button>
      </DialogActions>
    </Dialog>
  )
}

type PropsType = {
  children: React.ReactElement,
}

function ResponderLayout(props: PropsType) {
  const { children } = props;
  const navigate = useNavigate()
  const [showTime, setShowTime] = useState<string>(DEFAULT_SHOW_TIME)
  const [pathname, setPathname] = useState(window.location.pathname)
  const [warningOpen, setWarningOpen] = useState(false)

  useEffect(() => {
    setShowTime(getShowTime() || DEFAULT_SHOW_TIME)
  }, [])

  useEffect(() => {
    setPathname(window.location.pathname)
  }, [window.location.pathname])

  const changeShowTime = (value: string) => {
    setShowTime(value)
    saveShowTime(value)
  }

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <Box className={pathname === "/kiosk" ? "kiosk" : "responder"}>
      <AppBar position="static" className={isMobile ? "bar mobile": "bar"} >
        <Container maxWidth="xl">
          <Toolbar disableGutters style={{ display: "flex", justifyContent: "space-between", height: "66px" }}>
	    <h1>
              <a href="https://parrot.cards/" title="home">
                <img src="/parrot.svg" alt="Parrot logo" width="50px" /> <span className='hideSm'>Parrot</span>
              </a>
	    </h1>
           
            {pathname === "/kiosk" && <Box sx={{ borderRadius: "5px", padding: "10px 20px", backgroundColor: "crimson" }}>
              KIOSK
            </Box>}
            <Box sx={{ flexGrow: 0 }} className="menuRight">

              <Tooltip title="Open Menu">
                <IconButton onClick={handleOpenMenu} >
                  <MoreVertIcon  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorMenu)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={() => { navigate("/responder/tutorial"); handleCloseMenu() }}>
                  <Typography textAlign="left" >Tutorial</Typography>
                </MenuItem>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", p: "6px 16px" }}>
                  <Typography textAlign="left">Time to show card</Typography>

                  {Object.keys(showTimeOptions).map((key: string) => {
                    const value = showTimeOptions[key]
                    if (key === showTime) {
                      return (
                        <Typography
                          key={key}
                          component="span" textAlign="left" sx={{ paddingLeft: 0.25, cursor: "pointer" }}
                          onClick={() => changeShowTime(key)}
                        >
                          <SquareIcon sx={{ fontSize: 10 }} /> {value}
                        </Typography>
                      )
                    } else {
                      return (
                        <Typography
                          key={key}
                          component="span" textAlign="left" sx={{ paddingLeft: 2, cursor: "pointer" }}
                          onClick={() => changeShowTime(key)}
                        >
                          {value}
                        </Typography>
                      )
                    }
                  })}
                </Box>
                <MenuItem onClick={() => { navigate("/answer/faq"); handleCloseMenu() }}>
                  <Typography textAlign="left">FAQ</Typography>
                </MenuItem>
                <MenuItem component={Link} href="https://linktr.ee/talktoparrot" target="_blank">
                  <Typography textAlign="left">Link Tree</Typography>
                </MenuItem>
                <MenuItem component={Link} href="/ask/login">
                  <Typography textAlign="left">Login</Typography>
                </MenuItem>
                {
                  pathname === "/" && (
                    <MenuItem onClick={() => { setWarningOpen(true); handleCloseMenu() }}>
                      <Typography textAlign="left" >kioskMode</Typography>
                    </MenuItem>
                  )
                }
                <MenuItem disabled >
                  <Typography textAlign="left" title="version" >{VERSION}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        <KioskWarningDialog
          open={warningOpen}
          onClose={() => { setWarningOpen(false) }}
          onContinue={() => { setWarningOpen(false); navigate("/kiosk") }}
        />
      </AppBar>

      <Container maxWidth="xl">
        {children}
      </Container>
    </Box>
  );
}
export default ResponderLayout;
