import { initializeApp } from 'firebase/app'
import {
  getAuth, signInWithPopup, signOut, User, onAuthStateChanged,
  GoogleAuthProvider, 
  createUserWithEmailAndPassword, signInWithEmailAndPassword,
  sendEmailVerification, sendPasswordResetEmail,
} from "firebase/auth"
import { getFirestore, collection, doc, addDoc, setDoc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore"
import { ErrorCallback } from 'typescript'
// import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBHEDaEDeo7XU7ZlVjMLALBZ0wPmY2Nxsc",
  authDomain: "parrot.cards",
  projectId: "your-vault",
  storageBucket: "your-vault.appspot.com",
  messagingSenderId: "649899099002",
  appId: "1:649899099002:web:f309ef481ef18d4ba20cc3"
}
const provider = new GoogleAuthProvider()
provider.setCustomParameters({
  prompt: 'select_account'
});

const fbApp = initializeApp(firebaseConfig)

// const messaging = getMessaging(fbApp)

const fbStorage = getStorage(fbApp);

const auth = getAuth()

function logIn(){
  return signInWithPopup(auth, provider)
}

function logOut(){
  return signOut(auth)
}

/* Email/Password */
function signUpEmailPassword (email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password)
  /* .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  }); */
}

function sendDRYEmailVerification () {
  return sendEmailVerification(auth.currentUser as User)
}

function sendDRYPasswordResetEmail (email: string) {
  return sendPasswordResetEmail(auth, email)
}

function signInEmailPassword (email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password)
  /* .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  }); */
}

/* export const setupMessaging = function(cb:Function, msg:Function) {
//  console.log("Setup Messaging")
  
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.setBackgroundMessageHandler` handler.
  onMessage(messaging, (payload:any) => {
    console.log('Message received. ', payload)
    if (msg) {
      msg(payload.data)
    }
  })

    // [START get_token]
    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
     
  // [END get_token]

  function requestPermission() {
    console.log('Requesting permission...');
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === 'granted') {
  // //      console.log('Notification permission granted.')
  //       // TODO(developer): Retrieve an Instance ID token for use with FCM.
  //       // In many cases once an app has been granted notification permission,
  //       // it should update its UI reflecting this.
  //     } else {
  //       console.log('Unable to get permission to notify.')
  //     }
  //   })
  }
  requestPermission()
  getToken(messaging,{vapidKey:'BLvWK9g0z4myDjf9nmUtPWVSmmjod798siPUbtoFqeC9l-IVzBY5bU4Syy0WV8TAk8uYXlD-ZlEzsS3WoK8yb8w'}).then(currentToken => {
    if (currentToken) {
      if (cb) {
        cb(currentToken)
      }
    } else {
      // Show permission request.
      console.log('No Instance ID token available. Request permission to generate one.')
    }
  }).catch((err:ErrorCallback) => {
    console.error('An error occurred while retrieving token. ', err);
  });
} */

const fs = getFirestore()
export {
  auth, logIn, logOut, fs, onAuthStateChanged,
  collection, doc, setDoc, addDoc, getDoc, getDocs, orderBy, query, where,
  signUpEmailPassword, signInEmailPassword, sendDRYEmailVerification, sendDRYPasswordResetEmail,
  fbStorage, ref as fbStorageRef, uploadBytes, getDownloadURL, deleteObject as deleteStorageObject,
}
