import React from 'react'

import {
  Box,
  Button,
  Dialog, DialogContent, DialogTitle, IconButton, Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import makeStyles from '@mui/styles/makeStyles';
import { Field } from '../services/types';
import { formatDate } from '../Helpers/dates';
import MarkdownView from 'react-showdown';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 5,
  }
}))

// Doc: https://hi9io-my.sharepoint.com/:w:/g/personal/josh_hi9_io/EX5QN05Qq79MsF1KPx2DhYMBLy-KfJKJvkuZiI3S2F5GDg?rtime=ghhSypBb20g

export default function GDPRDialog(props: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open);
  const [piiFields, setPIIFields] = React.useState([])

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])


  console.log("GDPRDialog.tsx", props)
  React.useEffect(() => {
    if (props.questionSet) {
      const PIIfields = props.questionSet?.fields?.filter((field: Field) => {
        return (props.questionSet.questionsList?.find((f: Field) => f.name === field.name))?.isPII
      })
      // console.log("PIIfields", PIIfields)
      setPIIFields(PIIfields || [])
    } else {
      setPIIFields([])
    }
  }, [props.questionSet])

  const handleClose = () => {
    props.onClose()
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="gdpr-dialog-title"
      aria-describedby="gdpr-dialog-description"
    >
      <DialogTitle id="gdpr-dialog-title">
        {props.questionSet.setterName} - General Data Protection Regulation (GDPR) Privacy Policy
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 13,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {props.terms ? (<MarkdownView markdown={props.terms} />):(<>
          <Typography className={classes.sectionTitle}>1. Introduction</Typography>
          <Typography>
            At {props.questionSet.setterName}, We are committed to protecting and respecting your privacy. This policy explains when, why, and how we collect personal data from individuals who visit our website or interact with our services. We ensure to comply fully with the General Data Protection Regulation (EU) 2016/679 (GDPR) and applicable American data privacy regulations.
          </Typography>
  
          <Typography className={classes.sectionTitle}>2. The Data We Collect</Typography>
          <Typography>We may collect and process the following categories of personal information about you if it is presented in the question set, specifically these data points:</Typography>
          {
            piiFields.length === 0 ? (<ul>
              <li>None</li>
              </ul>) : (
              <ul>
                {piiFields.map((field: Field) => {
                  return <li key={field.name}> {field.label} </li> 
              })}
            </ul>
            )
          }

          <Typography className={classes.sectionTitle}>3. How We Use Your Data</Typography>
          <Typography>
            {props.questionSet.setterName} will use the personal data from {props.questionSet?.name} - {props.questionSet.id?.slice(0, 6)} collected to:
          </Typography>
          <ul>
            <li>Facilitate necessary business operations, which may include sharing your data with third-party service providers to deliver services and strictly under compliance with GDPR principles.</li>
          <li>Provide you with information about our services.</li>
            <li>
              Description: {props.questionSet.description}
            </li>
          </ul>
  
          <Typography className={classes.sectionTitle}>4. How We Collect Your Data</Typography>
          <Typography>We collect personal information directly from you via our \"Parrot\" tool. The \"Parrot\" tool is designed to collect only the required information, strictly following the principle of data minimisation under GDPR.</Typography>
  
          <Typography className={classes.sectionTitle}>5. Cookies and Similar Technologies</Typography>
          <Typography>Our website uses cookies and similar technologies for necessary site functionality, improving user experience, and tracking website performance. Details can be found in our Cookie Policy</Typography>
  
          <Typography className={classes.sectionTitle}>6. Data Storage, Protection and Retention</Typography>
          <Typography>We utilise end-to-end encryption to protect your data. All data stored is encrypted, ensuring that no third party, including Hi9 Ltd (The creators of Parrot), has the ability to decrypt it. Non-encrypted data is not stored using our Parrot tool. Your encrypted data is retained as long as necessary for our stated purposes, legal, accounting, or reporting requirements.</Typography>
  

          <Typography className={classes.sectionTitle}>7. Sharing and Disclosure</Typography>
          {!(props.questionSet?.sharedDataWith && props.questionSet?.sharedDataWith[0]) && (<Typography>
            {props.questionSet.setterName} will not share your personal data with third parties unless required by law, necessary for the purpose of the contract, or with your explicit consent. Any time data is shared with third parties, you will be able to find who the data was shared with, what data was shared, and when, on the Parrot app on your relevant response card.
          </Typography>)}

          {props.questionSet?.sharedDataWith && props.questionSet?.sharedDataWith[0] && (<Typography>
            {props.questionSet.setterName} will share your personal data with third parties as follows
            <ul>
              {props.questionSet?.sharedDataWith.map((sharedData: any) => {
                return <li key={sharedData.date}>{sharedData.name}</li>
              })}
            </ul>
          </Typography>)}
  
          <Typography className={classes.sectionTitle}>8. Your Rights Under GDPR</Typography>
          <Typography>Your Rights Under GDPR</Typography>
          <ul>
            <li>The right to be informed</li>
            <li>The right of access</li>
            <li>The right to rectification</li>
            <li>The right to erasure</li>
            <li>The right to restrict processing</li>
            <li>The right to data portability</li>
            <li>The right to object</li>
            <li>Rights in relation to automated decision making and profiling</li>
            <li>The right to withdraw consent</li>
          </ul>

          <Typography className={classes.sectionTitle}>9. Contact Us</Typography>
          <Typography> 
	    If you have any questions about this privacy policy or how we handle your personal information, please contact our Data Controller {props.questionSet.setterContactFullName || props.questionSet.setterName}{'\u00A0'}
	    at {props.questionSet.setterContactEmail}{'\u00A0'}
	    Please note that this privacy policy may be updated from time to time, in order to reflect changes in our practices or for other operational, legal, or regulatory reasons. <br /> <br />
            Last updated: {props.questionSet.created || formatDate(props.questionSet.timestamp)}
          </Typography>
        </>)}
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Button variant="text" onClick={handleClose}>Close</Button>
        </Box>

      </DialogContent>
    </Dialog>
  )
}
