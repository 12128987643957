import axios from 'axios'

export async function getPubKey(email: string) {
  const url = "https://europe-west2-your-vault.cloudfunctions.net/getPubKey"
  try {
    const response = await axios.post(url, {email}, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return response.data?.epubKey
  } catch (error: any) {
    console.error(error)
    throw new Error(error.response.data.message)
  }
}
export async function updateSubscription(data: any, token: string) {
  const url = "https://europe-west2-your-vault.cloudfunctions.net/updateSubscriptionV2"
  // const url = "http://127.0.0.1:5001/your-vault/europe-west2/updateSubscriptionV2"
  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    // console.log(response.data);
    return response.data
  } catch (error: any) {
    console.error(error)
    throw new Error(error.response.data.message)
  }
}

export async function connectSetterStripe(data: any, token: string) {
  const url = "https://europe-west2-your-vault.cloudfunctions.net/connectSetterStripeV2"
  // const url = "http://127.0.0.1:5001/your-vault/europe-west2/connectSetterStripeV2"
  console.log("url", url)
  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    console.log(response);
    return response.data
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response.data.message)
  }
}



export async function writeNFCData(data: any) {
  // const url = "https://fe38-2a02-c7c-e30f-7d00-995-4f75-5e7-61d1.ngrok-free.app/your-vault/europe-west2/writeNFCData"
  const url = "https://europe-west2-your-vault.cloudfunctions.net/writeNFCData"

  try {
    const response = await axios.post(url, data)
    console.log(response);
    return response.data
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response.data.message)
  }
}
export async function getNFCData(data: any) {
  const searchParams = new URLSearchParams(data);
  // const url = "http://127.0.0.1:5001/your-vault/europe-west2/getNFCData?" + searchParams
  // const url = "https://fe38-2a02-c7c-e30f-7d00-995-4f75-5e7-61d1.ngrok-free.app/your-vault/europe-west2/writeNFCData"
  const url = "https://europe-west2-your-vault.cloudfunctions.net/getNFCData?" + searchParams
  try {
    const response = await axios.get(url)
    console.log(response);
    return response.data
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response.data.message)
  }
}

export async function setterAuth(data: any, token: string) {

  // const url = "http://127.0.0.1:5001/your-vault/europe-west2/setterAuth"
  const url = "https://europe-west2-your-vault.cloudfunctions.net/setterAuth"

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    console.log(response);
    return response.data.data
  } catch (error: any) {
    console.error(error);
    throw new Error("setterAuth " + error.message)
  }
}
