import React from 'react'

import {
  Box,
  Button,
  Dialog, DialogContent, DialogTitle, IconButton, Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import makeStyles from '@mui/styles/makeStyles';
import { Field } from '../services/types';
import { formatDate } from '../Helpers/dates';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 5,
  }
}))

export default function AboutDialog(props: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open);
  const [piiFields, setPIIFields] = React.useState([])

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    if (props.questionSet) {
      const PIIfields = props.questionSet?.fields?.filter((field: Field) => {
        return (props.questionSet.questionsList?.find((f: Field) => f.name === field.name))?.isPII
      })
      // console.log("PIIfields", PIIfields)
      setPIIFields(PIIfields || [])
    } else {
      setPIIFields([])
    }
  }, [props.questionSet])

  const handleClose = () => {
    props.onClose()
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="gdpr-dialog-title"
      aria-describedby="gdpr-dialog-description"
    >
      <DialogTitle id="gdpr-dialog-title">
        About {props.questionSet.setterName}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 13,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          {props.questionSet?.setterLogo ? <img src={props.questionSet?.setterLogo} alt="Ask logo" width="100" /> : ""}
          {props.questionSet?.setterAbout ? <p>{props.questionSet?.setterAbout}</p> : ""}
          
          <p>Your contact <a href={'mailto:' + props.questionSet?.setterContactEmail}>{props.questionSet?.setterContactFullName}</a></p>
        </div>
      </DialogContent>
    </Dialog>
  )
}
