import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom"

import {
  Box, Card, CardContent, Button, Typography, TextField, InputAdornment, IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar,
} from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert';

import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import GoogleLogo from "../../images/google.svg"

import { doc, fs, getDoc, logIn, logOut, setDoc, signInEmailPassword } from '../../services/firebase'
import { getSetterPair, setSetterPair, setUser, getUser, load, save } from '../../Helpers/localStorage'

import { Setter } from '../../services/types'
import { maxHeight } from '../../Helpers/height'
import { setterAuth } from '../../services/api'
import { encryptKeys } from '../../Helpers/encryptionKeys'
import { SEA, hashValue } from '../../services/gun'

import makeStyles from '@mui/styles/makeStyles'
import themeConfig from '../../theme'
import { NewEncryptionCodesDialog } from '../../components/NewEncryptionCodesDialog';
const FREE_PLAN = 'free'
const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  mainCard: {
    margin: 10,
    marginTop: 15,
    display: "flex",
    boxShadow: "0px 2px 5px #888888",
    flexGrow: 1,
    height: maxHeight("100px"),
  },
  cardContent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "space-between",
    width: "100%"
  },
}))

const widthSx = { width: { xs: "90%", sm: 350, lg: 400 } }

const EncryptionCodeDialog = (props: any) => {
  const [code, setCode] = useState("")
  const [error, setError] = useState<boolean | string>(false)

  const onChange = (e) => {
    const value = e.target.value
    setError(false)
    setCode(value)
  }

  const onContinue = () => {
    if (code.trim() === "") {
      return setError("The code cannot be empty")
    }

    setCode("")
    props.onContinue(code)
  }

  return (
    <Dialog open={props.open !== false} onClose={props.onClose}>
      <DialogTitle>Login code</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are trying to login on a different device. Please input the login code to retrieve your encryption keys.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="code"
          label="Code"
          type="text"
          fullWidth
          variant="standard"

          value={code}
          onChange={onChange}

          error={error !== false}
          helperText={error || ""}

          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onContinue()
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={onContinue}>Continue</Button>
      </DialogActions>
    </Dialog>
  )
}

const SnackAlert = React.forwardRef<HTMLDivElement, AlertProps>(function alert(
  props,
  ref,
) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SetterLogin() {
  const classes = useStyles();

  const navigate = useNavigate();

  const [initial, setInitial] = useState(true)

  const [error, setError] = useState<string | null>(null)

  const [email, setEmail] = useState({
    value: "",
    error: false,
    helperText: ""
  })

  const [password, setPassword] = useState({
    value: "",
    error: false,
    helperText: ""
  })

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [loading, setLoading] = useState(false)
  const [codeDialogOpen, setCodeDialogOpen] = useState(false)
  const [snackAlertOpen, setSnackAlertOpen] = useState<any>(false)
  const user = getUser()
  const codes = load(`${user.email}-codes`, ["","",""])
  const [codesViewDialog, setCodesViewDialog] = useState({
    open: true,
    codes,
    firstTime: false,
  })

  const [authData, setAuthData] = useState<any>(null)

  const handleContinueEmailLogin = () => {

    if (email.value.trim() == "") {
      setEmail({
        ...email,
        error: true,
        helperText: "Email is required"
      })
      return
    }

    setInitial(false)
  }

  const handleEmailLogin = async () => {

    let error = false
    if (email.value.trim() == "") {
      setEmail({
        ...email,
        error: true,
        helperText: "Email is required"
      })
      error = true
    }

    if (password.value.trim() == "") {
      setPassword({
        ...password,
        error: true,
        helperText: "Password is required"
      })
      error = true
    }

    if (error) return

    try {
      setLoading(true)
      const result = await signInEmailPassword(email.value, password.value)
      // console.log("result", result)

      // Get data
      const token = await result?.user.getIdToken()
      // console.log("token", token)
      const authenticated = await setterAuth({}, token)
      console.log("authenticated", authenticated)
      const { plan, setterId, permissions } = authenticated

      // Save user data in local storage
      const { uid } = result?.user
      const user = {
        email: email.value,
        id: uid,
        method: "email",
        plan: plan || FREE_PLAN,
        setterId,
        memberId: uid,
        permissions,
      }

      // Get keys pair
      const pair = await getSetterPair(user?.email, false)

      console.log("pair", pair)
      console.log("pubKey", authenticated.pubKey)

      if (!authenticated.pubKey && pair.pub) {
        console.log("first time in the new system")
        // Generate codes if first time in the new system
        const { encryptedKeys, codes } = await encryptKeys(pair)

        // Update the setter 
        const docRef = doc(fs, "setters", user?.setterId)
        setDoc(docRef, { encryptedKeys, pubKey: pair.pub }, { merge: true })

        setLoading(false)
        // Setter in local storage
        setUser(user)

        // Show the codes to the user
        // const message = "To ensure a smooth login experience on various devices, please save the following codes as this is your initial login to the new system. These codes will be required for future logins on other devices."
        // alert(message + " " + codes.toString())
        setCodesViewDialog({
          open: true,
          firstTime: false,
          codes,
        })

      } else if (!pair.pub || pair.pub !== authenticated.pubKey) {
        console.log("user login on a different device")
        // Show a message, ask for the secret code
        setAuthData({
          authenticated,
          user,
        })
        setCodeDialogOpen(true)
        return
      } else {
        setLoading(false)
        // Setter in local storage
        setUser(user)

        // Redirect to ask home
        navigate("/ask")
      }
    } catch (error: any) {
      setLoading(false)
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("error", error)
      console.log("errorCode", errorCode)

      switch (errorCode) {
        case "auth/user-not-found":
          setError("Email address not linked to any user.")
          break
        case "auth/user-disabled":
          setError("Account disabled.")
          break
        case "auth/wrong-password":
          setError("The password is wrong.")
          break
        default:
          setError(errorCode + ". " + errorMessage)
          break
      }
    }
  }

  const handleGoogleAuth = async () => {
    setLoading(true)
    const result = await logIn()
    // console.log("result", result)

    const token = await result?.user.getIdToken()
    // console.log("token", token)
    const authenticated = await setterAuth({}, token)
    console.log("authenticated", authenticated)
    const { plan, setterId, permissions } = authenticated

    const { uid, email } = result?.user
    const user = {
      email,
      id: uid,
      method: "google",
      plan: plan || FREE_PLAN,
      setterId,
      memberId: authenticated.uid,
      permissions,
    }

    // Get keys pair
    let pair = await getSetterPair(user?.email!, false)

    console.log("pair", pair)
    console.log("pubKey", authenticated.pubKey)

    if (!authenticated.pubKey) {
      console.log("signup or first login to new system with google")
      const firstTime = !pair.pub

      if (firstTime) {
        pair = await getSetterPair(user?.email!)
      }

      // Generate codes if first time in the new system
      const { encryptedKeys, codes } = await encryptKeys(pair)

      // Update the setter 
      const docRef = doc(fs, "setters", user?.setterId)
      setDoc(docRef, { encryptedKeys, pubKey: pair.pub }, { merge: true })

      setLoading(false)
      // Setter in local storage
      setUser(user)


      // Save codes local
      save(`${user.email}-codes`, codes)
      // Show the codes to the user
      // alert(codes.toString())
      setCodesViewDialog({
        open: true,
        firstTime,
        codes,
      })
    } else if (!pair.pub || pair.pub !== authenticated.pubKey) {
      console.log("user login on a different device")
      // Show a message, ask for the secret code
      setAuthData({
        authenticated,
        user,
      })
      setCodeDialogOpen(true)
    } else if (pair.pub === authenticated.pubKey) {
      setLoading(false)
      // Setter in local storage
      setUser(user)
      // Redirect to ask home
      navigate("/ask")
    } else {
      logOut()
      setLoading(false)
      setError("Error")
    }
  }

  const handleForgotPassword = async () => {
    navigate("/ask/forgot-password")
  }

  const handleCloseCodeDialog = () => {
    setCodeDialogOpen(false);
    setLoading(false)
    logOut()
  }

  const handleContinueCodeDialog = async (code: string) => {
    setCodeDialogOpen(false);

    const { authenticated, user } = authData
    const codeHash = await hashValue(code)
    const found = authenticated.encryptedKeys?.find((keys: any) => keys.codeHash === codeHash)

    if (!found) {
      logOut()
      setLoading(false)
      setError("The code is incorrect")
      return
    }

    const decrypted = await SEA.decrypt(found.encrypted, code)
    console.log("decrypted", decrypted)
    setSnackAlertOpen({
      severity: "success",
      text: "We're getting your Team data!"
    })
    // Set the pair
    setSetterPair(user?.email, decrypted)

    // Setter in local storage
    setUser(user)

    // Redirect to ask home
    navigate("/ask")
  }

  const handleCloseViewCodesDialog = () => {
    const { firstTime } = codesViewDialog

    if (firstTime) {
      // Redirect to profile
      navigate("/ask/profile", {
        state: {
          firstTime,
        }
      })
    } else {
      // Redirect to /ask home
      navigate("/ask")
    }
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackAlertOpen(false);
  };

  return (
    <Box className={classes.wrapper}>
      <img src="/parrot.svg" alt="Parrot logo" width="150" /><br />
      <h1 className="logo">Parrot</h1>
      <h6 className="logo">
        Answer or Ask Once <br /> End Forms Forever
      </h6>

      <Card className={classes.mainCard}
        sx={{ ...widthSx }}
      >
        <CardContent className={classes.cardContent}>
          {initial ? (<Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant='h6' sx={{ fontWeight: "bold" }}>Login</Typography>

            {error && <Alert severity="error" sx={{ textAlign: "left" }}>{error}</Alert>}

            <TextField
              id="email" label="Email" variant="outlined" type="email" name="email"
              autoComplete="on"
              sx={{ mt: 2 }}

              {...email}
              onChange={(event) => { setEmail({ value: event.target.value, error: false, helperText: "" }) }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  // Do code here
                  event.preventDefault()
                  handleContinueEmailLogin()
                }
              }}
            />

            <Button
              id="continue-email-login"
              variant="contained" color="secondary" sx={{ mt: 2 }}
              onClick={handleContinueEmailLogin}
              disabled={loading}
            >
              Continue
            </Button>

            <Typography variant="caption" sx={{ mt: 2, mb: 1 }}>OR</Typography>

            <Button variant="contained" color="secondary" sx={{ mt: 1, backgroundColor: "#fde7c0", color: "black"/* , textTransform: 'none' */ }}
              onClick={handleGoogleAuth}
              disabled={loading}
            >
              <img src={GoogleLogo} alt="google log" width="20" style={{ marginRight: 10 }} />
              Continue with Google
            </Button>

            <Typography variant="subtitle2" sx={{ mt: 3, mb: 1, textAlign: "left" }}>
              Don't have an account? <Link to="/ask/signup" style={{ color: themeConfig.palette.secondary.main, textDecoration: "none" }}>Sign Up</Link>
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 3, mb: 1, color: themeConfig.palette.secondary.main, textAlign: "left" }}
              onClick={handleForgotPassword}
            >
              Forgot your password?
            </Typography>
          </Box>) : (
            <Box sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
              <Typography variant='h6' sx={{ fontWeight: "bold" }}>Login</Typography>

              {error && <Alert severity="error" sx={{ textAlign: "left" }}>{error}</Alert>}

              <Box component="form" noValidate autoComplete="on" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                <TextField
                  id="email" label="Email" variant="outlined" type="email" name="email"
                  autoComplete="on"
                  sx={{ mt: 2 }}

                  {...email}
                  onChange={(event) => {
                    setError(null)
                    setEmail({ value: event.target.value, error: false, helperText: "" })
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      // Do code here
                      event.preventDefault()
                      handleContinueEmailLogin()
                    }
                  }}
                />

                <TextField
                  id="password" label="Password" variant="outlined" name="password"
                  autoComplete="on"
                  type={showPassword ? "text" : "password"}
                  sx={{ mt: 2 }}

                  {...password}
                  onChange={(event) => {
                    setError(null)
                    setPassword({
                      value: event.target.value,
                      error: false,
                      helperText: ""
                    })
                  }}

                  InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleClickShowPassword}
                          size="small"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Button
                  variant="contained" color="secondary" sx={{ mt: 1 }}
                  id="button-email-login"
                  onClick={handleEmailLogin}
                  disabled={loading}
                >
                  Login
                </Button>

                <Typography variant="subtitle2" sx={{ mt: 3, mb: 1, color: themeConfig.palette.secondary.main, textAlign: "left" }}
                  onClick={handleForgotPassword}
                >
                  Forgot your password?
                </Typography>
              </Box>


              <Box sx={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  setInitial(true)
                  setPassword({
                    value: "",
                    error: false,
                    helperText: ""
                  })
                  setShowPassword(false)
                }}
              >
                <ArrowBackIcon sx={{ fontSize: 14, mr: 0.5 }} />
                <Typography variant="subtitle2">
                  Back to login
                </Typography>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>

      <NewEncryptionCodesDialog
        {...codesViewDialog}
        onClose={handleCloseViewCodesDialog}
      />

    </Box>
  )
}
