export const PermissionsEnum = {
	ADMIN: "admin",
	// READ_QS: "read_question_sets",
	CUD_QS: "create-update-delete_question_sets",
	READ_ANSWERS: "read_answers",
	SIGN_ANSWERS: "sign_answers",
	DOWNLOAD_ANSWERS: "download_answers",
	SHARE_ANSWERS: "share_answers",
};

export function checkPermission(requested: string, permissions: string[]) {
	if (!permissions || !permissions.length) return false

	if (permissions.includes(PermissionsEnum.ADMIN)) return true

	if (permissions.includes(requested)) return true

	return false
}