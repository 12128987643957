import React, { useState, useEffect } from "react"

import { Field } from "../../services/types"

import {
  Box, CardContent, Typography, Fab, Divider,
} from "@mui/material"

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import PreviewIcon from '@mui/icons-material/Preview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BusinessIcon from '@mui/icons-material/Business';
import DownloadIcon from '@mui/icons-material/Download';

import SignatureIcon from "../../images/sig purple.png"
import LockImage from "../../images/parrot.svg"

import Draggable from "react-draggable";
import { formatDate } from "../../Helpers/dates";
import ModalImage from "react-modal-image";
import { PermissionsEnum, checkPermission } from "../../Helpers/permissions";
import { getUser } from "../../Helpers/localStorage";

const INITIAL_POSITION = { x: 0, y: 74 }
const FINAL_POSITION = { x: 0, y: 0 }

export default function SeeAnswer(props: any) {

  const [draggable, setDraggable] = useState({
    initialPositions: INITIAL_POSITION,
    controlledPosition: INITIAL_POSITION,
    deltaPosition: INITIAL_POSITION,

    finalPositions: FINAL_POSITION,

    disabled: false,
  })
  const [showFullHistory, setShowFullHistory] = useState(false)

  const [user] = useState(getUser())

  useEffect(() => {
    const initial = props.answer.signed ? FINAL_POSITION : INITIAL_POSITION
    const final = props.answer.signed ? INITIAL_POSITION : FINAL_POSITION

    animateDraggableButton(props.answer.signed ? -5 : 5, initial, final)

    console.log("answer", props.answer)
  }, [])

  useEffect(() => {
    const initial = props.answer.signed ? FINAL_POSITION : INITIAL_POSITION
    const final = props.answer.signed ? INITIAL_POSITION : FINAL_POSITION

    setDraggable({
      ...draggable,
      initialPositions: initial,
      deltaPosition: initial,
      controlledPosition: initial,
      finalPositions: final,
      disabled: false,
    })

  }, [props.answer.signed])

  function animateDraggableButton(yStep: number = 5, initialPosition: any, finalPosition: any) {
    const { x, y } = initialPosition
    // console.log(initialPosition)

    let timeout = 100
    // const yStep = 5
    const timeStep = 50

    const setTimeoutPosition = (offset: number, timeout: number) => {
      setTimeout(function () {
        setDraggable({
          ...draggable,
          initialPositions: initialPosition,
          finalPositions: finalPosition,
          disabled: false,
          deltaPosition: {
            x: x,
            y: y - offset,
          },
          controlledPosition: {
            x: x,
            y: y - offset,
          },
        })
      }, timeout);
    }

    for (let i = 1; i <= 3; i++) {
      setTimeoutPosition(i * yStep, timeout)
      timeout += timeStep
    }
    timeout += timeStep
    for (let i = 2; i >= 0; i--) {
      setTimeoutPosition(i * yStep, timeout)
      timeout += timeStep
    }

    for (let i = 1; i <= 3; i++) {
      setTimeoutPosition(i * yStep, timeout)
      timeout += timeStep
    }
    timeout += timeStep
    for (let i = 2; i >= 0; i--) {
      setTimeoutPosition(i * yStep, timeout)
      timeout += timeStep
    }
  }

  const handleDragButton = (e: any, ui: any) => {
    const { x, y } = draggable.deltaPosition;
    // console.log("draggable", draggable)

    if (x >= draggable.finalPositions.x && y == draggable.finalPositions.y) return

    const newX = x + ui.deltaX
    const newY = y + ui.deltaY
    // console.log("x,y", newX, newY)

    if (newX >= draggable.finalPositions.x && newY == draggable.finalPositions.y) {
      // console.log("done")

      setDraggable({
        ...draggable,
        disabled: true,
        deltaPosition: {
          x: newX,
          y: newY,
        },
        controlledPosition: {
          x: ui.x,
          y: ui.y,
        },
      })

      if (!props.answer.signed) {
        console.log("sign")
        props.signCard()
      } else {
        console.log("revoke")
        props.revokeSignature()
      }

    } else {
      setDraggable({
        ...draggable,
        deltaPosition: {
          x: newX,
          y: newY,
        },
        controlledPosition: {
          x: ui.x,
          y: ui.y,
        },
      });
    }

  };

  function Signature(props: any) {
    const { signature } = props
    // console.log("signature", signature)

    const date = formatDate(signature.date)
    switch (signature.action) {
      case "see":
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <PreviewIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Seen by {signature.user} - {date}</Typography>
          </Box>
        )
      case "sign":
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <img src={SignatureIcon} width="18" style={{ marginRight: 10 }} />
            <Typography>Signed by {signature.user} - {date}</Typography>
          </Box>
        )
      case "revoke":
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <RemoveCircleRoundedIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Revoked by {signature.user} - {date}</Typography>
          </Box>
        )
      case "download":
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <DownloadIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Downloaded by {signature.user} - {date}</Typography>
          </Box>
        )
      case "third-party":
        const thirdPartyName = signature.thirdPartyName
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <BusinessIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Shared with {thirdPartyName} by {signature.user} - {date}</Typography>
          </Box>
        )
      default:
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <Typography>Action by {signature.user} - {date}</Typography>
          </Box>
        )
    }
  }

  return (
    <CardContent sx={{
      width: "100%", display: "flex", flexDirection: "column", textAlign: "left",
      overflowY: "scroll", "-webkit-overflow-scrolling": "touch",
      height: "auto", minHeight: "100% !important"
    }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h6" sx={{ mt: 0, mb: 0, color: "gray" }}>{props.answer.shortId}</Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {props.answer.signed && <img src={SignatureIcon} width="35" />}
          {props.answer.available ? <VisibilityRoundedIcon fontSize="large" color="primary" sx={{ ml: 1 }} />
            : props.answer.saved ? <SaveIcon fontSize="large" color="primary" sx={{ ml: 1 }} />
              : <VisibilityOffRoundedIcon fontSize="large" color="primary" sx={{ ml: 1 }} />}
        </Box>
      </Box>
      <Typography variant="h6" sx={{ mt: 0, mb: 2 }}>{props.answer.title}</Typography>

      {props.answer.signatures?.length !== 0 && <Box sx={{ mt: 1.5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Signature signature={props.answer.signatures.slice(-1)[0]} />
          {props.answer.signatures?.length > 1 && <UnfoldMoreIcon onClick={() => { setShowFullHistory(!showFullHistory) }} />}
        </Box>
        {showFullHistory && props.answer.signatures?.slice().reverse().slice(1).map(signature => {
          return <Signature key={signature.date} signature={signature} />
        })}
      </Box>}

      <Divider sx={{ my: 1 }} />
      <Box sx={{ flexGrow: 1, overflowY: "auto", "-webkit-overflow-scrolling": "touch", my: 1 }}>
        {(props.answer.available || props.answer.saved) && props.answer?.fields.map((field: Field) => {
	  console.log("field", field)
          return (
            <Box key={field.name} sx={{ display: "flex" }}>
              <Box sx={{ mr: 1, display: "flex", alignItems: "center", color: "gray", pb: 0.5 }}>
                {field.action === "see" ? <VisibilityIcon sx={{ fontSize: 18 }} />
                  : <SaveIcon sx={{ fontSize: 18 }} />}
              </Box>
              <Typography variant="subtitle1" sx={{ m: 0.25, display: "flex", flexDirection: field.type === "image" ? "column" : "rows" }}>
                <span className="label">{field.label} &nbsp; </span>
              </Typography>
              <Typography sx={{ fontWeight: 300, m: 0.25, display: "flex", flexDirection: field.type === "image" ? "column" : "rows" }}>
                {field.type === "image" ? (
                  <ModalImage
                    hideDownload={true}
                    large={field.decrypted}
                    small={field.decrypted}
                  />
                ) : field.type === "date" ? formatDate(field.decrypted, "DD/MM/YYYY") : field.decrypted}
              </Typography>
            </Box>
          )
        })}
      </Box>

      {checkPermission(PermissionsEnum.SIGN_ANSWERS, user?.permissions) && <Box sx={{
        mt: 1, mb: 0,
        display: "flex", justifyContent: "space-between", alignItems: "flex-end", overlow: "hidden"
      }}>
        <ArrowBackIcon
          sx={{ fontSize: 30, cursor: "pointer" }}
          aria-label="return to list of answers"
          onClick={props.stopShowing}
        />

        <div style={{ float: "left", height: '130px', width: '80px', position: 'relative', padding: '0' }}>

          <div style={{
            height: "130px", width: "57px", paddingBottom: 0, borderRadius: !props.answer.signed ? "0px 0px 45px 45px" : "45px 45px 0px 0px",
            background: `linear-gradient(${!props.answer.signed ? 0 : 180}deg, rgba(255,229,183,1) 0%, rgba(255,229,183,1) 45%, rgba(255,247,232,0) 100%)`
          }}>
            {!props.answer.signed ? <div style={{ position: "absolute", left: 0, top: "30px", width: "57px", flex: "1", display: "flex", justifyContent: "center", color: "#4534159c" }}>
              <KeyboardDoubleArrowUpIcon fontSize="medium" />
            </div> : <div style={{ position: "absolute", left: 0, top: "70px", width: "57px", flex: "1", display: "flex", justifyContent: "center", color: "#4534159c" }}>
              <KeyboardDoubleArrowDownRoundedIcon fontSize="medium" />
            </div>}
            <Draggable
              axis="y" bounds="parent"
              defaultPosition={draggable.initialPositions}
              onDrag={handleDragButton}
              disabled={draggable.disabled}
              position={draggable.controlledPosition}
            >
              <Fab color="secondary" aria-label="show">
                <img src={SignatureIcon} width="22" />
              </Fab>
            </Draggable>
          </div>

        </div>

        <Box sx={{ minWidth: 30 }}></Box>
      </Box>}

      {checkPermission(PermissionsEnum.SIGN_ANSWERS, user?.permissions) ? <Box sx={{ display: "flex", justifyContent: "center", ml: 2, mt: 1 }}>
        <Typography variant="caption">
          {!props.answer.signed ? "Slide up to sign the card!" : "Slide down to revoke the signature!"}
        </Typography>
      </Box> : <ArrowBackIcon
        sx={{ fontSize: 30, cursor: "pointer" }}
        aria-label="return to list of answers"
        onClick={props.stopShowing}
      />}

    </CardContent>
  )
}
