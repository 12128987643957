import React, { useEffect, useState } from "react"

import {
  Box, CardContent, Typography, Fab, IconButton,
} from "@mui/material"

import FlipIcon from "@mui/icons-material/Flip";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import SignatureIcon from "../../images/sig purple.png"
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import BusinessIcon from '@mui/icons-material/Business';
import DownloadIcon from '@mui/icons-material/Download';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

import makeStyles from "@mui/styles/makeStyles";
import { Field } from "../../services/types";

import { fs, collection, getDocs, orderBy, query } from "../../services/firebase";
import { formatDate } from "../../Helpers/dates";
import { onSnapshot } from "@firebase/firestore";
import ModalImage from "react-modal-image";
import { QuestionSetShare} from "../Ask/SavedQuestionSet"

const useStyles = makeStyles(_ => ({
  cardContent: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    height: "100%",
  },
  topWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  shareWrapper: {
    marginTop: 1,
    marginBottom: 0,
    display: "flex",
    justifyContent: "right",
    alignItems: "flex-end",
    overlow: "hidden"
  },
  fabWrapper: {
    marginTop: 1,
    marginBottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    overlow: "hidden"
  }
}))
function toDate(date: string) {
  if (typeof date !== "string") return ""
  if (date && date.indexOf("T") > 2) {
    return date.split("T")[0]
  }
  if (!date) {
    return ""
  }
  if (date.trim()) {
    return new Date(date).toLocaleDateString(navigator.language) 
  }
  return ""
}
export default function SavedAnswers(props: any) {
  console.log("SavedAnswers props", props)
  const classes = useStyles();

  const [history, setHistory] = useState<any[]>([])
  const [showFullHistory, setShowFullHistory] = useState(false)

  const [signed, setSigned] = useState(false)

  useEffect(() => {
    const q = query(collection(fs, "cardAuditions", props.answers?.id, "signatures"), orderBy('date', "desc"))
    const unsub = onSnapshot(q, (querySnapshot) => {
      let localHistory: any[] = [];
      querySnapshot.forEach((doc) => {
        localHistory.push(doc.data())
      });

      setHistory(localHistory)

      const onlySignRevoke = localHistory.filter((h) => h.action === "sign" || h.action === "revoke") || []
      if (onlySignRevoke.length) {
        setSigned(onlySignRevoke[0].action === "sign")
     }
    }, (error: any) => {
      console.error("Error fetching history: ", error);
    });

    return unsub
  }, [])

  function Signature(props: any) {
    const { signature } = props

    const date = formatDate(signature.date)
    switch (signature.action) {
      case "see":
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <PreviewIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Seen : {date}</Typography>
          </Box>
        )
      case "sign":
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <img src={SignatureIcon} width="18" style={{ marginRight: 10 }} />
            <Typography>Signed : {date}</Typography>
          </Box>
        )
      case "revoke":
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <RemoveCircleRoundedIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Revoked : {date}</Typography>
          </Box>
        )
      case "download":
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <DownloadIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Downloaded : {date}</Typography>
          </Box>
        )
      case "third-party":
        const thirdPartyName = signature.thirdPartyName
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <BusinessIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography>Shared with {thirdPartyName} : {date}</Typography>
          </Box>
        )
      default:
        return (
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <Typography>Action : {date}</Typography>
          </Box>
        )
    }
  }
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  console.log("props.answers", props)
  return (
    <CardContent className={classes.cardContent}>
      <Box className={classes.topWrapper}>
        {/* TODO: also show the QS code */}
        <Typography sx={{ color: "#4b4949" }}>{props.answers.id?.slice(0, 6)} - {props.answers.id?.slice(-8)?.toUpperCase()}</Typography>
        <Box>
          {signed && <img src={SignatureIcon} width="24" style={{ marginRight: 10 }} />}
          {history.find(h => h.action === "see") ? <VisibilityOffRoundedIcon fontSize="medium" color="primary" /> :
            <VisibilityRoundedIcon fontSize="medium" color="primary" />}
        </Box>
      </Box>
      {props.answers?.askLogo ? <img src={props.answers?.askLogo} alt="Ask logo" width="100" /> : ""}
      <Typography variant="h6">
        {props.answers?.setterName || props.answers?.org || ""}
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 0 }}>{props.answers?.title}</Typography>

      {history.length !== 0 && <Box sx={{ mt: 1.5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Signature signature={history[0]} />
          {history.length > 1 && <UnfoldMoreIcon onClick={() => { setShowFullHistory(!showFullHistory) }} />}
        </Box>
        {showFullHistory && history.slice(1).map(signature => {
          return <Signature key={signature.date} signature={signature} />
        })}
      </Box>}

      <Box sx={{ flexGrow: 1, my: 1.5, overflowY: "auto", "-webkit-overflow-scrolling": "touch" }}>
        {props.answers?.fields.map((field: Field) => {
          return (
            <Box key={field.name} sx={{ display: "flex" }}>
              <Box sx={{ mr: 1, display: "flex", alignItems: "center", color: "gray", pb: "3px" }}>
                {field.action === "see" ? <VisibilityIcon sx={{ fontSize: 18 }} />
                  : <SaveIcon sx={{ fontSize: 18 }} />}
              </Box>
              <div className={field.type === "image" ? "saved-answers-image" : "saved-answers"}>
                <div className="saved-answer-label"> {field.label} </div>
                <div className="saved-answer"> {field.type === "image" ? (
                  <ModalImage
                    hideDownload={true}
                    large={field.decrypted}
                    small={field.decrypted}
                  />
                ) : field.type === "date" ? toDate(field.decrypted) : field.decrypted} </div>
              </div>
            </Box>
          )
        })}
      </Box>

      {/* <Typography variant="subtitle1" sx={{ mb: 2 }}>Answer on: {formatDate(props.answers?.timestamp)}</Typography> */}
      <QuestionSetShare
        open={shareDialogOpen}
        id={props.answers.id.slice(0, 6)}
        name={props.answers.title}
        handleClose={() => { setShareDialogOpen(false) }}
      />
      <Box className={classes.shareWrapper}>
        <IconButton sx={{ p: 0 }} onClick={() => { setShareDialogOpen(true) }}>
          <ShareOutlinedIcon fontSize="medium" sx={{ color: "#0b9101" }} />
        </IconButton>
      </Box>
      <Box className={classes.fabWrapper}>
        <Fab color="secondary" aria-label="flip card"
          onClick={() => { props.flip() }}
        >
          <FlipIcon />
        </Fab>
      </Box>
    </CardContent>
  )
}
