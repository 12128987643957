import React, { useState, useEffect, SyntheticEvent } from 'react'
import { useNavigate, useLocation, Location } from "react-router-dom";

import {
  Box, Button, Typography, TextField,
} from '@mui/material'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { fbStorage, fbStorageRef, getDownloadURL, deleteStorageObject, uploadBytes, fs, doc, setDoc, getDoc, auth, logOut } from '../../services/firebase'

import makeStyles from '@mui/styles/makeStyles';
import { getUser, setUser } from '../../Helpers/localStorage';
import { PermissionsEnum } from '../../Helpers/permissions';
import Resizer from "react-image-file-resizer"
import FlipCard from '../../components/FlipCard';
import { isIOS, isMobile } from "react-device-detect";

const useStyles = makeStyles(_ => ({
  wrapper: {
    width: "100%",
    padding: "0 20px",
  },
}))

const SnackAlert = React.forwardRef<HTMLDivElement, AlertProps>(function SnackAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DEFAULT_FIELD_STATE = {
  value: "",
  error: false,
  helperText: ""
}

export default function SetterProfile() {
  const classes = useStyles();

  const navigate = useNavigate();
  const location: Location = useLocation();

  const [error, setError] = useState<string | null>(null)

  const [firstTime, setFirstime] = useState(false);
  const [user] = useState(getUser())

  const [logo, setLogo] = useState<any>(null);
  const [terms, setTerms] = useState<any>(null);

  const [organisationName, setOrganisationName] = useState(DEFAULT_FIELD_STATE)
  const [contactName, setContactName] = useState(DEFAULT_FIELD_STATE)
  const [contactSurname, setContactSurname] = useState(DEFAULT_FIELD_STATE)
  const [contactEmail, setContactEmail] = useState(DEFAULT_FIELD_STATE)
  const [website, setWebsite] = useState("")
  const [about, setAbout] = useState("")

  const [snackbar, setSnackbar] = useState(false)
  const [timer, setTimer] = useState<any>(0)
  const [loading, setLoading] = useState(false)
  const [flip, setFlip] = useState(false)
  
  function getProfile(setterUrlRaw: string) {
    const setterUrl = window.encodeURIComponent(setterUrlRaw)
    if (!about) {
      clearTimeout(timer)
      setTimer(setTimeout(() => {
        const url = `https://europe-west2-your-vault.cloudfunctions.net/extract_logo_description_v1?url=${setterUrl}`
        fetch(url).then(res => res.json()).then(res => {
          if (res.result && res.result.description) {
            const got = res.result
            setAbout(got.description)
            if (got.logo) setLogo(got.logo)
          } else {
            console.log("No results found")
          }
        }).catch(err => console.log(err))
      }, 1000))
    } 
  }
  useEffect(() => {
    if (!user) {
      logOut()
      setUser(null)
      navigate("/ask/login")
    }
  }, [])

  useEffect(() => {
    if (location && location.state) {
      const firstTime = (location.state as any).firstTime || false

      setFirstime(firstTime)
    }

  }, [location])

  useEffect(() => {
    if (!firstTime && user) {
      // Get date from firestore
      loadProfile(user?.setterId)
    }

  }, [firstTime, user])

  async function loadProfile(setterId: string) {
    const docRef = doc(fs, "setters", setterId)
    const setter = (await getDoc(docRef))?.data()
    // console.log("setter", setter)

    if (setter) {
      setLogo(setter.logo || "")
      setTerms(setter.terms || "")
      setOrganisationName({
        ...organisationName,
        value: setter.organisationName || "",
      })
      setContactName({
        ...contactName,
        value: setter.contactName || "",
      })
      setContactSurname({
        ...contactSurname,
        value: setter.contactSurname || "",
      })
      setContactEmail({
        ...contactEmail,
        value: setter.contactEmail || "",
      })
      setWebsite(setter.website || "")
      setAbout  (setter.about   || "")
    }
  }

  const handleFileUpload = (event: any) => {
    console.log(event.target.files)

    if (!user) return

    if (event.target.files && event.target.files.length) {
      setError(null)
      Resizer.imageFileResizer(
        event.target.files[0],
        300,
        300,
        "WEBP",
        100,
        0,
        (file) => {
          if (!file || typeof file === "string") return
          const fileName = `${Date.now()}.`
          const filePath = `/SettersLogos/${fileName}`

          const storageRef = fbStorageRef(fbStorage, filePath)
          uploadBytes(storageRef, file as File).then((snapshot) => {
            console.log('Uploaded a blob or file!', snapshot)
            // upload complete
            console.log("upload complete")
            // download url
            getDownloadURL(snapshot.ref).then((url: string) => {
              // console.log(url);
              // Set state
              setLogo(url)
     
              // Also update logo in firebase
              const docRef = doc(fs, "setters", user?.setterId)
              setDoc(docRef, { logo: url }, { merge: true })
            });
          }).catch((error) => {
            console.log("error", error)
            setError(error.message)
          })
        },
        "file",
        200,
        200
      );
    }
  }
  const handleUploadTerms = (event: any) => {
    if (event.target.files && event.target.files.length) {
      setError(null)
      const file = event.target.files[0]
      if (!file) return
      var reader = new FileReader();
      reader.onload = function () {
	setTerms(reader.result)
	console.log(reader.result)
      };
      // This does not return the text. It just starts reading.
      // The onload handler is triggered when it is done and the result is available.
      reader.readAsText(file);
    }
  }


  const handleDeleteLogo = () => {
    try {
      // Don't delete from storage in case its previously used in question sets
      // deleteStorageObject(fbStorageRef(fbStorage, logo))
      setLogo(null)

      // Also update logo in firebase
      const docRef = doc(fs, "setters", user?.setterId)
      setDoc(docRef, { logo: null }, { merge: true })
    } catch (error: any) {
      const errorMessage = error.message;
      setError(errorMessage)
    }
  }

  const handleSaveProfile = () => {
    let error = false
    if (organisationName.value.trim() === "") {
      setOrganisationName({
        ...organisationName,
        error: true,
        helperText: "The organisation name is required"
      })
      error = true
    }
    if (contactEmail.value.trim() === "") {
      setContactEmail({
        ...contactEmail,
        error: true,
        helperText: "The contact email is required"
      })
      error = true
    }
    if (contactName.value.trim() === "") {
      setContactName({
        ...contactName,
        error: true,
        helperText: "The contact first name is required"
      })
      error = true
    }
    if (contactSurname.value.trim() === "") {
      setContactSurname({
        ...contactSurname,
        error: true,
        helperText: "The contact last name is required"
      })
      error = true
    }

    if (error) return

    console.log(terms)

    // Save to firestore
    const profile = {
      organisationName: organisationName.value,
      logo,
      terms,
      contactEmail: contactEmail.value,
      contactName: contactName.value,
      contactSurname: contactSurname.value,
      website,
      about,
    }
    console.log(user)

    const docRef = doc(fs, "setters", user?.setterId)
    setDoc(docRef, profile, { merge: true })

    // Show an snackbar that the profile was updated
    setSnackbar(true)

    if (firstTime) {
      // Navigate to next step
      navigate("/ask/plans")
    }
    // if Destination is not set, navigate to home
    if (window.location.search) {
      const destination = window.location.search.split("=")[1]
      navigate(destination)
    } else {
      navigate("/ask")
    }
  }

  const handleClose = (event: SyntheticEvent<Element, Event> | Event, reason?: string | SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width:"100%" }}>
    <FlipCard flip={flip} item={{key: "login"}} isIOS={isIOS}>
      <div style={{background:"#fff",width: "100%", padding:"10px 15px"}}>
        <Box component="form" noValidate autoComplete="on" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Box sx={{ display: "flex", justifyContent: firstTime? "flex-start" : "space-between", alignItems: "center", my: 2, width: "100%", }}>
            {!firstTime && <ArrowBackIcon sx={{ fontSize: 20, mr: 0.5, ml: 1.5, cursor: "pointer" }} onClick={() => { navigate("/ask") }} />}
            <Typography variant="h5">Profile</Typography>
            {!firstTime && <Box sx={{ minWidth: 30 }}></Box>}
          </Box>

          {error && <Alert severity="error" sx={{ textAlign: "left", my: 1 }}>{error}</Alert>}
      
          <TextField
            id="website" label="Website or Social Media Link" variant="outlined" type="website"
            sx={{ mt: 2 }}
            onBlur={(e) => { getProfile(e.target.value) }}
            value={website}
            placeholder="Optional"
            onChange={(event) => { setWebsite(event.target.value) }}
          />

          <TextField
            id="display-name" label="Organisation Name or a Name (required)" variant="outlined" type="organisation-name"
            sx={{ mt: 2 }}
    
            {...organisationName}
            onChange={(event) => {
              setOrganisationName({
                value: event.target.value,
                error: false,
                helperText: ""
              })
            }}
          />
  
          {logo ? (
            <Box sx={{ mt: 4, mb: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img src={logo} alt="logo" width="150" />
              <Button size="small" variant="outlined" startIcon={<DeleteIcon />}
                onClick={handleDeleteLogo}
                sx={{ mt: 2 }}
              >
                Delete logo
              </Button>
            </Box>) :
            (<Box component="label"
              sx={{ mt: 2, borderRadius: 1, backgroundColor: "lightgrey", minWidth: 200, minHeight: 100, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 20 }}>
              Upload Logo
              <input
                type="file" accept="image/*"
                hidden
  
                onChange={handleFileUpload}
              />
            </Box>)
          }

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" size="large" color="secondary" sx={{ my: 3, minWidth: 200 }}
              onClick={() => {setFlip(true)}}
	      disabled={!organisationName.value}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </div>
      <div style={{background:"#fff",width: "100%", padding:"10px 15px"}}>
        <Box component="form" noValidate autoComplete="on" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Box sx={{ display: "flex", justifyContent: firstTime? "flex-start" : "space-between", alignItems: "center", my: 2, width: "100%", }}>
            {!firstTime && <ArrowBackIcon sx={{ fontSize: 20, mr: 0.5, ml: 1.5, cursor: "pointer" }} onClick={() => { setFlip(false) }} />}
            <Typography variant="h5">Profile</Typography>
            {!firstTime && <Box sx={{ minWidth: 30 }}></Box>}
          </Box>

          {error && <Alert severity="error" sx={{ textAlign: "left", my: 1 }}>{error}</Alert>}
      

          <TextField
            id="contact-name" label="Contact First Name" variant="outlined" type="text" name="contact-name"
            sx={{ mt: 2 }}
    
            {...contactName}
            onChange={(event) => {
              setContactName({
                value: event.target.value,
                error: false,
                helperText: ""
              })
            }}
          />

          <TextField
            id="contact-surname" label="Contact Last Name" variant="outlined" type="text" name="contact-surname"
            sx={{ mt: 2 }}
  
            {...contactSurname}
            onChange={(event) => {
              setContactSurname({
                value: event.target.value,
                error: false,
                helperText: ""
              })
            }}
          />

          <TextField
            id="email" label="Contact Email" variant="outlined" type="email" name="email"
            sx={{ mt: 2 }}
  
            {...contactEmail}
            onChange={(event) => {
              setContactEmail({
                value: event.target.value,
                error: false,
                helperText: ""
              })
            }}
          />
  

          <TextField
            id="about" label="About Your Company" variant="outlined" type="website"
            sx={{ mt: 2 }}
            multiline={true} rows={4}
            value={about}
            onChange={(event) => { setAbout(event.target.value) }}
          />
          {terms ? (
            <Box sx={{ mt: 4, mb: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Button size="small" variant="outlined" startIcon={<DeleteIcon />}
                onClick={() => {setTerms(null)}}
                sx={{ mt: 2 }}
              >
                Use default terms
              </Button>
            </Box>) :
            (<Box component="label"
              sx={{ mt: 2, borderRadius: 1, backgroundColor: "lightgrey", minWidth: 200, minHeight: 100, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 20 }}>
              Upload Privacy Policy and GDPR Terms
              <input
                type="file" accept="text/*"
                hidden
                onChange={handleUploadTerms}
              />
            </Box>)
	  } 
  
          {user?.permissions?.includes(PermissionsEnum.ADMIN) || firstTime ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" size="large" color="secondary" sx={{ my: 3, minWidth: 200 }}
                onClick={handleSaveProfile}
                disabled={!user}
              >
                {firstTime ? "Continue" : "Update"}
              </Button>
            </Box>
          ) :
            (
              <Alert severity="info" sx={{ my: 2 }}>
                Please contact the admin of your team if you need to update the profile!
              </Alert>
            )
          }

          <Typography variant="caption" sx={{ mt: 2 }}>
            * The contact first name, last name, and email will be used in the generated GDPR privacy policy
          </Typography>

        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbar}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <SnackAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Updated successfully!
          </SnackAlert>
        </Snackbar>
      </div>
    </FlipCard>
  </div>)
}
