// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
import { isIOS } from 'react-device-detect';
import { SEA } from './services/gun'
import { doc, fs, getDoc, setDoc, query ,collection, getDocs, where } from "./services/firebase";
export const VERSION = "V4.5.17"

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

function inIframe () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
async function saveField(name: string, decrypted: string, pair: any) {
  // console.log(name, decrypted)
  const enc = await SEA.encrypt(decrypted, pair)
  const data = await SEA.sign(enc, pair)
  const msg = await SEA.verify(data, pair?.pub)

  if (typeof msg === "object" && msg !== null) {
    console.log("msg", name, decrypted, msg)
    return setDoc(doc(fs, "userDB", pair?.pub + "-" + name), msg)
  }
}
function setData(pair, index, theForm: any, dec: string) {
  const q = query(collection(fs, "questions"), where("label", "==", theForm.result[index]));
  getDocs(q).then((querySnapshot: any) => {
    querySnapshot.forEach((question) => {
      console.log(question.id, " => ", question.data());
      const {name} = question.data();
      saveField(name, dec, pair)
    });
  });
}; 
function getData(pair, index, index2, event) {
  const q = query(collection(fs, "questions"), where("label", "==", event.data.result[index][index2]));
  getDocs(q).then((querySnapshot: any) => {
    querySnapshot.forEach((question) => {
      console.log(question.id, " => ", question.data());
      const {name} = question.data();
      const encryptDataRef = doc(fs, "userDB/" + pair.pub + "-" + name)
      console.log(encryptDataRef)
      getDoc(encryptDataRef).then(async (msgRef: any) => {
        if (!msgRef.exists) return

        const msg = msgRef.data()
        console.log("msg", msg)

        if (typeof msg === "object" && msg !== null) {
          let dec: any
          try {
            dec = (await SEA.decrypt(msg, pair))
            window.parent.postMessage({dec, index, index2, result: event.data.result}, event.origin);
          } catch (error) {
            console.error(error)
          }

          console.log("dec", name, dec, typeof dec)
        };
      });
    });
  });
}; 
if (inIframe()) {
  window.addEventListener("message", (event) => {
    console.log("event", event)
    if (event.data.update && event.data.index !== undefined) {
      console.log("update", event.data)
      const {theForm, index, dec} = event.data
      setData(JSON.parse(theForm.pair), index, theForm, dec)
    }

    if (typeof event.data.result !== "object") return
    if (typeof event.data.result[0] !== "object") return
    if (typeof event.data.result[0][0] !== "string") return
    const result = event.data.result
    console.log(`Message received:`, event.data);
    if (localStorage.getItem(event.origin) || window.confirm(`Do you want share your data with ${event.origin}?`)) {
      localStorage.setItem(event.origin, "1")
      Object.keys(result).forEach((index) => {
        result[index].forEach((item, index2) => {
          getData(JSON.parse(event.data.pair), index, index2, event)
        });        
      })
      // get questions from firebase
    }; 
  });
}

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

const defaultConfig: Config = {
  onUpdate: (registration: ServiceWorkerRegistration) => {
    console.log("onUpdate", VERSION)
    if (registration && registration.waiting) {
      if (isIOS) { // iOS
        if (window.confirm('New version available! Please click OK to update your app to the new version.')) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload();
        }
      } else { // Android
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        // window.location.reload();
      }
    }
  }
};
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      // unregister service worker
      console.log('serviceWorker registered', registration)
      // registration.unregister();
    }
  });
}
// https://blog.logicwind.com/show-new-version-available-dialog/

export function register(config: Config = defaultConfig) {
  console.log("config", VERSION)
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                'tabs for this page are closed. See https://cra.link/PWA.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
  }   
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType?.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
