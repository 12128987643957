import React, { useState, useEffect } from 'react'
import { Link, useLocation, Location } from "react-router-dom";

import {
  Box, Card, CardContent, Button, Typography, TextField, InputAdornment, IconButton,
  Alert,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { sendDRYPasswordResetEmail } from '../../services/firebase'
import { maxHeight } from '../../Helpers/height';

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(_ => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  mainCard: {
    margin: 10,
    marginTop: 15,
    display: "flex",
    boxShadow: "0px 2px 5px #888888",
    flexGrow: 1,
    height: maxHeight("100px"),
  },
  cardContent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "space-between",
    width: "100%"
  },
}))

const widthSx = { width: { xs: "90%", sm: 350, lg: 400 } }

export default function SetterForgotPassword() {
  const classes = useStyles();

  const location: Location = useLocation();

  const [info, setInfo] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const [email, setEmail] = useState({
    value: "",
    error: false,
    helperText: ""
  })

  useEffect(() => {
    if (location && location.state) {
      const { email } = location.state as { email: string }
      setEmail((state) => {
        return {
          ...state,
          value: email,
        }
      })
    }

  }, [location])

  const handleForgotPassword = async () => {
    if (email.value.trim() == "") {
      setEmail({
        ...email,
        error: true,
        helperText: "Email is required"
      })
      return
    }

    try {
      // const result = 
      await sendDRYPasswordResetEmail(email.value)
      // console.log("result", result)

      setInfo("Password reset email sent. Please check your email for instructions on how to reset your password.")

    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("error", error)
      console.log("errorCode", errorCode)

      switch (errorCode) {
        case "auth/user-not-found":
          setError("No account found with that email.")
          break
        case "auth/invalid-email":
          setError("The email address is invalid.")
          break
        default:
          setError(errorCode + ". " + errorMessage)
          break
      }
    }
  }

  return (
    <Box className={classes.wrapper}>

      <Card className={classes.mainCard}
        sx={{ ...widthSx }}
      >
        <CardContent className={classes.cardContent}>
	  <div className='lockup'>
            <img src="/parrot.svg" alt="Parrot logo" width="150" /><br />
            <h1 className="logo">Parrot</h1>
            <h6 className="logo">
              Answer or Ask Once <br /> End Forms Forever
            </h6>
	  </div>

          {info && <Alert severity="success" sx={{ textAlign: "left", my: 1 }}>{info}</Alert>}
          {error && <Alert severity="error" sx={{ textAlign: "left", my: 1 }}>{error}</Alert>}

          <Box component="form" noValidate autoComplete="on" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <TextField
              id="email" label="Email" variant="outlined" type="email"
              sx={{ mt: 2 }}

              {...email}
              onChange={(event) => { setError(null); setEmail({ value: event.target.value, error: false, helperText: "" }) }}
            />

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained" color="secondary" sx={{ mt: 2, maxWidth: "300px" }}
                onClick={handleForgotPassword}
              >
                Reset password
              </Button>
            </Box>
          </Box>

          <Link to="/ask/login" style={{ color: "black", textDecoration: "none", marginTop: 20 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon sx={{ fontSize: 14, mr: 0.5 }} />
              <Typography variant="subtitle2">
                Back to login
              </Typography>
            </Box>
          </Link>
        </CardContent>
      </Card>
    </Box>
  )
}
